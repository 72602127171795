import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { Config } from "../../../../Utils/Config";
import { useDownloadExcel } from "react-export-table-to-excel";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f3f3f3",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
function MySchedule() {
  const [openCircle, setOpenCircle] = useState(false);
  const [conferenceDetail, setConferenceDetail] = useState();
  const [conferenceList, setConferenceList] = useState();
  const [scheDuleData, setScheduleData] = useState();
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "My Schedule",
    sheet: "Product",
  });
  useEffect(() => {
    if (downloadExcel) {
      setTimeout(() => {
        onDownload();
        setDownloadExcel(false);
      }, 200);
    }
    // eslint-disable-next-line
  }, [downloadExcel]);

  useEffect(() => {
    GetConferenceList();
    // eslint-disable-next-line
  }, []);

  const GetConferenceList = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/AttendeeSchedule/GetAttendeeConferenceScheduleList?userId=${LogDetails?.userId}`,
    });
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        setConferenceList(
          res.data.payload?.map((x) => {
            return { ...x, value: x?.conferenceId, label: x?.conferenceName };
          })
        );
        if (res?.data?.payload?.length > 0) {
          GetMySchedule(res?.data?.payload[0]?.conferenceId);
          setConferenceDetail(res?.data?.payload[0]);
        }
      }
    }
  };
  const GetMySchedule = async (id) => {
    setScheduleData();
    let con = conferenceList?.filter((x) => x.conferenceId === id)[0];
    setConferenceDetail(con ? con : "");
    if (id) {
      setOpenCircle(true);
      let res = await GetCallApi({
        url: `/api/AttendeeSchedule/GetAttendeeScheduleDetailsById?userId=${LogDetails?.userId}&conferenceId=${id}`,
      });
      setOpenCircle(false);

      if (res.status === 200) {
        if (res.data.success) {
          //   let sessionList = [];
          let schedule = res?.data?.payload
            ?.filter(
              (x) => x?.classList?.length > 0 || x?.eventList?.length > 0
            )
            ?.map((x) => {
              let classList =
                x?.classList?.length > 0
                  ? x?.classList?.map((y) => {
                      let stime = y?.startTime
                        ? TimeTo12HourFormat(y?.startTime)
                        : "";
                      let etime = y?.endTime
                        ? TimeTo12HourFormat(y?.endTime)
                        : "";
                      return {
                        ...y,
                        name: y?.classTitle,
                        time: stime + " - " + etime,
                        type: "Session",
                      };
                    })
                  : null;
              let eventList =
                x?.eventList?.length > 0
                  ? x?.eventList?.map((y) => {
                      let stime = y?.eventStartTime
                        ? TimeTo12HourFormat(y?.eventStartTime)
                        : "";
                      let etime = y?.eventEndTime
                        ? TimeTo12HourFormat(y?.eventEndTime)
                        : "";
                      return {
                        ...y,
                        name: y?.eventName,
                        time: stime + " - " + etime,
                        type: "Event",
                      };
                    })
                  : null;
              let mainlist =
                classList !== null && eventList !== null
                  ? [...classList, ...eventList]
                  : classList !== null
                  ? classList
                  : eventList !== null
                  ? eventList
                  : [];
              return {
                ...x,
                date: x?.date
                  ? new Date(x?.date).toLocaleDateString("en-us", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "",
                myClassEventList: mainlist.sort(function (a, b) {
                  let btime = b?.startTime ? b?.startTime : b?.eventStartTime;
                  let atime = a?.startTime ? a?.startTime : a?.eventStartTime;

                  return (
                    new Date(x.date?.split("T")[0] + " " + atime) -
                    new Date(x.date?.split("T")[0] + " " + btime)
                  );
                }),
              };
            });

          setScheduleData(schedule);
        }
      }
    }
  };

  function TimeTo12HourFormat(time) {
    let [hours, minutes] = time.split(":").map(Number);

    let period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    hours = hours < 10 ? "0" + hours : hours;
    hours = hours ? hours : "00";
    minutes = minutes < 10 ? "0" + minutes : minutes;
    minutes = minutes ? minutes : "00";
    return `${hours}:${minutes} ${period}`;
  }
  let Time = new Date();

  const handleDownloadPdf = async () => {
    setOpenCircle(true);

    const element = tableRef.current;
    let newPdfDate =
      Time.toLocaleDateString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`MySchedule.pdf`);
        setOpenCircle(false);
        setDownloadpdf(false);
      });
  };

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="my_schdule_page">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="title">MySchedule</div>
                <div className="ms-auto d-none">
                  <Button
                    className="common_btn me-3"
                    onClick={() => setDownloadpdf(true)}
                  >
                    Download PDF
                  </Button>
                  <Button
                    className="common_btn me-3"
                    onClick={() => setDownloadExcel(true)}
                  >
                    Download Excel
                  </Button>
                </div>
                <div className="w-25">
                  <div className="form-group">
                    <Select
                      isMulti={false}
                      name="colors"
                      options={conferenceList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={conferenceList?.filter(
                        (obj) => obj.value === conferenceDetail?.conferenceId
                      )}
                      onChange={(e) => GetMySchedule(e?.value)}
                    />
                    <select
                      className="form-select d-none"
                      value={conferenceDetail?.conferenceId}
                      onChange={(e) => GetMySchedule(e.target.value)}
                    >
                      <option value="">Select Conference</option>
                      {conferenceList &&
                        conferenceList?.map((con, i) => (
                          <option key={i} value={con?.conferenceId}>
                            {con?.conferenceName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              {scheDuleData?.length > 0 ? (
                ""
              ) : (
                <div
                  className="er_nodata"
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: "2rem",
                    color: "#6b696d",
                  }}
                >
                  No Data Found
                </div>
              )}
              <div className="mt-5" ref={tableRef} style={back}>
                {downloadPdf || downloadExcel ? (
                  <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                    <img
                      src={
                        downloadPdf
                          ? require("../../../../assets/images/fig_events.png")
                          : `${Config.API_HOST_URL_live}/files/Logo/fig_events.png`
                      }
                      alt=""
                      height={100}
                      width={100}
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        My Schedule
                      </label>

                      <div
                        className="names"
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        {Time.toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}{" "}
                        {Time.toLocaleTimeString()}
                      </div>
                      <div
                        className="names"
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                        }}
                      >
                        Report Generated By:{" "}
                        {LogDetails?.firstName + " " + LogDetails?.lastName}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {scheDuleData?.map((x, i) => (
                  <div className="mt-2" key={i}>
                    <Table className="table-bordered Conference-table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            className="text-center"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                            colSpan={3}
                          >
                            {x?.date}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                              width: "150px",
                            }}
                          >
                            Time
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                              width: "500px",
                            }}
                          >
                            Name
                          </StyledTableCell>{" "}
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Type
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      {x?.myClassEventList?.map((y, index) => (
                        <TableBody key={index}>
                          <StyledTableRow>
                            <StyledTableCell
                              style={{
                                width: "150px",
                              }}
                            >
                              {y?.time}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                width: "500px",
                                wordBreak: "break-all",
                              }}
                            >
                              {/* <div
                                style={{
                                  whiteSpace: "pre-line",
                                  wordBreak: "break-All",
                                }}
                              > */}
                              {y?.name}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell>{y?.type}</StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MySchedule;
