import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import "./index.scss";
import { useLocation, useParams } from "react-router-dom";
import DetailShowPopup from "../../../Modules/Components/DetailShowPopup";
import { Config } from "../../../Utils/Config";
import { ResizableBox } from "react-resizable";
import { GetCallApi } from "../../../Action/Action";

function ConferenceUserDragableBoxBook(props) {
  const [open, setOpen] = React.useState(false);
  const [comDl, setComDl] = React.useState(false);
  const handleOpen = (detail) => {
    setOpen(true);
    setSboothId();

    setComDl(detail);
  };
  const { evD, sboothId, canEdit, setSboothId } = props;
  const [deltaPositionSave, setDeltaPositionSave] = useState([]);

  const { id, name } = useParams();
  const { state } = useLocation();
  const conId = id || state?.conferenceId;
  const [confId, setConFId] = useState();
  useEffect(() => {
    if (conId) {
      setConFId(conId);
    }
    // eslint-disable-next-line
  }, [conId]);
  useEffect(() => {
    getBox();
    // eslint-disable-next-line
  }, [confId]);

  const getBox = async () => {
    setDeltaPositionSave();
    if (confId) {
      // `/api/EventBooths/GetEventBoothsByEventID?eventId=${id}`,
      let res = await GetCallApi({
        url: `/api/ConferenceBooths/GetConferenceBoothsStatusByConferenceIdWithoutTokenNew?conferenceId=${confId}`,
      });
      if (res.status === 200) {
        if (res.data.success) {
          let pn = res.data.payload?.map((p) => {
            let company = p?.exhibitor?.exhibitorCompanyDetailsNew?.find(
              (x) => x.isFloorPlanDisplayCompany === true
            );
            let compAr = [
              company ?? "",
              ...(p?.exhibitor?.exhibitorCompanyDetailsNew?.filter(
                (x) => x.isFloorPlanDisplayCompany === false
              ) ?? []),
            ]
              ?.map((x) => {
                return { ...x, value: x?.companyId, label: x?.companyName };
              })
              ?.filter((x) => x.value !== undefined);
            return {
              ...p,
              ...p.exhibitor,
              ...p.exhibitor?.exhibitorCompanyDetails,
              companyD: compAr,
              PrimCompany: company,
              sequenceNumber: p?.sequenceNumber,
              value: p?.eventBoothsId,
              label: p?.seatEventNumber,
              x: p?.positionX,
              y: p?.positionY,
              width: p?.width,
              height: p?.hight,
              status: p?.status,
              oneExhibitorList: p?.exhibitor,
              exhibitorList: p?.exhibitor,
            };
          });
          setDeltaPositionSave(pn);
        }
      }
    }
  };

  // useEffect(() => {
  //   if (sboothId) {
  //     setTimeout(() => {
  //     }, 3000);
  //   }
  //   // eslint-disable-next-line
  // }, [sboothId]);
  return (
    <>
      <div className="editdrop_new">
        <div className="control_btn"></div>
        <div
          className="box"
          style={{
            height: evD?.height ? evD?.height - 290 : "680px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
        >
          <div
            style={{
              height: "100%",
              width: evD?.width ? evD?.width - 150 : "1770px",
              padding: "10px",
              zIndex: "999",
              position: "relative",
            }}
          >
            {deltaPositionSave &&
              deltaPositionSave?.map((item) => (
                <Draggable
                  key={item.value}
                  bounds="parent"
                  defaultPosition={{ x: item.x, y: item.y }}
                  onStart={() => false}
                >
                  {item?.type === "wall" ? (
                    <ResizableBox
                      minConstraints={[50, 50]}
                      maxConstraints={[300, 300]}
                      width={parseInt(item?.width)}
                      height={parseInt(item.height)}
                      style={{
                        position: "absolute",
                        backgroundColor: "black",
                      }}
                      resizeHandles={[]}
                    >
                      <div></div>
                    </ResizableBox>
                  ) : item?.type === "door" ? (
                    <ResizableBox
                      minConstraints={[50, 50]}
                      maxConstraints={[300, 300]}
                      width={parseInt(item?.width)}
                      height={parseInt(item.height)}
                      style={{
                        position: "absolute",
                        backgroundColor: item?.type === "wall" ? "black" : "",
                      }}
                      resizeHandles={[]}
                    >
                      <div>
                        <img
                          src={"/assets/icons/door.svg"}
                          className="child"
                          alt=""
                          style={{
                            width: `100%`,
                            height: `100%`,
                          }}
                        />
                      </div>
                    </ResizableBox>
                  ) : item?.status === "Approve" ? (
                    <ResizableBox
                      className={`${
                        item?.value === sboothId
                          ? "blink_me booked"
                          : item?.status === "Approve"
                          ? "booked"
                          : item?.status === "InProgress"
                          ? "my_booked"
                          : "availabel"
                      }`}
                      width={item.width}
                      height={item.height}
                      minConstraints={[50, 50]}
                      maxConstraints={[300, 300]}
                      resizeHandles={[]}
                      style={{
                        position: "absolute",
                        // backgroundColor: "#d3e0ed",

                        padding: "10px",
                        backgroundImage:
                          item?.status === "Approve"
                            ? item?.PrimCompany?.companyLogo
                              ? `url(${Config.API_HOST_URL_live}${item?.PrimCompany?.companyLogo})`
                              : `url(${Config.API_HOST_URL_live}${item?.exhibitorProfileImage})`
                            : "",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                      // onClick={() => console.log(item)}
                      onClick={() => handleOpen(item)}
                      onTouchStart={() => handleOpen(item)}
                    >
                      <div>{item.label}</div>
                    </ResizableBox>
                  ) : (
                    <ResizableBox
                      className={`${
                        item?.value === sboothId
                          ? "blink_me booked"
                          : item?.status === "InProgress"
                          ? "my_booked"
                          : "availabel"
                      }`}
                      width={item.width}
                      height={item.height}
                      minConstraints={[50, 50]}
                      maxConstraints={[300, 300]}
                      resizeHandles={[]}
                      style={{
                        position: "absolute",
                        padding: "10px",
                      }}
                      // onClick={() => handleOpen(item)}
                    >
                      <div>{item.label}</div>
                    </ResizableBox>
                  )}
                </Draggable>
              ))}
          </div>
        </div>
      </div>
      <DetailShowPopup
        openbox={open}
        closebox={setOpen}
        title="Company Details"
        item={comDl}
        canEdit={canEdit}
        gotoBtn={true}
        allBooth={deltaPositionSave}
        getBox={getBox}
        hideButton={name}
        evD={evD}
      />
    </>
  );
}

export default ConferenceUserDragableBoxBook;

{
  /* <Draggable
  key={k}
  bounds="parent"
  handle=".child"
  position={{
    x: drag.x,
    y: drag.y,
  }}
  onStart={() => false}
>
  {drag?.type === "wall" ? (
    <div
      className={`box_small wall`}
      style={{
        width: `${drag?.width ? drag.width : "28"}px`,
        height: `${drag?.height ? drag.height : "28"}px`,
        background: drag?.type === "wall" ? "black" : "",
      }}
    ></div>
  ) : drag?.type === "door" ? (
    <div
      className={`box_small door`}
      style={{
        width: `${drag?.width ? drag.width : "28"}px`,
        height: `${drag?.height ? drag.height : "28"}px`,
      }}
    >
      <img
        src={"/assets/icons/door.svg"}
        alt=""
        style={{
          width: `100%`,
          height: `100%`,
        }}
      />
    </div>
  ) : drag?.status === "Approve" ? (
    <div
      className={`child box_small ${
        drag?.value === sboothId
          ? "blink_me booked"
          : drag?.status === "Approve"
          ? "booked"
          : drag?.status === "InProgress"
          ? "my_booked"
          : ""
      }`}
      style={{
        width: `${drag?.width ? drag.width : "26"}px`,
        height: `${drag?.height ? drag.height : "26"}px`,
        backgroundImage: drag?.companyLogo
          ? `url(${Config.API_HOST_URL_live}${drag?.companyLogo})`
          : `url(${Config.API_HOST_URL_live}${drag?.exhibitorProfileImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      onClick={() => handleOpen(drag)}
      onTouchStart={() => handleOpen(drag)}
    >
      <div>{drag?.label}</div>
    </div>
  ) : drag?.status === "InProgress" ? (
    <div
      className={`child box_small
                         my_booked
                        
                      `}
      style={{
        width: `${drag?.width ? drag.width : "26"}px`,
        height: `${drag?.height ? drag.height : "26"}px`,
      }}
    >
      <div>{drag?.label}</div>
    </div>
  ) : (
    <div
      className={`child box_small availabel `}
      style={{
        width: `${drag?.width ? drag.width : "28"}px`,
        height: `${drag?.height ? drag.height : "28"}px`,
      }}
    >
      <div>{drag?.label}</div>
    </div>
  )}
</Draggable>; */
}
