import React, { Suspense, useState } from "react";
import QrReader from "react-qr-scanner";
import { PostCallApi } from "../../../Action/Action";
import { useSelector } from "react-redux";
import "./index.scss";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { Config } from "./../../../Utils/Config";
import { ToastError, ToastSuccess } from "../../Components/Toast";

const pro_img = "/assets/images/default_profile.jpg";

const renderLoader = () => <p>Loading...</p>;

function UserQRScan() {
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState(false);
  const [userData, setUserData] = useState();
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    if (startScan) {
      setUserData();
    }
    if (scanData && scanData !== "") {
      AttendUser(scanData);
      setData(true);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const AttendUser = async (user) => {
    let usd = user?.text?.split("/");
    let usData = {
      conferenceId: usd[2],
      sessionId: usd[4],
      status: "Attended",
      sessionEventType: usd[3],
      // userId: usd[6],
      createdBy: LogDetails?.userId,
    };
    let seData = {
      url: `/api/UserMst/ValidateUserToScanQRcodeForSession?conferenceId=${usData?.conferenceId}&sessionOrEventId=${usData?.sessionId}&userEmail=${LogDetails?.email}&sessionEventType=${usData?.sessionEventType}`,
      body: {},
    };
    let res = await PostCallApi(seData);
    setData(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        // setUserData(res?.data?.payload);
        ToastSuccess(res.data.message);
      } else {
        Swal.fire({
          position: "center",
          width: "50%",
          title: res.data.message,
          confirmButtonText: "Close",
          confirmButtonColor: "#E64942",
        });
      }
    } else {
      ToastError(res.data.message);
    }
  };
  return (
    <Suspense fallback={renderLoader()}>
      <section className="user_qr_scan">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="title mb-3">User QR Scanner</div>
              <Button
                className="common_btn mb-3"
                onClick={() => {
                  setStartScan(!startScan);
                }}
                disabled={data}
              >
                {startScan ? "Stop Scan" : "Start Scan"}
              </Button>
              {startScan && (
                <div className="">
                  <div className="col-md-4 my-3">
                    <select
                      className="form-select"
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <option value={"environment"}>Back Camera</option>
                      <option value={"user"}>Front Camera</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <QrReader
                      facingMode={selected}
                      delay={1000}
                      onError={handleError}
                      onScan={handleScan}
                      // chooseDeviceId={()=>selected}
                      style={{ width: "500px" }}
                    />
                  </div>
                </div>
              )}
              {loadingScan && <p>Loading</p>}
              {userData && (
                <div className="User_details">
                  <div className="card_title">Identity</div>
                  <div className="d-flex gap-3 p-3">
                    <div className="img_div">
                      <img
                        src={
                          userData?.userDetails?.userProfile
                            ? Config.API_HOST_URL_live +
                              userData?.userDetails?.userProfile
                            : pro_img
                        }
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = pro_img;
                        }}
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <div className="name f-16-600">
                        Name: {userData?.userDetails?.userName}
                      </div>{" "}
                      <div className="mb-1 f-14-400">
                        <b>Email:</b> {userData?.userDetails?.userEmail}
                      </div>{" "}
                      <div className="mb-1 f-14-400">
                        <b>Conference:</b> {userData?.conferenceName}
                      </div>{" "}
                      <div className="mb-1 f-14-400">
                        <b>{userData?.sessionEventType}: </b>
                        {userData?.eventOrSessionName}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {data !== "" && <p>{data?.text}</p>} */}
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default UserQRScan;
