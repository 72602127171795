import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { Config } from "../../Utils/Config";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { ToastError, ToastSuccess } from "../Components/Toast";

const NotFound = "/assets/images/Image_not_available.jpg";

function UserConferenceFeedBackForm() {
  const { id, uId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [allDetails, setAllDetails] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [otherOption, setOtherOption] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const { fields: fieldArray1 } = useFieldArray({
    control,
    name: "questionAnswerDetails",
  });

  useEffect(() => {
    GetAllQuestionList();
  }, []);

  const GetAllQuestionList = async () => {
    let seData = {
      url: `api/ConferenceFeedbackQuestion/GetConferenceFeedbackQuestionBySubmittedIdWithoutToken?submittedId=${id}`,
    };
    let res = await GetCallApi(seData);

    if (res.status === 200 && res.data.success) {
      let allData = res?.data?.payload;
      setAllDetails(allData);
      setValue("conferenceId", allData?.conferenceId);
      let comList = res?.data?.payload?.questionList?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
        };
      });
      if (comList?.length > 0) {
        setValue("questionAnswerDetails", comList);
      }
    } else {
      ToastError(res?.data?.message);
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;

    if (is_success) {
      let seData = {
        url: `/api/ConferenceFeedbackQuestion/SaveUserConferenceFeedbackQuestionAnswer`,
        body: {
          ...data,
          submittedId: id,
          userId: uId,

          FeedbackUserQuestionAnswer: data?.questionAnswerDetails?.map((x) => {
            if (x?.questionType === "Multiple Choice") {
              if (
                x?.answer
                  ? x?.answer?.find((y) => y === "other" || y === "Other")
                  : false
              ) {
                return {
                  ...x,
                  answer: x?.answer?.toString() + "; " + x?.otherAnswer,
                };
              }
              return { ...x, answer: x?.answer?.toString() };
            }
            if (x?.answer === "other" || x?.answer === "Other") {
              return { ...x, answer: x?.answer + "; " + x?.otherAnswer };
            }
            return x;
          }),
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res?.data?.success) {
        setFormSubmit(true);
        ToastSuccess(res.data.message);
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  // const forBack = () => {
  //   Swal.fire({
  //     position: "center",
  //     width: window?.innerWidth > 768 ? "50%" : "90%",
  //     title: "Are you sure want to cancel ?",
  //     text: "All the details will discard after close.",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //     confirmButtonColor: "#ff5151",
  //   }).then((value) => {
  //     // console.log(value);
  //     if (value?.isConfirmed) {
  //       //
  //     }
  //   });
  // };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="QuestionsforNonFigUsers">
        <div className="main_wrapper ">
          <div className="background_theme mb-3 ">
            <img
              src={
                allDetails?.displayImage
                  ? Config.API_HOST_URL_live + allDetails?.displayImage
                  : NotFound
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NotFound;
              }}
              width={30}
              height={30}
              alt=""
            />
            <span>{allDetails?.conferenceName} Conference Feedback Form </span>
          </div>
          {formSubmit ? (
            <div className="success_message_box">
              <BsFillPatchCheckFill className="icon" />

              <h5 className="m-0">Feedback Submitted Successfully</h5>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                {" "}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    {/* <div className={`col-md-6`}>
                    <div className="form-group">
                      <label className="form-label">
                        Conference
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        {...register("conferenceName")}
                      />
                    </div>
                  </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          First Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("firstName", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max character 250 allowed",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <span role="alert" className="error_text">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Last Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          {...register("lastName", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max character 250 allowed",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <span role="alert" className="error_text">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                    {fieldArray1?.map((item, index) => {
                      let setAr = getValues(
                        `questionAnswerDetails.${index}.answer`
                      );
                      return (
                        <div
                          className={`col-md-${
                            item?.questionType === "Rating" ? 12 : 6
                          }`}
                          key={index}
                        >
                          <div className="form-group">
                            <label className="form-label">
                              {index + 1}) {item?.questionTitle}
                              {item?.isRequired ? (
                                <span className="red-color"> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                            {item?.questionType === "Text" ? (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Answer"
                                {...register(
                                  `questionAnswerDetails.${index}.answer`,
                                  {
                                    required: {
                                      value: item?.isRequired,
                                      message: "This field is required",
                                    },
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message:
                                        "Starting and Ending Space not allowed",
                                    },
                                    maxLength: {
                                      value: 200,
                                      message: "Max character 200 allowed",
                                    },
                                  }
                                )}
                              />
                            ) : item?.questionType === "BigText" ? (
                              <textarea
                                rows={3}
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Answer"
                                {...register(
                                  `questionAnswerDetails.${index}.answer`,
                                  {
                                    required: {
                                      value: item?.isRequired,
                                      message: "This field is required",
                                    },
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message:
                                        "Starting and Ending Space not allowed",
                                    },
                                    maxLength: {
                                      value: 500,
                                      message: "Max character 500 allowed",
                                    },
                                  }
                                )}
                              />
                            ) : item?.questionType === "Listbox" ? (
                              <select
                                type="text"
                                className="form-select"
                                {...register(
                                  `questionAnswerDetails.${index}.answer`,
                                  {
                                    required: {
                                      value: item?.isRequired,
                                      message: "This field is required",
                                    },
                                    onChange: (e) =>
                                      setOtherOption(
                                        e.target.value === "Other" ||
                                          e.target.value === "other"
                                          ? !otherOption
                                          : ""
                                      ),
                                  }
                                )}
                              >
                                <option value="">Select Value</option>
                                {item?.isSpeakerDropDown
                                  ? allDetails?.confSpeakerList?.map((x, i) => (
                                      <option value={x?.speakerId} key={i}>
                                        {x?.speakerName}
                                      </option>
                                    ))
                                  : item?.confQuestionOption?.map((x, i) => (
                                      <option value={x?.option} key={i}>
                                        {x?.option}
                                      </option>
                                    ))}
                              </select>
                            ) : item?.questionType === "Rating" ? (
                              <div className="d-flex">
                                <div
                                  className=" my-auto me-3"
                                  style={{
                                    maxWidth: "35%",
                                    width: "fit-content",
                                  }}
                                >
                                  <div className="text-end">
                                    {item?.confQuestionOption[0]?.option}
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="d-flex gap-5">
                                    {[...Array(5).keys()]?.map((x) => (
                                      <div key={x}>
                                        <label
                                          htmlFor={`TrueFalse${x}`}
                                          className="w-100"
                                        >
                                          {x + 1}
                                        </label>
                                        <input
                                          type="radio"
                                          id={`TrueFalse${x}`}
                                          className=""
                                          name="TrueFalse"
                                          value={x + 1}
                                          {...register(
                                            `questionAnswerDetails.${index}.answer`,
                                            {
                                              required: {
                                                value: item?.isRequired,
                                                message:
                                                  "This field is required",
                                              },
                                            }
                                          )}
                                        />{" "}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className=" my-auto ms-3"
                                  style={{
                                    maxWidth: "35%",
                                    width: "fit-content",
                                  }}
                                >
                                  <div>
                                    {item?.confQuestionOption[1]?.option}
                                  </div>
                                </div>
                              </div>
                            ) : item?.questionType === "Radio" ? (
                              <div>
                                {item?.confQuestionOption?.map((opt, i) => (
                                  <div key={opt?.optionId}>
                                    <input
                                      type="radio"
                                      id={`TrueFalse${i}`}
                                      className=""
                                      name="TrueFalse"
                                      value={opt?.option}
                                      {...register(
                                        `questionAnswerDetails.${index}.answer`,
                                        {
                                          required: {
                                            value: item?.isRequired,
                                            message: "This field is required",
                                          },
                                          onChange: (e) =>
                                            setOtherOption(
                                              e.target.value === "Other" ||
                                                e.target.value === "other"
                                                ? !otherOption
                                                : ""
                                            ),
                                        }
                                      )}
                                    />{" "}
                                    <label
                                      htmlFor={`TrueFalse${i}`}
                                      className="ms-2"
                                    >
                                      {opt?.option}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : item?.questionType === "Multiple Choice" ? (
                              <div>
                                {item?.confQuestionOption?.map((x, i) => (
                                  <React.Fragment key={i}>
                                    <input
                                      type="checkBox"
                                      id={`check${x?.option}`}
                                      className=""
                                      value={x?.option}
                                      {...register(
                                        `questionAnswerDetails.${index}.answer`,
                                        {
                                          required: {
                                            value: item?.isRequired,
                                            message: "This field is required",
                                          },
                                          onChange: (e) =>
                                            // console.log(e.target.value),
                                            setOtherOption(
                                              e.target.value === "Other" ||
                                                e.target.value === "other"
                                                ? !otherOption
                                                : ""
                                            ),
                                        }
                                      )}
                                    />{" "}
                                    <label
                                      htmlFor={`check${x?.option}`}
                                      className="mx-2"
                                    >
                                      {x?.option}
                                    </label>
                                  </React.Fragment>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}

                            {getValues(
                              `questionAnswerDetails.${index}.answer`
                            ) === "Other" ||
                            getValues(
                              `questionAnswerDetails.${index}.answer`
                            ) === "other" ? (
                              <div className="mt-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your Answer"
                                  {...register(
                                    `questionAnswerDetails.${index}.otherAnswer`,
                                    {
                                      required: {
                                        value: true,
                                        message: "This field is required",
                                      },
                                      pattern: {
                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                        message:
                                          "Starting and Ending Space not allowed",
                                      },
                                      maxLength: {
                                        value: 200,
                                        message: "Max character 200 allowed",
                                      },
                                    }
                                  )}
                                />
                                {errors.questionAnswerDetails?.[index]
                                  ?.otherAnswer && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.questionAnswerDetails?.[index]
                                        ?.otherAnswer.message
                                    }
                                  </span>
                                )}
                              </div>
                            ) : item?.questionType === "Multiple Choice" &&
                              setAr ? (
                              setAr?.find(
                                (x) => x === "other" || x === "Other"
                              ) ? (
                                <div className="mt-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Answer"
                                    {...register(
                                      `questionAnswerDetails.${index}.otherAnswer`,
                                      {
                                        required: {
                                          value: true,
                                          message: "This field is required",
                                        },
                                        pattern: {
                                          value: /^[^\s]+(\s+[^\s]+)*$/,
                                          message:
                                            "Starting and Ending Space not allowed",
                                        },
                                        maxLength: {
                                          value: 200,
                                          message: "Max character 200 allowed",
                                        },
                                      }
                                    )}
                                  />
                                  {errors.questionAnswerDetails?.[index]
                                    ?.otherAnswer && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.questionAnswerDetails?.[index]
                                          ?.otherAnswer.message
                                      }
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            {errors.questionAnswerDetails?.[index]?.answer && (
                              <span role="alert" className="error_text">
                                {
                                  errors.questionAnswerDetails?.[index]?.answer
                                    .message
                                }
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className="col-md-12 d-flex justify-content-end mt-3">
                      <div className="form-group d-flex gap-3">
                        {/* <Button
                        className="common_btn"
                        onClick={() => forBack()}
                      >
                        Cancel
                      </Button> */}
                        <Button className="common_btn" type="submit">
                          {openCircle ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default UserConferenceFeedBackForm;
