import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// import { Editor } from "@tinymce/tinymce-react";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Select from "react-select";
import "./index.scss";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
  SourceEditing,
  GeneralHtmlSupport,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

const CreateTemplate = () => {
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [derror, setDrr] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [require, setRequire] = useState([]);
  const [selectRequire, setSelectRequire] = useState([]);
  const [editorData, setEditorData] = useState("");

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const headers = {
    "Content-Type": "application/json",
  };

  useEffect(() => {
    if (id) {
      getTemplateData();
    }
    getParameter();
  }, [id]);

  // get Edit data
  const getTemplateData = async () => {
    let seData = {
      url: `/api/Template/GetTemplateDataById?templateId=${id}`,
      headers: headers,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200 && res.data.success) {
      let tempData = res.data.payload;
      setValue("templateId", tempData?.templateId);
      setValue("templateTitle", tempData?.templateTitle);
      setValue("notificationType", tempData?.notificationType);
      setValue("subject", tempData?.subject);
      setEditorData(tempData?.body);
      setSelectRequire(
        tempData?.requireParameter?.split(",")?.map((p) => {
          return {
            value: p,
            label: p,
          };
        })
      );
      if (editorRef.current && tempData?.body) {
        editorRef.current.setContent(tempData.body);
      }
    }
  };

  const getParameter = async () => {
    let sendData = {
      url: `/api/Common/GetTemplateRequiredParameter`,
      headers: headers,
    };
    const resp = await GetCallApi(sendData);
    if (resp?.status === 200) {
      if (resp.data.success) {
        let list = resp.data.payload?.map((c) => {
          return {
            value: c?.name,
            label: c?.name,
          };
        });
        let allList = [{ value: "all", label: "Select All" }, ...list];
        setRequire(allList);
      }
    }
  };

  // submit handler
  const onSubmit = async (data) => {
    if (selectRequire?.length > 0) {
      setDrr({ req: "" });
      if (editorData) {
        let tempBody = editorData;
        setDrr("");
        // data.body = tempBody;
        let seData = {
          url: "/api/Template/CreateTemplate",
          headers: headers,
          body: {
            ...data,
            body: tempBody,
            requireParameter: selectRequire?.map((x) => x.label),
          },
        };
        setOpenCircle(true);
        let res = await PostCallApi(seData);
        setOpenCircle(false);
        if (res.status === 200 && res.data.success) {
          ToastSuccess(res.data.message);
          reset();
          navigate(-1);
          setDrr({ err: "" });
        } else {
          setDrr({ err: res.data?.message });
          ToastError(res.data.message);
        }
      } else {
        setDrr({ err: "This field is required" });
      }
    } else {
      setDrr({ req: "This field is required" });
    }
  };

  const handleMulti = (e) => {
    setSelectRequire(e);
  };

  // useEffect(() => {
  //   ClassicEditor.create(editorRef.current, {
  //     // ...your CKEditor configuration
  //   })
  //     .then((editor) => {
  //       editor.setData(editorData);

  //       editor.model.document.on("change:data", () => {
  //         setEditorData(editor.getData());
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [editorData]);
  return (
    <section className="create_template">
      <div className="main_wrapper">
        <div className="title mb-3">Create Template</div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Template Title
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Template Title"
                      {...register("templateTitle", {
                        required: "This field is required",
                        maxLength: {
                          value: 1500,
                          message: "Max character 1500 allowed",
                        },
                      })}
                    />
                    {errors.templateTitle && (
                      <span role="alert" className="error_text">
                        {errors.templateTitle.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* 
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Notification Type</label>
                    <select
                      name=""
                      className="form-select"
                      {...register("notificationType", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select Notification Type</option>
                      <option value="email">Email</option>
                      <option value="text">Text</option>
                    </select>
                    {errors.notificationType && (
                      <span role="alert" className="error_text">
                        {errors.notificationType.message}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Subject <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      {...register("subject", {
                        required: "This field is required",
                        maxLength: {
                          value: 2000,
                          message: "Max character 2000 allowed",
                        },
                      })}
                    />
                    {errors.subject && (
                      <span className="formError errorMssg error_text">
                        {errors.subject.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Require Parameter
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti
                      name="type"
                      options={require}
                      className="form-neselect"
                      classNamePrefix="select"
                      placeholder="Select Require Parameter"
                      // menuIsOpen={true}
                      value={selectRequire ? selectRequire : null}
                      filterOption={customFilter}
                      onChange={(selected) => {
                        selected.length &&
                        selected.find((option) => option.value === "all")
                          ? handleMulti(require.slice(1))
                          : handleMulti((selected && selected) || null);
                      }}
                      //    true &&
                      // selected.length &&
                      // selected.find((option) => option.value === "all")
                      //   ? handleMulti(require.slice(1))
                      //   : !true
                      //   ? handleMulti((selected && selected.value) || null)
                      //   : handleMulti(selected);
                      style={{ width: "100%" }}
                    />
                    {derror?.req && (
                      <span role="alert" className="d-block error_text">
                        {derror?.req}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <style>
                    {`
                        .ck-powered-by { 
                        display: none !important;
                        }
                      `}
                  </style>
                  <div className="form-group">
                    <label>
                      Template Body <span className="red-color">*</span>{" "}
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorData}
                      onReady={(editor) => {
                        editorRef.current = editor;
                      }}
                      onChange={(event, editor) =>
                        setEditorData(editor.getData())
                      }
                      config={{
                        toolbar: [
                          "undo",
                          "redo",
                          "|",
                          "source",
                          "sourceEditing",
                          "|",
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "link",
                          "insertTable",
                          "mediaEmbed",
                          "image",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "indent",
                          "outdent",
                        ],
                        plugins: [
                          Bold,
                          Essentials,
                          Heading,
                          Indent,
                          IndentBlock,
                          Italic,
                          Link,
                          List,
                          Image,
                          MediaEmbed,
                          Paragraph,
                          Table,
                          Undo,
                          SourceEditing,
                          GeneralHtmlSupport,
                        ],
                        sourceEditing: {
                          shouldNotRender: false, // Enable source editing
                        },
                        htmlSupport: {
                          allow: [
                            {
                              name: /.*/,
                              attributes: true,
                              classes: true,
                              styles: true,
                            },
                          ],
                        },
                        // initialData: "<h1>Hello from CKEditor 5!</h1>",
                      }}
                    />
                    {/* <Editor
                      apiKey="9zojxfrss457vslhx3vq977m5e0w30yzcd3jeanzkgnuxiye"
                      onInit={(_evt, editor) => (editorRef.current = editor)}
                      initialValue=""
                      init={{
                        height: 500,
                        menubar: false,
                        branding: false, // Removes "Powered by TinyMCE" branding
                        statusbar: false, // Removes the status bar
                        // plugins: [
                        //   "advlist",
                        //   "autolink",
                        //   "lists",
                        //   "link",
                        //   "image",
                        //   "charmap",
                        //   "preview",
                        //   "anchor",
                        //   "searchreplace",
                        //   "visualblocks",
                        //   "code",
                        //   "fullscreen",
                        //   "insertdatetime",
                        //   "media",
                        //   "table",
                        //   "code",
                        //   "wordcount",
                        //   "Templates",
                        // ],
                        plugins:
                          "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media  table code wordcount Templates",
                        toolbar:
                          "undo Redo | blocks | " +
                          "Bold | Underline |Italic | Forecolor |Select all	 | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | source code | Switch to or from fullscreen mode |media | link image",

                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    /> */}

                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                  <div className="form-group">
                    <Button
                      className="back_button"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                    <Button
                      className="common_btn ms-3"
                      type="submit"
                      disabled={openCircle}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateTemplate;
