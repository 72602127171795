import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ImgPopup from "../../Components/ImgPopUp";
import { Config } from "../../../Utils/Config";
import AOS from "aos";
import Select from "react-select";
import AssignTable from "../../SuperAdmin/AssignUsers/AssignTable";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { VscEye } from "react-icons/vsc";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { SwalMessage, ToastError, ToastSuccess } from "../../Components/Toast";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "leadStatus",
    label: "Lead Status",
  },
  {
    id: "email",
    label: "Contact Email",
  },
  {
    id: "phone",
    label: "Contact Number",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "associationMembersName",
    label: "Group Name",
  },
  {
    id: "requestACallDate",
    label: "Request A Call Date",
  },
  {
    id: "isChatWithExhibitor",
    label: "Call From Chat",
  },
  {
    id: "isContacted",
    label: "IsContacted",
  },
  {
    id: "isExternalUser",
    label: "IsExternal User",
  },
  {
    id: "addressType",
    label: "Address Type",
  },
  {
    id: "address",
    label: "Address",
    widthFix: 600,
  },
];

const LeadList = [
  { label: "Cold", value: "Cold" },
  { label: "Hot", value: "Hot" },
  { label: "Warm", value: "Warm" },
];

function LeadsReport() {
  const [openCircle, setOpenCircle] = useState(false);
  const [attendeeList, setAttendeeList] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [conList, setConList] = useState([]);
  const [selectConference, setSelectConference] = useState();
  const [selectLead, setSelectLead] = useState();
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [show, setShow] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [selectCompany, setSelectCompany] = useState();
  const [filterPage, setFilterPage] = useState(false);

  // const [derror, setDerror] = useState();

  // const {
  //   handleSubmit,
  //   register,
  //   setValue,
  //   formState: { errors },
  // } = useForm();

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const navigate = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.title = "FIG Events | Dashboard";
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetButtonshow();
    getLEAList();
    getConferenceList();
    getAgencyist();
    getIndustryist();
    getProductList();
    GetCompanyRegistrationList();

    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   GetAttList();
  //   // eslint-disable-next-line
  // }, [
  //   selectConference,
  //   selectAgency,
  //   selectIndustry,
  //   selectLea,
  //   selectLead,
  //   selectProduct,
  // ]);
  const GetButtonshow = async () => {
    setOpenCircle(true);
    let Data = {
      url: `/api/ExhibitorSubscription/ExhibitorSubscriptionExistsByUserId?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let resp = await GetCallApi(Data);

    if (resp?.status === 200) {
      if (resp.data.success) {
        setShow(resp.data.success);
      } else {
        setShow(resp.data.success);
      }
    }
  };

  const GetCompanyRegistrationList = async () => {
    let seData = {
      url: `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${logindetail?.userId}`,
      headers: headers,
    };
    setCompanyList([]);

    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          label: c?.companyName,
          value: c?.companyId,
        };
      });
      setCompanyList(comList);
    }
  };

  // const handleSelectProd = (v, id) => {
  //   if (v) {
  //     setUserArray((old) => [...old, id]);
  //   } else {
  //     setUserArray((old) => old?.filter((d) => d !== id));
  //   }
  // };
  const handlere = (d) => {
    sessionStorage.setItem("viewUserAnswer", JSON.stringify(d));
    navigate(`view`, { replace: true });
  };
  const GetAttList = async () => {
    setAttendeeList([]);
    setUserArray([]);
    let member = [];
    let is_success = true;

    if (selectLea?.length > 0) {
      member = [...selectLea];
    }
    if (selectAgency?.length > 0) {
      member = [...member, ...selectAgency];
    }
    if (selectIndustry?.length > 0) {
      member = [...member, ...selectIndustry];
    }
    if (selectCompany?.value) {
      // if (!selectConference?.value) {
      //   is_success = false;
      //   SwalMessage("Conference required when selecting company");
      // }
    } else {
      is_success = false;
      SwalMessage("Company required");
    }
    if (is_success) {
      setOpenCircle(true);

      let seData = {
        url: `/api/ExhibitorHall/AddAttendeeInterestedProductListWithFilter`,
        body: {
          userId: logindetail?.userId,
          conferenceId: selectConference ? selectConference?.value : null,
          companyId: selectCompany ? selectCompany?.value : null,
          associationMemberId: member?.length > 0 ? member : null,
          LeadStatus: selectLead ? selectLead?.value : null,
          ProductId: selectProduct?.length > 0 ? selectProduct : null,
        },
        headers: headers,
      };
      let res = await PostCallApi(seData);

      setFilterPage(!filterPage);
      if (res?.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          let comList = res?.data?.payload?.attendeeFavoriteProductList?.map(
            (c, i) => {
              return {
                id: i + 1,
                ...c,
                ...c.userAddress,
                color:
                  c?.leadStatus === "Hot"
                    ? "#f3c2c3"
                    : c?.leadStatus === "Warm"
                    ? "#fffaca"
                    : c?.leadStatus === "Cold"
                    ? "#0000ff63"
                    : "",
                productImage: c?.productImage ? (
                  <ImgPopup
                    img={`${Config.API_HOST_URL_live}${c.productImage}`}
                    addClass={"h_fix"}
                    hide_icon={true}
                  />
                ) : c?.mutipleImages?.length > 0 ? (
                  <ImgPopup
                    img={c?.mutipleImages?.[0]}
                    addClass={"h_fix"}
                    hide_icon={true}
                  />
                ) : (
                  ""
                ),
                requestACallDate: c?.requestACallDate
                  ? new Date(c?.requestACallDate)?.toLocaleDateString("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "",
                isChatWithExhibitor: c?.isChatWithExhibitor ? "True" : "False",
                isContacted: c?.isContacted ? "True" : "False",
                isExternalUser: c?.isExternalUser ? "True" : "False",
                Action: (
                  <>
                    <Button
                      className={
                        c?.isContacted ? "green_button" : "grey_button"
                      }
                      onClick={() => handleContact(c)}
                    >
                      Contacted
                    </Button>
                    <Button
                      sx={{ minWidth: "auto" }}
                      onClick={() => handlere(c)}
                      className="btn_edit ms-3 "
                    >
                      <VscEye />
                    </Button>
                  </>
                ),
              };
            }
          );
          setAttendeeList(comList);
        } else {
          setOpenCircle(false);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  useEffect(() => {
    if (userArray?.length > 0) {
      changeTbData();
    } else {
      changeTbData();
    }
    // eslint-disable-next-line
  }, [userArray]);

  const changeTbData = () => {
    let newData = attendeeList?.map((c) => {
      return {
        ...c,
        Action: (
          <>
            <Button
              className={
                c?.isContacted === "True" ? "green_button" : "grey_button"
              }
              onClick={() => handleContact(c)}
            >
              Contacted
            </Button>
            <Button
              sx={{ minWidth: "auto" }}
              onClick={() => handlere(c)}
              className="btn_edit ms-3 "
            >
              <VscEye />
            </Button>
          </>
        ),
      };
    });
    setAttendeeList(newData);
  };

  const handleContact = async (user) => {
    let seDara = {
      url: `/api/ExhibitorHall/AddAttendeeIsContactedStatus`,
      body: {
        requestAcallUserInformationId: user?.requestAcallUserInformationId,
        // userId: [user?.attendeeId],
        contactedStatus: user?.isContacted ? false : true,
      },
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seDara);
    setOpenCircle(false);
    if (res.status === 200 && res.data.success) {
      GetAttList();
      ToastSuccess(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      ToastError(res?.data?.message);
    }
  };

  const getConferenceList = async () => {
    let seData = {
      url: `/api/ExhibitorHall/GetExhibitorRegisteredConferenceList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            value: x?.conferenceId,
            label: x?.conferenceName,
          };
        });
        setConList(data);
      }
    }
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        logindetail?.userId
      }`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setLeaList(data);
      }
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        logindetail?.userId
      }`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setAgencyList(data);
      }
    }
  };

  const getProductList = async () => {
    let seData = {
      url: `/api/UserMst/GetExhibitorsAllInformation?userId=${logindetail?.userId}&loginUserId=${logindetail?.userId}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let sv = res.data.payload?.productList?.map((x) => {
          return {
            ...x,
            value: x?.exhibitorProductId,
            label: x.productName,
          };
        });
        setProductList(sv);
      }
    }
  };

  const getIndustryist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
        logindetail?.userId
      }`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setIndustryList(data);
      }
    }
  };

  const handleChangeCon = (e) => {
    setSelectConference(e);
  };
  const handleChangeCompany = (e) => {
    setSelectCompany(e);
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };
  const handleChangeProduct = (e) => {
    setSelectProduct(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const [checkedValue, setCheckValue] = useState(false);

  const handleMultiCheck = (val, id) => {
    if (val) {
      setUserArray(id?.map((d) => d?.attendeeId));
      setCheckValue(true);
    } else {
      setUserArray([]);
      setCheckValue(false);
    }
  };

  const handleClearFilter = () => {
    setSelectLea();
    setSelectAgency();
    setSelectIndustry();
    setSelectProduct();
    setSelectConference();
    setSelectCompany();
    setSelectLead();
    setAttendeeList();
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="leads_report">
          <div className="main_wrapper">
            <form className="form_box">
              {/* <form onSubmit={handleSubmit(onSubmit)} className="form_box"> */}
              <div className="row">
                {/* <div className="card">
                <div className="card-body"> */}
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Company</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={companyList}
                            isClearable={true}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Company"
                            filterOption={customFilter}
                            value={companyList?.filter(
                              (obj) => obj.value === selectCompany?.value
                            )}
                            onChange={handleChangeCompany}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === selectConference?.value
                            )}
                            onChange={handleChangeCon}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">LEA Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={leaList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA Member"
                            filterOption={customFilter}
                            value={leaList?.filter((obj) =>
                              selectLea?.includes(obj.value)
                            )}
                            onChange={handleChangeLea}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">State Agencies</label>
                          <Select
                            isMulti
                            name="colors"
                            options={agencyList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select State Agencies"
                            filterOption={customFilter}
                            value={agencyList?.filter((obj) =>
                              selectAgency?.includes(obj.value)
                            )}
                            onChange={handleChangeAgency}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Industry Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={industryList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry Member"
                            filterOption={customFilter}
                            value={industryList?.filter((obj) =>
                              selectIndustry?.includes(obj.value)
                            )}
                            onChange={handleChangeIndustry}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Lead Status</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={LeadList}
                            isClearable={true}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Lead Status"
                            filterOption={customFilter}
                            value={LeadList?.filter(
                              (obj) => obj.value === selectLead?.value
                            )}
                            onChange={(e) => setSelectLead(e)}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Product</label>
                          <Select
                            isMulti
                            name="colors"
                            options={productList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Product"
                            filterOption={customFilter}
                            value={productList?.filter((obj) =>
                              selectProduct?.includes(obj.value)
                            )}
                            onChange={handleChangeProduct}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="common_btn ms-3"
                            onClick={() => GetAttList()}
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className="accordian_card">
                  {/* <div className=" d-flex justify-content-start my-3 ms-2">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Update Lead Status</label>
                        <select
                          className="form-select "
                          {...register("leadStatus", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Status</option>
                          <option value="Hot">Hot</option>
                          <option value="Warm">Warm</option>
                          <option value="Cold">Cold</option>
                        </select>
                        {errors.leadStatus && (
                          <span role="alert" className="error_text">
                            {errors.leadStatus.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group ms-3 mt-auto">
                      <Button className="common_btn " type="submit">
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div> */}
                  <div className="">
                    <AssignTable
                      title="Leads Report"
                      headCells={headCellsUser}
                      actionFirst={true}
                      ListData={attendeeList}
                      action={false}
                      dropdown={false}
                      addBtn={false}
                      showActionText={true}
                      status={true}
                      setUserArray={setUserArray}
                      handleMultiCheck={handleMultiCheck}
                      checkedValue={checkedValue}
                      setCheckValue={setCheckValue}
                      showUserListInTable={true}
                      showSubscribtn={false}
                      filterPage={filterPage}
                      // showSubscribtn={attendeeList?.length >= 3 ? true : false}
                      showSubscribeUrlName={
                        show
                          ? "Update Leads Status"
                          : "Subscribe To View More Leads"
                      }
                      showSubscribeUrl={
                        show
                          ? `/company-lead-tracking-updates`
                          : `/subscription/exhibitor`
                      }
                      fileName={"Leads Report"}
                      exportDataList={true}
                      innerProduct={true}
                    />
                  </div>
                </div>
              </div>
            </form>
            {/* </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default LeadsReport;
