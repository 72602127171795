import React, { useEffect, useState } from "react";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import "./index.scss";
import Swal from "sweetalert2";
import SchedulePayment from "./SchedulePayment";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

function PayToSchedule() {
  const [dataOfEvent, setDataOfEvent] = useState([]);
  const [evList, setEvList] = useState([]);
  const [evAddList, setEvAddList] = useState([]);
  const [subtotal, setSubTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState();

  const { id } = useParams();
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const nav = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getEventData();
    setValue("userName", LogDetails?.firstName + " " + LogDetails?.lastName);
    setValue("email", LogDetails?.email);
    setValue("phoneNumber", LogDetails?.phone);
  }, []);

  const getEventData = async () => {
    let res = await GetCallApi({
      url: `/api/AttendeeSchedule/GetAttendeeConferenceScheduleList?userId=${LogDetails?.userId}`,
    });
    if (res.status === 200) {
      if (res.data.success) {
        let comList1 = res?.data?.payload?.filter(
          (x) => x?.conferenceId === id
        )[0];
        setDataOfEvent(comList1);
        setValue("conferenceId", id);
        setValue("registerUserType", comList1?.registerUserType);
        setValue("attendeePaymentId", comList1?.attendeePaymentId);
        setValue("paymentMode", comList1?.paymentStatus);
        setValue("invoiceId", comList1?.invoiceId);
        setEvList(
          comList1?.eventDetails
            ?.filter((x) => x?.isRegistered !== true)
            ?.filter((x) => x?.eventPrice !== 0)
        );
      }
    }
  };

  const AddEventList = (e) => {
    setEvAddList([...evAddList, e]);

    let ead = evList?.map((x) => {
      if (x?.eventId === e?.eventId) {
        return { ...x, add: true };
      }
      return { ...x };
    });
    setEvList(ead);
  };

  const RemoveEventList = (e) => {
    setEvAddList((oldArray) =>
      oldArray?.filter((data) => data?.eventId !== e?.eventId)
    );
    let ead = evList?.map((x) => {
      if (x?.eventId === e?.eventId) {
        return { ...x, add: false };
      }
      return { ...x };
    });
    setEvList(ead);
  };

  const onSubmit = async (data) => {
    if (evAddList?.length > 0) {
      if (data?.paymentMode !== "Online") {
        setLoading(true);

        let seData = {
          url: `/api/AttendeeSchedule/PayPaidEventForAttendeeSchedule`,
          body: {
            ...data,
            amount: subtotal,
            userId: LogDetails?.userId,
            createdBy: LogDetails?.userId,
            eventId: evAddList?.map((x) => x?.eventId),
          },
        };

        let res = await PostCallApi(seData);
        setLoading(false);
        if (res?.status === 200) {
          if (res?.data?.isPaid) {
            //
            nav(-1);
            ToastSuccess(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            ToastError(res.data.message);
          }
        } else {
          ToastError(res.data.message);
        }
      } else {
        setLoading(true);

        setAllData({
          ...data,
          amount: subtotal,
          userId: LogDetails?.userId,
          createdBy: LogDetails?.userId,
          eventId: evAddList?.map((x) => x?.eventId),
        });
        setLoading(false);
      }
    } else {
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Please select minimum one Event",
        confirmButtonText: "Close",
        confirmButtonColor: "#E64942",
      });
    }
  };
  useEffect(() => {
    handleTotal();
  }, [evAddList]);
  const handleTotal = () => {
    let ep = evAddList?.map((ev) => ev?.eventPrice);
    const sum =
      ep?.length > 0
        ? ep?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;
    let sm = sum;

    setSubTotal(sm);
  };
  return (
    <section className="pay_to_schedule">
      {allData ? (
        <SchedulePayment allData={allData} />
      ) : (
        <div className="main_wrapper">
          <div className="form_box bookcompany_description">
            <div className="row">
              <div className="col-md-8">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="card">
                    <div className="card-body">
                      <div className="title attend-title">Attendee Details</div>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-group text-start">
                            <label className="form-label">User Name</label>
                            <input
                              type="text"
                              className="form-control "
                              placeholder="Enter your your name"
                              {...register("userName", {
                                required: "This field is required",
                              })}
                            />
                            {errors.userName && (
                              <span role="alert" className="error_text">
                                {errors.userName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group text-start">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control "
                              placeholder="Enter your email ID"
                              {...register("email", {
                                required: "This field is required",
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: "Please Enter Valid Email",
                                },
                              })}
                              disabled
                            />
                            {errors.email && (
                              <span role="alert" className="error_text">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group text-start">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your phone number"
                              {...register("phoneNumber", {})}
                              disabled
                            />
                            {errors.phoneNumber && (
                              <span role="alert" className="error_text">
                                {errors.phoneNumber.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <hr />

                        {evList?.length || evAddList?.length > 0 ? (
                          <div className="col-md-6">
                            <label className="form-label">
                              Pre_Registration Required - Please select the
                              events you plan to attend
                            </label>

                            {evList?.length === 0 ? (
                              ""
                            ) : (
                              <div className="pr_box_gtup">
                                {evList &&
                                  evList?.map((item, index) => (
                                    <div key={index} className="form-group1">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          {item?.eventName} - Fees:{" "}
                                          {item?.eventPrice
                                            ? item?.eventPrice
                                            : "Free"}
                                        </div>
                                        <div>
                                          {item?.add === true ? (
                                            <>
                                              <Button
                                                className="grey_button ms-2"
                                                onClick={() =>
                                                  RemoveEventList(item)
                                                }
                                              >
                                                <AiOutlineMinus />
                                              </Button>
                                            </>
                                          ) : (
                                            <>
                                              <Button
                                                className="green_button ms-2"
                                                onClick={() =>
                                                  AddEventList(item)
                                                }
                                              >
                                                <AiOutlinePlus className="icon" />
                                              </Button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <Button
                          className="ms-auto common_btn w-auto"
                          type="submit"
                          disabled={loading}
                          // onClick={() => payment()}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ overflow: "hidden" }}>
                  <div className="card-body p-0">
                    <div
                      className="conference_details"
                      style={{ padding: "1.25rem" }}
                    >
                      <div className="title mb-4 attend-title">
                        Conference Details
                      </div>
                      <ul>
                        <li>{dataOfEvent?.conferenceName}</li>
                        {evAddList?.map((evitem, i) => (
                          <li key={i}>
                            {evitem?.eventName}{" "}
                            <span>
                              {evitem?.eventPrice ? evitem?.eventPrice : "0.00"}
                            </span>
                          </li>
                        ))}
                      </ul>
                      <div className="subtotal">
                        <p>
                          Total <span>{subtotal ? subtotal : "0.00"}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default PayToSchedule;
