import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  // Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { VscCheck, VscEdit, VscEye } from "react-icons/vsc";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import { GetCallApi, PostCallApi } from "../../../Action/Action.jsx";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastError, ToastSuccess } from "../../Components/Toast/index.jsx";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi.jsx";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
// import { TabContext, TabList } from "@mui/lab";

const PermenentDeletePopup = lazy(() =>
  import("../../Components/PermenentDeletePopup/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "invoiceEmail",
    label: "Invoice Email",
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
  },
  {
    id: "poNumber",
    label: "PONumber",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "userType",
    label: "User Type",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "invoiceDate",
    label: "Submit Date",
  },
  {
    id: "dueDate",
    label: "Due Date",
  },
  {
    id: "receivedPaymentDate",
    label: "Pymt Date",
  },
  {
    id: "checkNumber",
    label: "Check Number",
  },
  {
    id: "isPayByOnline",
    label: "Paid Online",
  },
  {
    id: "invoiceStatus",
    label: "Status",
    color: true,
  },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function IndividualInvoiceApproval() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();
  const [filterPage, setFilterPage] = useState(false);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [userType, setUserType] = useState();

  useEffect(() => {
    document.title = "FIG Events | Invoice Approval";
    getConfList();
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const handleOpenPop = (eId, st) => {
    setApData({ ...eId, st: st });
    setOpenPop(true);
  };
  const handleClose = () => {
    setApData();
    setOpenPop(false);
    reset();
  };

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
    }
  };

  const GetInvoiceList = async (data) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let seData = {
      url: `/api/ExhibitorAttendeeInvoice/GetAllExhibitorAttendeeInvoice`,
      body: {
        ...data,
        invoiceStatus: data?.invoiceStatus ? data?.invoiceStatus : "",
        userType: data?.userType ? data?.userType : "",
        userId: logindetail?.userId,
      },
    };
    let res = await PostCallApi(seData);
    setFilterPage(!filterPage);
    setOpenCircle(false);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          bgcolor:
            c?.invoiceStatus === "Complete"
              ? ""
              : c?.days > 90
              ? "#f3c2c3"
              : c?.days > 60
              ? "#fffaca"
              : c?.days > 30
              ? "#18bad447"
              : // ? "#73c04d6b"
                "#18bad447",
          color:
            c?.invoiceStatus === "Approve"
              ? "new_white"
              : c?.invoiceStatus === "Complete"
              ? "approv"
              : "created",
          dueDate: c?.isPayByOnline
            ? ""
            : c?.dueDate
            ? new Date(c?.dueDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          receivedPaymentDate: c?.isPayByOnline
            ? c?.payOnlineDate
              ? new Date(c?.payOnlineDate)?.toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  hour: "2-digit",
                  hour12: true,
                })
              : ""
            : c?.receivedPaymentDate
            ? new Date(c?.receivedPaymentDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          invoiceDate: c?.invoiceDate
            ? new Date(c?.invoiceDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          isPayByOnline: c?.isPayByOnline ? "Yes" : "",
          invoiceStatus:
            c?.invoiceStatus === "Complete" ? "Completed" : c?.invoiceStatus,
          Action: (
            <>
              {c?.invoiceStatus === "Complete" ? (
                ""
              ) : (
                <>
                  {logindetail?.roleId === 1 ? (
                    <>
                      <Tooltip title="Complete">
                        <button
                          type="button"
                          className="btn_edit me-3"
                          onClick={() => handleOpenPop(c, "Complete")}
                        >
                          <VscCheck />
                        </button>
                      </Tooltip>
                      {/* <button
                          type="button"
                          className="btn_dlt me-3"
                          onClick={() => handleOpenPop(c, "Reject")}
                        >
                          <VscChromeClose className="icon" />
                        </button> */}
                    </>
                  ) : c?.viewApprove ? (
                    <Tooltip title="Complete">
                      <button
                        type="button"
                        className="btn_edit me-3"
                        onClick={() => handleOpenPop(c, "Complete")}
                      >
                        <VscCheck />
                      </button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Link
                    to={`/invoice-edit/${c?.exhibitorAttendeeInvoiceId}`}
                    className="btn_edit me-3 d-flex align-items-center"
                  >
                    <VscEdit />
                  </Link>
                </>
              )}

              <Link
                to={`/invoice-view/${c?.exhibitorAttendeeInvoiceId}`}
                className="btn_edit me-3 d-flex align-items-center"
              >
                <VscEye />
              </Link>
              <PermenentDeletePopup
                title={"Delete Invoice"}
                text={c?.userName + "'s invoice"}
                url={`/api/ExhibitorAttendeeInvoice/DeleteExhibitorAttendeeInvoiceByInvoiceId?exhibitorAttendeeInvoiceId=${c?.exhibitorAttendeeInvoiceId}&createdBy=${logindetail?.userId}&deletePermanently=`}
                callBack={true}
                callBackFunction={CallBackApi}
              />
              <Button
                className="ms-3"
                style={{
                  background:
                    "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                  padding: "0.5rem",
                  borderRadius: "5px",
                  color: "white",
                  textTransform: "none",
                }}
                onClick={() => handleOpenPop(c, "Resend")}
              >
                Resend
              </Button>
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const HandleRequest = async (data) => {
    setOpenCircle(true);
    if (apData?.st === "Resend") {
      if (apData?.userType === "User") {
        let seData = {
          url: `/api/ExhibitorAttendeeInvoice/ViewExhibitorAttendeeInvoiceDetails?exhibitorAttendeeInvoiceId=${apData?.exhibitorAttendeeInvoiceId}&isResend=true`,
        };

        setOpenCircle(true);
        let resp = await GetCallApi(seData);
        setOpenCircle(false);
        if (resp?.status === 200 && resp.data.success) {
          handleClose();
          ToastSuccess(resp.data.message);
        } else {
          ToastError(resp.data.message);
        }
      } else {
        setOpenCircle(true);
        let resp = await PostCallApi({
          url: `/api/ExhibitorAttendeeInvoice/SendInvoiceExhibitorEmailAfterRegistration`,
          body: {
            invoiceId: apData?.exhibitorAttendeeInvoiceId,
            memberManagementInvoiceId: apData?.memberManagementInvoiceId,
            userType: "Exhibitor",
            isZeroAmount: false,
          },
        });
        setOpenCircle(false);
        if (resp?.status === 200 && resp.data.success) {
          handleClose();
          ToastSuccess(resp.data.message);
        } else {
          ToastError(resp.data.message);
        }
      }
    } else {
      let reqData = {
        ...data,
        exhibitorAttendeeInvoiceId: apData?.exhibitorAttendeeInvoiceId,
        createdBy: logindetail?.userId,
        invoiceStatus: apData?.st,
      };

      let seData = {
        url: `/api/ExhibitorAttendeeInvoice/ApproveOrRejectExhibitorAttendeeInvoice`,
        body: reqData,
      };

      setOpenCircle(true);
      let resp = await PostCallApi(seData);
      setOpenCircle(false);
      if (resp?.status === 200 && resp.data.success) {
        handleClose();
        let msg = resp.data.message;
        CallBackApi();
        ToastSuccess(msg);
      } else {
        ToastError(resp.data.message);
      }
    }
  };
  var dtToday = new Date().toISOString().split("T")[0];

  const CallBackApi = () => {
    let send = {
      conferenceId: getValues("conferenceId"),
      invoiceStatus: getValues("invoiceStatus"),
      poNumber: getValues("poNumber") ? getValues("poNumber") : "",
      paymentDate: getValues("paymentDate") ? getValues("paymentDate") : null,
      userType: getValues("userType"),
    };
    GetInvoiceList(send);
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
    setValue("conferenceId", e);
  };

  const PaymentStatusOption = [
    { value: "Approve", label: "Approve" },
    { value: "Complete", label: "Complete" },
  ];

  const handlePaymentStatus = (e) => {
    setPaymentStatus(e);
    setValue("invoiceStatus", e);
  };

  const UserTypeOption = [
    { value: "Exhibitor", label: "Exhibitor" },
    { value: "User", label: "User" },
  ];

  const handleUserType = (e) => {
    setUserType(e);
    setValue("userType", e);
  };

  const handleClearFilter = () => {
    setConfId();
    setPaymentStatus();
    setUserType();
    setDataOfTable([]);
    reset();
  };
  // const handleChange2 = () => {};
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            {/* <TabContext value={"1"}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="lab API tabs example"
                  className={`session-tab `}
                >
                  <Tab
                    label={`Individual Invoice`}
                    value={`1`}
                    className="head-tab"
                  />{" "}
                  <Tab
                    label={`Group Invoice`}
                    value={`2`}
                    className="head-tab"
                  />
                </TabList>
              </Box>
            </TabContext> */}
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetInvoiceList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Conference</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={conList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Conference"
                              filterOption={customFilter}
                              value={conList?.filter(
                                (obj) => obj.value === confId
                              )}
                              onChange={(e) => handleConfereceSelect(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">PO Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter PO Number"
                              {...register("poNumber", {
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.poNumber && (
                              <span role="alert" className="error_text">
                                {errors.poNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Status</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={PaymentStatusOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Payment Status"
                              filterOption={customFilter}
                              value={PaymentStatusOption?.filter(
                                (obj) => obj.value === paymentStatus
                              )}
                              onChange={(e) => handlePaymentStatus(e?.value)}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("paymentDate", {
                                valueAsDate: true,
                              })}
                            />
                            {errors.paymentDate && (
                              <span role="alert" className="error_text">
                                {errors.paymentDate.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">User Type</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={UserTypeOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select User Type"
                              filterOption={customFilter}
                              value={UserTypeOption?.filter(
                                (obj) => obj.value === userType
                              )}
                              onChange={(e) => handleUserType(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    headCells={headCellsEvent}
                    title="Invoice Approval List"
                    action={true}
                    ListData={dataOfTable}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  open={openCircle}
                  sx={{ color: "#fff", zIndex: () => 9999 }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>Invoice {apData?.st} </h6>
                  <form onSubmit={handleSubmit(HandleRequest)}>
                    <div className="popup_delete_detail">
                      <p className="delete_text">
                        Are you sure you want to {apData?.st.toLowerCase()}{" "}
                        invoice {apData?.st === "Resend" ? "to" : "of"}{" "}
                        <span>{apData?.userName}?</span>
                      </p>
                      {apData?.st === "Complete" ? (
                        <>
                          <div className="form-group">
                            <label className="form-label">
                              Received Payment Date
                              <span className="red-color"> *</span>
                            </label>

                            <input
                              type="date"
                              className="form-control"
                              placeholder="Enter Email"
                              max={dtToday}
                              {...register("receivedPaymentDate", {
                                required: "This field is require",
                                valueAsDate: true,
                              })}
                            />
                            {errors?.receivedPaymentDate && (
                              <span className="error_text">
                                {errors.receivedPaymentDate.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="form-label">
                              Check Number
                              <span className="red-color"> *</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Check Number"
                              {...register("checkNumber", {
                                required: "This field is require",
                                pattern: {
                                  value: /^[0-9+-]+$/,
                                  message: "Enter valid number",
                                },
                              })}
                            />
                            {errors?.checkNumber && (
                              <span className="error_text">
                                {errors.checkNumber.message}
                              </span>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        type="submit"
                        className="popup_btn delete"
                        // onClick={HandleRequest}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default IndividualInvoiceApproval;
