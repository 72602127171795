import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import "./index.scss";
import { Badge, Menu, MenuItem, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetMenu } from "../../reducers/menu";
// import LogMainPop from "../../Modules/Components/LogPopup";
import { resetLogin } from "../../reducers/login";
import AssignHeadMenu from "../AssignHeadMenu";
import logo from "../../assets/images/fig_events.png";
import { Config } from "../../Utils/Config";
import { PiSignInBold } from "react-icons/pi";

const pro_img = "/assets/images/default_profile.jpg";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const openUMenu = Boolean(anchorEl2);

  let nav = useNavigate();
  const accountInformation = useSelector((state) => state.login.LoginDetails);
  const companyAccess = useSelector((state) => state.login.CompanyList);

  const handleClickUMenu = (event) => {
    setAnchorEl2(event?.currentTarget);
  };

  const handleCloseUMenu = () => {
    setAnchorEl2(null);
  };
  let dispatch = useDispatch();

  const handleLogOut = () => {
    localStorage.clear("expireDate");
    dispatch(resetLogin());
    dispatch(resetMenu());
    handleCloseUMenu();
    nav("/", { replace: true });
  };
  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.authenticate
  );
  let location = useLocation();

  const [settingoptions, setSettingoptions] = useState([
    {
      pathname: "/users",
      menuName: "Manage Users",
    },
    {
      pathname: "/event-approval",
      menuName: "Event Approval",
    },
  ]);
  useEffect(() => {
    if (accountInformation?.roleId === 1) {
      let sm = [
        {
          pathname: "/menus",
          menuName: "Menu List",
        },
        {
          pathname: "/menu-access",
          menuName: "Menu Access",
        },
      ];
      setSettingoptions([...settingoptions, ...sm]);
    }
    // eslint-disable-next-line
  }, [accountInformation]);

  return (
    <>
      <section className={`navigation`}>
        <div className="container-fluid">
          <div className="main_wrapper">
            <Navbar expand="xl" className="nav_bar gap-5">
              <div className="mobile_toggle">
                <NavLink
                  className="NavLink p-0"
                  to={accountInformation?.authenticate ? "/" : "/"}
                >
                  <img src={logo} className="logo img-fluid" alt="" />
                </NavLink>
                <div className="ms-auto d-flex align-items-center gap-3 d-block d-xl-none">
                  {accountInformation?.authenticate ? (
                    <>
                      {accountInformation?.roleName === "Speaker" ? (
                        ""
                      ) : (
                        <Tooltip title="Message" placement="top-start" arrow>
                          <Link
                            to={"/messages"}
                            className="header_search_button"
                          >
                            <Badge>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_6_815)">
                                  <path
                                    d="M23 11.3889C23.0042 13.0021 22.6273 14.5934 21.9 16.0333C21.0376 17.7588 19.7119 19.2101 18.0713 20.2247C16.4307 21.2393 14.5401 21.777 12.6111 21.7778C10.9979 21.782 9.40657 21.4051 7.96666 20.6778L1 23L3.32222 16.0333C2.59491 14.5934 2.21801 13.0021 2.22222 11.3889C2.22297 9.45995 2.76075 7.56929 3.77532 5.9287C4.78989 4.28811 6.24119 2.96239 7.96666 2.10003C9.40657 1.37273 10.9979 0.995829 12.6111 1.00003H13.2222C15.7697 1.14058 18.1759 2.21585 19.98 4.01997C21.7841 5.82409 22.8594 8.23027 23 10.7778V11.3889Z"
                                    stroke="#2C2E35"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <circle cx="8" cy="12" r="1" fill="#2C2E35" />
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="1"
                                    fill="#2C2E35"
                                  />
                                  <circle
                                    cx="16"
                                    cy="12"
                                    r="1"
                                    fill="#2C2E35"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6_815">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Badge>
                          </Link>
                        </Tooltip>
                      )}
                      <div
                        className="header_option "
                        onClick={handleClickUMenu}
                      >
                        {accountInformation?.profileImage ? (
                          <img
                            src={`${Config.API_HOST_URL_live}${accountInformation?.profileImage}`}
                            alt=""
                            className="user_profile"
                            width={40}
                            height={40}
                            style={{ borderRadius: "50px", objectFit: "cover" }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = pro_img;
                            }}
                          />
                        ) : (
                          <>
                            <img
                              src={pro_img}
                              style={{
                                borderRadius: "50px",
                                objectFit: "cover",
                              }}
                              className="user_profile"
                              width={40}
                              height={40}
                              alt=""
                            />
                          </>
                        )}
                        <div className="profile_info d-none d-lg-flex">
                          <span>
                            {accountInformation?.firstName
                              ? accountInformation?.firstName
                              : accountInformation?.name}
                            {accountInformation?.lastName
                              ? " " + accountInformation?.lastName
                              : ""}
                          </span>
                          <span className="user_type">
                            {accountInformation?.roleName}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Link className="navbar-toggler" to="/login">
                      <PiSignInBold
                        style={{
                          color: "#fff",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </Link>
                    // <LogMainPop btnName="Login/Register" />
                  )}
                </div>
                <NavbarToggler
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12H16"
                      stroke="#666666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 18H21"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NavbarToggler>
              </div>
              <Collapse
                isOpen={isOpen}
                navbar
                className="header_nav justify-content-end justify-content-xl-between justify-content-lg-between"
              >
                {loggedIn &&
                location.pathname !== "/" &&
                location.pathname !== "/login" &&
                location?.pathname !== "/register" &&
                location?.pathname !== "/forgot-passowrd" ? (
                  <>
                    <Nav navbar className="nav_action m-auto">
                      <AssignHeadMenu />
                    </Nav>
                  </>
                ) : (
                  <>
                    <Nav
                      navbar
                      className="m-auto nav_action align-items-center "
                      // style={{ visibility: "hidden" }}
                    >
                      <li className="hover_link">
                        <NavLink className="hover" to="/">
                          Home
                        </NavLink>
                      </li>
                      <li className="hover_link">
                        {location?.pathname === "/" ? (
                          <Link className="hover" to="about" duration={500}>
                            About
                          </Link>
                        ) : (
                          <NavLink className="hover" to="/">
                            About
                          </NavLink>
                        )}
                      </li>
                      <li className="hover_link">
                        <NavLink className="hover" to="/how-it-works">
                          How It Works
                        </NavLink>
                      </li>
                      <li className="hover_link">
                        <NavLink className="hover" to="/our-products">
                          Our Products
                        </NavLink>
                      </li>
                      <li className="hover_link">
                        <NavLink className="hover" to="/blogs">
                          Blogs
                        </NavLink>
                      </li>
                    </Nav>
                  </>
                )}

                <div className="align-items-center gap-3 d-none d-xl-flex">
                  {accountInformation?.authenticate ? (
                    <>
                      {accountInformation?.roleName === "Speaker" ? (
                        ""
                      ) : (
                        <Tooltip title="Message" placement="top-start" arrow>
                          <Link
                            to={"/messages"}
                            className="header_search_button"
                          >
                            <Badge>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_6_815)">
                                  <path
                                    d="M23 11.3889C23.0042 13.0021 22.6273 14.5934 21.9 16.0333C21.0376 17.7588 19.7119 19.2101 18.0713 20.2247C16.4307 21.2393 14.5401 21.777 12.6111 21.7778C10.9979 21.782 9.40657 21.4051 7.96666 20.6778L1 23L3.32222 16.0333C2.59491 14.5934 2.21801 13.0021 2.22222 11.3889C2.22297 9.45995 2.76075 7.56929 3.77532 5.9287C4.78989 4.28811 6.24119 2.96239 7.96666 2.10003C9.40657 1.37273 10.9979 0.995829 12.6111 1.00003H13.2222C15.7697 1.14058 18.1759 2.21585 19.98 4.01997C21.7841 5.82409 22.8594 8.23027 23 10.7778V11.3889Z"
                                    stroke="#2C2E35"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <circle cx="8" cy="12" r="1" fill="#2C2E35" />
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="1"
                                    fill="#2C2E35"
                                  />
                                  <circle
                                    cx="16"
                                    cy="12"
                                    r="1"
                                    fill="#2C2E35"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6_815">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Badge>
                          </Link>
                        </Tooltip>
                      )}
                      <div
                        className="header_option "
                        onClick={handleClickUMenu}
                      >
                        {accountInformation?.profileImage ? (
                          <img
                            src={`${Config.API_HOST_URL_live}${accountInformation?.profileImage}`}
                            alt=""
                            className="user_profile"
                            width={40}
                            height={40}
                            style={{ borderRadius: "50px", objectFit: "cover" }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = pro_img;
                            }}
                          />
                        ) : (
                          <>
                            <img
                              src={pro_img}
                              style={{
                                borderRadius: "50px",
                                objectFit: "cover",
                              }}
                              className="user_profile"
                              width={40}
                              height={40}
                              alt=""
                            />
                          </>
                        )}
                        <div className="profile_info d-none d-lg-flex">
                          <span>
                            {accountInformation?.firstName
                              ? accountInformation?.firstName
                              : accountInformation?.name}
                            {accountInformation?.lastName
                              ? " " + accountInformation?.lastName
                              : ""}
                          </span>
                          <span className="user_type">
                            {accountInformation?.roleName}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Link className="navbar-toggler" to="/login">
                      <PiSignInBold
                        style={{
                          color: "#fff",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </Link>
                    // <LogMainPop btnName="Login/Register" />
                  )}
                </div>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </section>
    
      <Menu
        className="menu_list"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl2}
        open={openUMenu}
        onClose={handleCloseUMenu}
      >
        {accountInformation?.roleName !== "Speaker" ? (
          <MenuItem onClick={handleCloseUMenu}>
            <Link
              to={`/attendee-profile`}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              Standard Profile
            </Link>
          </MenuItem>
        ) : (
          ""
        )}
        {accountInformation?.roleName === "Speaker" ? (
          <MenuItem onClick={handleCloseUMenu}>
            <Link
              to={`/speaker-profile`}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              Profile
            </Link>
          </MenuItem>
        ) : (
          ""
        )}
        {accountInformation?.roleName === "Admin" ||
        accountInformation?.roleName === "Exhibitor" ||
        accountInformation?.isCompanyStoreAccess === true ? (
          <MenuItem onClick={handleCloseUMenu}>
            <Link
              to={`/exhibitor`}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              Company Store
            </Link>
          </MenuItem>
        ) : (
          ""
        )}
        {accountInformation?.roleName === "Admin" ||
        companyAccess?.length > 0 ? (
          <MenuItem onClick={handleCloseUMenu}>
            <Link
              to={`/company-user-access`}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              Company User Access
            </Link>
          </MenuItem>
        ) : (
          ""
        )}

        {/* {accountInformation?.roleName === "Admin" ||
        accountInformation?.roleName === "	Exhibitor" ? (
          <MenuItem>
            <Link
              to={`/exhibitor-data/${accountInformation?.userId}`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Exhibitor
            </Link>
          </MenuItem>
        ) : (
          ""  
        )} */}
        {accountInformation?.isRegisterExhibitorAnyConference ||
        accountInformation?.roleName === "Admin" ? (
          <MenuItem onClick={handleCloseUMenu}>
            <Link
              to={`/booth-team-member-update`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Booth Team Member Update
            </Link>
          </MenuItem>
        ) : (
          ""
        )}
        <MenuItem onClick={handleCloseUMenu}>
          <Link
            to={`/email-preference`}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Email Preference
          </Link>
        </MenuItem>
        <MenuItem onClick={handleCloseUMenu}>
          <Link
            to={`/my-qr-codes`}
            style={{
              textDecoration: "none",
              color: "inherit",
              width: "100%",
            }}
          >
            My Badges
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <span className="header_user_link_text2">Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
}

export default React.memo(Navigation);
