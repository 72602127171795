import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { AttendeeRegistrationConference } from "../../../reducers/Common";
import ReactQuill from "react-quill";
import {
  // CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useNavigate, useParams } from "react-router-dom";
import useResponsiveFontSize from "../../SuperAdmin/MemberManagement/Association/UserAssociation/useResponsiveFontSize";
import { SwalMessage, ToastError, ToastSuccess } from "../../Components/Toast";

const deleteIcon = "/assets/images/deleteIcon.png";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

function EditAssociationUserPayment(props) {
  const { id, invoiceId } = useParams();
  const { companyData } = props;
  const [subtotal, setSubTotal] = useState("");
  const { setValue } = useForm();
  const [clientPublicKey, setClientPublicKey] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [btnLoad1, setBtnLoad1] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [payment, setPayment] = useState();

  const LogDetails = useSelector((state) => state.login.LoginDetails);

  let ATRC = useSelector((state) => state.Common?.ATRC);
  let dispatch = useDispatch();
  let nav = useNavigate();
  useEffect(() => {
    if (companyData) {
      setValue("amount", companyData.subtotal);
      setSubTotal(companyData?.subtotal);
      if (companyData?.paymentMode !== "Invoice") {
        PCSec();
      }
    }
    // eslint-disable-next-line
  }, [companyData]);

  const RequestInvoice = async (data) => {
    let seData = {
      url: `/api/AssociationPayment/AddAssociationInvoice`,
      body: {
        ...data,
        associationInvoiceId: invoiceId,
        createdBy: LogDetails?.userId,
        totalAmount: subtotal,
        addressLine1: data?.address1,
        zipCode: data?.zip,
        invoiceInstructions: data?.editorValue,
        paymentMode: "Invoice",
        paymentStatus: "Success",
        invoiceStatus: "Approve",
        // discountValue: discountValue?.discountValue,
        discountValue: companyData?.associationUserList
          ?.filter((curr) => curr?.discount > 0)
          ?.reduce((acc, curr) => acc + curr?.discount, 0),
        associationName: companyData?.name,
        associationPrice: subtotal.toString(),
        associationMembershipsTypesId:
          companyData?.associationMembershipsTypesId,
        associationId: companyData?.associationId,
        associationMemberId: companyData?.associationMemberId,
        companyName: companyData?.companyName,
        paymentAddress:
          ATRC?.paymentRemittanceAddress +
          " \r\n" +
          ATRC?.paymentCity +
          " " +
          ATRC?.paymentState +
          " " +
          ATRC?.paymentZip,
        registerType: "User",
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res?.data?.success) {
        await SendUser({
          paymentMode: "Invoice",
          associationInvoiceId: res?.data?.payload?.associationInvoiceId,
          associationPaymentId: res?.data?.payload?.associationPaymentId,
        });
        dispatch(AttendeeRegistrationConference());
        nav(`/association-invoice-list/view/${invoiceId}`, { replace: true });
      }
    }
  };

  const ShowInvoice = ({ RequestInvoice, invoiceId }) => {
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();
    const [editorValue, setEditorValue] = useState("");
    const [derror, setDerror] = useState();

    useEffect(() => {
      if (invoiceId) {
        getInvoiceData(invoiceId);
      }
      // eslint-disable-next-line
    }, [invoiceId]);
    const getInvoiceData = async (ind) => {
      let res = await GetCallApi({
        url: `/api/AssociationPayment/GetAssociationMembershipDataByAssociationPaymentId?associationPaymentId=${ind}`,
      });
      if (res.status === 200) {
        if (res.data.success) {
          let useDe = res.data.payload.ai;
          setValue("name", useDe?.name);
          setValue("email", useDe?.email);
          setValue("address1", useDe?.addressLine1);
          setValue("purchaseOrderNumber", useDe?.purchaseOrderNumber);
          setValue("city", useDe?.city);
          setValue("State", useDe?.state);
          setValue("zip", useDe?.zipCode);
          setEditorValue(useDe?.invoiceInstructions);
        }
      }
    };

    const handleEditotValue = (value) => {
      if (value?.length > 1000) {
        setDerror({ ed: "Max 1000 character" });
      } else {
        setDerror({ ed: "" });
        setEditorValue(value);
      }
    };

    return (
      <form
        onSubmit={handleSubmit(async (data) => {
          RequestInvoice({ ...data, editorValue });
        })}
      >
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">
              Email Invoice To
              <span className="red-color"> *</span>
            </label>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                {...register("email", {
                  required: "This field is require",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please Enter Valid Email",
                  },
                })}
              />
              {errors?.email && (
                <span className="error_text">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <div>
                <label className="form-label">Address</label>
              </div>
              <label className="form-label">
                Name<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                {...register("name", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 500,
                    message: "You can enter max 500 charactor",
                  },
                })}
              />
              {errors?.name && (
                <span className="error_text">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3 mt-auto">
            <div className="form-group  mb-3">
              <label className="form-label">
                Address Line 1<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                {...register("address1", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.address1 && (
                <span className="error_text">{errors.address1.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mt-auto">
            <div className="form-group mb-3">
              <label className="form-label">PO Number </label>
              <input
                type="text"
                placeholder="Enter PO Number"
                className="form-control"
                {...register("purchaseOrderNumber", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.purchaseOrderNumber && (
                <span className="error_text">
                  {errors.purchaseOrderNumber.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                City<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter City"
                {...register("city", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.city && (
                <span className="error_text">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                State<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter State"
                {...register("State", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.State && (
                <span className="error_text">{errors.State.message}</span>
              )}
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                Zip Code
                <span className="red-color"> *</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 10,
                    message: "You can enter max 10 charactor",
                  },
                })}
              />
              {errors?.zip && (
                <span className="error_text">{errors.zip.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 ">
            <label className="form-label">Invoice Introduction</label>
            <ReactQuill
              value={editorValue}
              onChange={(value) => handleEditotValue(value)}
              // style={{ height: "200px", overflow: "auto" }}
              className="ql_show"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              theme="snow"
            />
            {derror?.ed && (
              <span role="alert" className="d-block error_text">
                {derror.ed}
              </span>
            )}
          </div>
          <div className="col-md-12 ">
            <Button className="common_btn mt-3" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const SendUser = async (data) => {
    let Dt =
        companyData?.associationUserList
          ?.filter((curr) => curr?.discount > 0)
          ?.reduce((acc, curr) => acc + curr?.discount, 0) ?? 0,
      ta = subtotal + Dt;

    let user = companyData?.associationUserList?.map((x) => {
      let cost = x?.totalAmount ?? 0,
        myDISAmount = cost > 0 ? Dt / (ta / cost) : 0,
        FA = cost > 0 ? cost - myDISAmount : 0;
      return {
        ...x,
        totalAmount: FA ? parseFloat(FA?.toFixed(2)) : FA,
        discount: myDISAmount
          ? parseFloat(myDISAmount?.toFixed(2))
          : myDISAmount,
      };
    });
    let send = { ...data, ...companyData, associationUserList: user };

    await PostCallApi({
      url: `/api/AssociationPayment/GroupAssociationUserAllocation`,
      body: send,
    });
  };

  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  const applyPromo = async (data) => {
    setBtnLoad1(true);
    let seData = {
      url: `/api/Payment/GetAmountAfterApplyPromoCodeOnConference`,
      body: { ...data, type: "Association" },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res.data.success) {
      let am = res.data.payload;

      setSubTotal(am?.finalAmount);
      let disc =
        discountValue?.length > 0
          ? [
              ...discountValue,
              { ...am, promoCode: data?.promoCode, codeId: generateId(10) },
            ]
          : [{ ...am, promoCode: data?.promoCode, codeId: generateId(10) }];
      setDiscountValue(disc);

      setBtnLoad1(false);
    } else {
      setBtnLoad1(false);
      ToastError(res.data.message);
    }
  };
  const handlePromo = (d) => {
    if (d?.promoCode) {
      let send = {
        amount: subtotal,
        conferenceId: id,
        userId: LogDetails?.userId,
        promoCode: d?.promoCode,
      };
      applyPromo(send, d);
    } else {
      SwalMessage("Please enter discount code");
    }
  };
  const deletePromo = (id) => {
    let dv = discountValue?.filter((x) => x?.codeId !== id);
    setDiscountValue(dv);
    let vl = discountValue?.find((x) => x?.codeId === id).discountValue;
    setSubTotal(subtotal + vl);
  };

  const DiscountApply = ({ handlePromo, subtotal, btnLoad }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    return (
      <form onSubmit={handleSubmit(handlePromo)} className="mt-3">
        <div className="row">
          <div className="col-md-10">
            <div className="form-group">
              <label className="form-label">Discount Code</label>
              <input
                type="text"
                placeholder="Enter Discount Code"
                className="form-control"
                disabled={subtotal === 0 ? true : false}
                {...register("promoCode", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors.promoCode && (
                <span role="alert" className="error_text">
                  {errors.promoCode.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-2 my-auto text-end pe-3">
            <Button
              className="common_btn"
              type="submit"
              disabled={subtotal === 0 ? true : btnLoad ? true : false}
              // disabled={discountValue ? true : false}
            >
              {subtotal === 0 ? (
                "Apply"
              ) : btnLoad ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    color: "#fff",
                    marginLeft: "0.5rem",
                    height: "23px !important",
                    width: "23px !important",
                  }}
                />
              ) : (
                "Apply"
              )}
            </Button>
            {/* )} */}
          </div>
        </div>
      </form>
    );
  };
  const PCSec = async () => {
    let seData = {
      url: `/api/AssociationPayment/GetPaymentKeysByAssociationId?associationId=${id}`,
      body: {},
    };
    const res = await PostCallApi(seData);

    if (res?.status === 200 && res.data.success) {
      const clientPub = res.data?.payload?.publicKey;
      setClientPublicKey(clientPub);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const CLSec = async () => {
    if (parseInt(subtotal) === 0) {
      setBtnLoad(true);
      let paymentData = {
        ...companyData,
        userId: LogDetails?.userId,
        email: LogDetails?.email,
        amount: subtotal,
        discountAmount: discountValue?.reduce(
          (acc, curr) => acc + curr?.discountValue,
          0
        ),
        promoCodeList:
          discountValue?.map((x) => {
            return {
              promoCodeId: x?.promoCodeId,
              discountAmount: x?.discountValue,
            };
          }) ?? [],
        associationMembershipsTypesId:
          companyData?.associationMembershipsTypesId,
        associationId: companyData?.associationId,
        registerType: "User",
        paymentMode: "Online",
      };
      let sveCC = {
        url: `/api/AssociationPayment/AssociationPaymentProcess`,
        body: paymentData,
      };
      let res = await PostCallApi(sveCC);
      if (res?.status === 200 && res.data.isPaid) {
        await SendUser({
          paymentMode: "Online",
          associationPaymentId: res?.data?.paymentId,
        });
        dispatch(AttendeeRegistrationConference());
        nav(`/association-stripe-recipe/${res?.data?.paymentId}`, {
          replace: true,
        });
      } else {
        setBtnLoad(false);
      }
    } else {
      setPayment(true);
    }
  };

  const stripePromise = loadStripe(clientPublicKey);
  const CheckoutForm = ({ SendUser }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [derror, setDerror] = useState();
    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 500);
    }, []);
    const [dis, setDis] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      // event.preventDefault();
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (!stripe || !elements) {
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data?.cardName,
            address: { postal_code: data?.zip ? data?.zip : null },
          },
          // card: elements.getElement(CardElement),
        });

        if (error) {
          // handle payment error
        } else {
          const sourceId = paymentMethod.id;
          let paymentData = {
            ...companyData,
            userId: LogDetails?.userId,
            email: LogDetails?.email,
            amount: subtotal,
            discountAmount: discountValue?.reduce(
              (acc, curr) => acc + curr?.discountValue,
              0
            ),
            promoCodeList:
              discountValue?.map((x) => {
                return {
                  promoCodeId: x?.promoCodeId,
                  discountAmount: x?.discountValue,
                };
              }) ?? [],
            associationMembershipsTypesId:
              companyData?.associationMembershipsTypesId,
            associationId: companyData?.associationId,
            paymentMode: "Online",
            registerType: "User",
          };
          let sveCC = {
            url: `/api/AssociationPayment/AssociationPaymentProcess`,
            body: {
              ...paymentData,
              PaymentMethodId: sourceId,
            },
          };
          setDis(true);
          let res = await PostCallApi(sveCC);
          if (res.status === 200) {
            if (res.data.isPaid) {
              await SendUser({
                paymentMode: "Online",
                associationPaymentId: res?.data?.paymentId,
              });
              setDis(false);
              dispatch(AttendeeRegistrationConference());
              nav(`/association-stripe-recipe/${res?.data?.paymentId}`, {
                replace: true,
              });

              ToastSuccess(res.data.message);
            } else {
              setDis(false);
              ToastError(res?.data?.message);
            }
          } else {
            setDis(false);

            ToastError(res?.data?.message);
          }
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };
    // const CARD_OPTIONS = {
    //   iconStyle: "solid",
    //   style: {
    //     base: {
    //       iconColor: "#666EE8",
    //       color: "#31325F",
    //       lineHeight: "40px",
    //       fontWeight: 600,
    //       fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
    //       fontSize: "16px",

    //       "::placeholder": {
    //         color: "#CFD7E0",
    //       },
    //     },
    //   },
    // };
    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Name On Card</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter name on card"
                {...register("cardName", {
                  required: "This field is required",
                })}
              />
              {errors.cardName && (
                <span role="alert" className="error_text">
                  {errors.cardName.message}
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 "
            // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
          >
            <div className="form-group">
              <label className="form-label">Card Number</label>
              <CardNumberElement className="form-control" options={options} />
            </div>
            {/* <CardElement options={CARD_OPTIONS} /> */}
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label"> Expiration date</label>
              <CardExpiryElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label">CVC</label>
              <CardCvcElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  // required: "This field is required",
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors.zip && (
                <span role="alert" className="error_text">
                  {errors.zip.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        <Button
          className="common_btn"
          type="submit"
          disabled={!stripe || dis}
        >
          {dis ? (
            <CircularProgress
              color="inherit"
              sx={{
                color: "#fff",
                marginLeft: "0.5rem",
                height: "23px !important",
                width: "23px !important",
              }}
            />
          ) : (
            "Complete Payment"
          )}
        </Button>
      </form>
    );
  };

  return (
    <>
      <div className="">
        <div className="title attend-title mb-3">Payment Details</div>
        <div className="col-md-6">
          <div className="conference_details border-bottom-0 mb-4">
            <ul>
              <li>
                Fees
                <span>
                  {companyData?.subtotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>{" "}
              {discountValue?.length > 0 ? (
                <>
                  <h5 className="mt-3">Discount Code</h5>
                  {discountValue?.map((x, i) => (
                    <li key={i}>
                      <p>
                        {/* Discount */}
                        {x?.promoCode}
                        <span className="ms-auto">
                          {" "}
                          -
                          {x
                            ? x.discountValue?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : ""}
                        </span>
                        {!payment ? (
                          <button
                            type="button"
                            className="deleteBtn"
                            onClick={() => deletePromo(x?.codeId)}
                          >
                            <img
                              src={deleteIcon}
                              className="img-fluid"
                              alt=""
                            />
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                    </li>
                  ))}
                  <li>
                    <p>
                      Total Discount
                      <span className="ms-auto">
                        {" "}
                        -
                        {discountValue
                          ?.reduce((acc, curr) => acc + curr?.discountValue, 0)
                          ?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </span>
                    </p>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <div className="subtotal">
              <p>
                Total{" "}
                <span>
                  {subtotal
                    ? subtotal?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "0.00"}
                </span>
              </p>
            </div>
            <div className="row">
              <div className="col-md-10 ">
                {!payment && (
                  <DiscountApply
                    handlePromo={handlePromo}
                    discountValue={discountValue}
                    subtotal={subtotal}
                    btnLoad={btnLoad1}
                  />
                )}
              </div>
              <div className="col-md-2 text-end my-auto">
                {!payment && (
                  <div className="mt-3 text-end">
                    <Button
                      className="common_btn"
                      onClick={CLSec}
                      disabled={btnLoad}
                    >
                      {btnLoad ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Pay"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {payment ? (
          companyData?.paymentMode === "Invoice" ? (
            <ShowInvoice
              RequestInvoice={RequestInvoice}
              invoiceId={invoiceId}
            />
          ) : (
            <Elements stripe={stripePromise}>
              <CheckoutForm SendUser={SendUser} />
            </Elements>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default EditAssociationUserPayment;
