import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/fig_events.png";
import "./index.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import ReactQuill from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import TableHeader from "../../../../TableHeader.json";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));

function ViewAssociationMembershipInvoice() {
  const { id } = useParams();
  const [ATRC, setATRC] = useState();
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const tableRef = useRef(null);
  let Time = new Date();
  let nav = useNavigate();
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const LoginDetail = useSelector((state) => state.login.LoginDetails);

  const handleDownloadPdf = async () => {
    // return false;
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);
        nav(-1);
      });
  };
  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    // maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };
  let newtime = new Date(ATRC?.invoiceDate);
  newtime?.setDate(newtime?.getDate() + 30);
  newtime = newtime.toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  useEffect(() => {
    if (id) {
      getInvoiceData(id);
    }
    // eslint-disable-next-line
  }, [id]);
  const getInvoiceData = async (ind) => {
    setOpen(true);
    let res = await GetCallApi({
      url: LoginDetail?.userId
        ? `/api/AssociationPayment/ViewOrResendAssociationInvoiceDetails?associationInvoiceId=${ind}&isResend=false`
        : `/api/AssociationPayment/ViewOrResendAssociationInvoiceDetailsWithoutToken?associationInvoiceId=${ind}`,
    });
    setOpen(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        setATRC({
          ...useDe,
          associationUserDetail:
            useDe?.registerType === "User"
              ? useDe?.associationUserDetail?.map((x) => {
                  return {
                    ...x,
                    totalPrice: x?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }),
                  };
                })
              : useDe?.associationCompanyDetail?.map((x) => {
                  return {
                    ...x,
                    totalPrice: x?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }),
                  };
                }),
        });
      }
    }
  };
  const associationPaymentReceiptTableUser =
    ATRC?.registerType === "User"
      ? TableHeader?.associationPaymentReceiptTableUser
      : TableHeader?.associationPaymentReceiptTableCompany;

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="Register_Conference_Form ">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              {LoginDetail?.userId ? (
                <Button
                  className="common_btn mb-3"
                  onClick={() => setDownloadpdf(true)}
                >
                  Download
                </Button>
              ) : (
                ""
              )}

              <section
                className="association-membership-invoice"
                ref={tableRef}
                style={back}
              >
                <div className="invoice-section">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="logo">
                        <img
                          src={logo}
                          style={{
                            height: "60px",
                          }}
                          alt="Confrence Planner"
                          title="Confrence Planner"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="d-flex align-items-center gap-3 justify-content-end">
                        <h2>Invoice</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6  mb-4">
                      <h5 className="text-uppercase">
                        {ATRC?.associationName}
                      </h5>
                      <div className="Billto-wrap">
                        <p>{ATRC?.paymentRemittanceAddress}</p>
                        <p>
                          {ATRC?.paymentCity +
                            " " +
                            ATRC?.paymentState +
                            " " +
                            ATRC?.paymentZip}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 text-end">
                      <div className="invoice-details">
                        <ul
                          className={`${ATRC?.isPayByOnline ? "online" : ""}`}
                        >
                          <li>
                            <span className="primarylight-color">
                              Invoice Number
                            </span>
                            <span className="invoice-text">
                              {ATRC?.invoiceNumber}
                            </span>
                          </li>
                          {ATRC?.isPayByOnline ? (
                            <li>
                              <span className="primarylight-color">
                                Payment Reference
                              </span>
                              <span className="invoice-text">
                                {ATRC?.paymentReference}
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            <span className="primarylight-color">
                              PO Number
                            </span>
                            <span className="invoice-text">
                              {ATRC?.purchaseOrderNumber}
                            </span>
                          </li>
                          <li>
                            <span className="primarylight-color">
                              Invoice Date
                            </span>
                            <span className="invoice-text">
                              {new Date(ATRC?.invoiceDate)?.toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                            </span>
                          </li>
                          {ATRC?.isPayByOnline ? (
                            <>
                              <li>
                                <span className="primarylight-color">
                                  Payment Date
                                </span>
                                <span className="invoice-text">
                                  {new Date(
                                    ATRC?.payOnlineDate
                                  )?.toLocaleString("en-us", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    minute: "2-digit",
                                    hour: "2-digit",
                                    hour12: true,
                                  })}
                                </span>
                              </li>
                              <li>
                                <span className="primarylight-color">
                                  Status
                                </span>
                                <span className="invoice-text">Paid</span>
                              </li>
                            </>
                          ) : (
                            <li>
                              <span className="primarylight-color">
                                Due Date
                              </span>
                              <span className="invoice-text">{newtime}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6  mb-4">
                      <h5 className="text-uppercase">Billed To</h5>
                      <div className="Billto-wrap">
                        <p>
                          {ATRC?.associationMemberName
                            ? ATRC?.associationMemberName
                            : ATRC?.companyName}
                        </p>
                        <p>{ATRC?.name}</p>
                        <p>{ATRC?.addressLine1}</p>
                        <p>
                          {ATRC?.city + " " + ATRC?.state + " " + ATRC?.zipCode}
                        </p>
                        <p>{ATRC?.email}</p>
                      </div>
                    </div>
                    {!ATRC?.isPayByOnline &&
                    ATRC?.invoiceStatus !== "Complete" &&
                    ATRC ? (
                      <div className="col-md-6 text-end">
                        <Link
                          className="common_btn"
                          to={`/stripe/association-membership/${ATRC?.associationInvoiceId}`}
                          // state={{
                          //   associationId: ATRC?.associationId,
                          //   associationPaymentId: ATRC?.associationPaymentId,
                          //   associationInvoiceId: ATRC?.associationInvoiceId,
                          //   amount: ATRC?.totalAmount,
                          // }}
                        >
                          Pay Now
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="table-wrap table-responsive mt-5">
                    <h5>
                      Membership{" "}
                      {ATRC?.registerType === "User" ? "User" : "Company"}{" "}
                      Details
                    </h5>
                    <Table className="table-bordered Conference-table">
                      <TableHead className="thead-primary">
                        <StyledTableRow>
                          {associationPaymentReceiptTableUser?.map((x, i) => (
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                                width: x?.label === "Email" ? "150px" : "",
                              }}
                              key={i}
                            >
                              {x?.label}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {ATRC?.associationUserDetail?.map((us, id) => (
                          <React.Fragment key={id}>
                            <StyledTableRow>
                              {associationPaymentReceiptTableUser?.map(
                                (column) => {
                                  const value = us[column?.id];
                                  let stl =
                                    column?.label === "Name"
                                      ? {
                                          width: "150px",
                                          wordBreak: "break-word",
                                        }
                                      : {};
                                  return (
                                    <StyledTableCell
                                      className={`column_data`}
                                      style={stl}
                                      key={column?.id}
                                    >
                                      {value ? value : ""}
                                    </StyledTableCell>
                                  );
                                }
                              )}
                            </StyledTableRow>
                            {us?.discount > 0 ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  style={{
                                    backgroundColor: "white",
                                    textAlign: "right",
                                    borderBottom: "1px solid #808080",
                                  }}
                                  colSpan={
                                    associationPaymentReceiptTableUser?.length -
                                    1
                                  }
                                >
                                  Discount
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    backgroundColor: "white",
                                    borderBottom: "1px solid #808080",
                                  }}
                                >
                                  -
                                  {us?.discount?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        ))}
                        {ATRC?.discountValue ? (
                          <StyledTableRow>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                textAlign: "right",
                                borderBottom: "1px solid #808080",
                              }}
                              colSpan={
                                associationPaymentReceiptTableUser?.length - 1
                              }
                            >
                              Total Discount
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                borderBottom: "1px solid #808080",
                              }}
                            >
                              -
                              {ATRC?.discountValue?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          ""
                        )}
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              textAlign: "right",
                              borderBottom: "1px solid #808080",
                            }}
                            colSpan={
                              associationPaymentReceiptTableUser?.length - 1
                            }
                          >
                            <b>Total</b>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              borderBottom: "1px solid #808080",
                            }}
                          >
                            <b>
                              {ATRC?.totalAmount?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </b>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div className="table-wrap mt-3">
                    <h5>Payment User Details</h5>
                    <Table className="table-bordered Conference-table">
                      <TableHead className="thead-primary">
                        <StyledTableRow>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            User Name
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Phone
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Price
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>
                            {ATRC?.userName ? ATRC?.userName : ATRC?.name}
                          </StyledTableCell>
                          <StyledTableCell>{ATRC?.userEmail}</StyledTableCell>
                          <StyledTableCell>{ATRC?.userPhone}</StyledTableCell>
                          <StyledTableCell>
                            {ATRC?.totalAmount?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </div>
                  {/* <div className="row mt-5">
                    <div className="col-lg-12 text-lg-end mt-3">
                      <h5 className="mt-lg-7">Becki Swan</h5>
                      <p>Co-founder, Fig Events</p>
                    </div>
                  </div> */}
                  <div className="row term-text mt-3">
                    <div className="col-md-12">
                      <h5>Invoice Instructions</h5>
                      <ReactQuill
                        value={ATRC?.invoiceInstructions}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </div>
                  </div>
                  {/* <div className="row term-text">
            <div className="col-md-12">
              <h5>Terms &amp; Conditions</h5>
              <ul className="ps-3">
                <li className="primarylight-color">
                  {" "}
                  Please pay within 30 days from the date of invoice, overdue
                  interest @ 14% will be charged on delayed payments.
                </li>
              </ul>
            </div>
          </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewAssociationMembershipInvoice;
