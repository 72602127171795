import React, { lazy, Suspense, useEffect, useState } from "react";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { SwalMessage, ToastError } from "../../Components/Toast";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsCompanyReg = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
  },
  {
    id: "companyList",
    label: "Company Name",
    widthFix: 600,
  },
  {
    id: "associationName",
    label: "Organization Name",
    widthFix: 600,
  },
  // {
  //   id: "payment",
  //   label: "Payment Amount",
  // },
  {
    id: "adaDisabilities",
    label: "ADA Disabilities",
    widthFix: 600,
  },
  {
    id: "mealRequirement",
    label: "Meal Requirement",
    widthFix: 600,
  },
  {
    id: "paymentDate",
    label: "Register Date",
  },
];
function ConferenceRegisterUser() {
  const [conferenceList, setConferenceList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [userType, setUserType] = useState("User");
  const [confId, setConfId] = useState("");
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);
  const [filterPage, setFilterPage] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Register User";
    GetAllEventsList();
    getLEAList();
    getAgencyist();
    getIndustryist();
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const GetAllEventsList = async () => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${logindetail?.userId}`,
    });
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((x) => {
        return {
          ...x,
          label: x?.conferenceName,
          value: x?.conferenceId,
        };
      });
      setConferenceList(comList);
    }
  };

  const GetRegistrationList = async (type, conId) => {
    setDataOfTable1([]);
    let member = [];
    if (selectLea?.length > 0) {
      member = [...selectLea];
    }
    if (selectAgency?.length > 0) {
      member = [...member, ...selectAgency];
    }
    if (selectIndustry?.length > 0) {
      member = [...member, ...selectIndustry];
    }
    if (type && conId) {
      setOpenCircle(true);
      let SedData = {
        url: `/api/Reports/RegisteredUserListReport?userId=${logindetail?.userId}&conferenceId=${conId}&type=${type}`,
        body: member,
      };
      const res = await PostCallApi(SedData);
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res?.status === 200 && res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            ...c,
            id: i + 1,
            email: c?.emailId,
            adaDisabilities: c?.adaDisabilities
              ? c?.adaDisabilities?.split(";")?.join(", ")
              : "",
            mealRequirement: c?.mealRequirement
              ? c?.mealRequirement?.split(";")?.join(", ")
              : "",
            paymentDate: c?.paymentDate
              ? new Date(c?.paymentDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : null,
          };
        });
        setDataOfTable1(comList);
      } else {
        ToastError(res.data.message);
      }
    } else {
      let ms =
        !type && !conId
          ? "Please Select Conference & User Type"
          : type
          ? conId
            ? ""
            : "Please Select Conference"
          : "Please Select User Type";
      SwalMessage(ms);
    }
  };

  const handleChangeValue = (e, conId) => {
    setUserType(e);
    setConfId(conId);
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        logindetail?.userId
      }`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setLeaList(data);
      }
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        logindetail?.userId
      }`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setAgencyList(data);
      }
    }
  };

  const getIndustryist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
        logindetail?.userId
      }`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setIndustryList(data);
      }
    }
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleClearFilter = () => {
    setSelectIndustry([]);
    setSelectAgency([]);
    setSelectLea([]);
    setUserType();
    setConfId();
    setDataOfTable1([]);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form>
                      <div className="row mb-3" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Conference</label>

                            <Select
                              isMulti={false}
                              name="colors"
                              options={conferenceList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Conference"
                              filterOption={customFilter}
                              value={conferenceList?.filter(
                                (obj) => obj.value === confId
                              )}
                              onChange={(e) =>
                                handleChangeValue(userType, e?.value)
                              }
                            />
                            {/* <select
                          className="form-select "
                          value={confId}
                          onChange={(e) =>
                            handleChangeValue(userType, e.target.value)
                          }
                          style={{ minWidth: "250px" }}
                        >
                          {conferenceList &&
                            conferenceList?.map((con, i) => (
                              <option value={con?.conferenceId} key={i}>
                                {con?.conferenceName}
                              </option>
                            ))}
                        </select> */}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Register Type</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={[
                                { label: "Exhibitor", value: "Exhibitor" },
                                { label: "Attendee", value: "User" },
                              ]}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select User Type"
                              filterOption={customFilter}
                              value={[
                                { label: "Exhibitor", value: "Exhibitor" },
                                { label: "Attendee", value: "User" },
                              ]?.filter((obj) => obj.value === userType)}
                              onChange={(e) =>
                                handleChangeValue(e?.value, confId)
                              }
                            />
                            {/* <select
                          className="form-select"
                          value={userType}
                          onChange={(e) =>
                            handleChangeValue(e.target.value, confId)
                          }
                          style={{ minWidth: "250px" }}
                        >
                          <option value="Exhibitor">Exhibitor</option>
                          <option value="User">User</option>
                        </select> */}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">LEA Member</label>
                            <Select
                              isMulti
                              name="colors"
                              options={leaList}
                              className="company-multi-select"
                              classNamePrefix="select"
                              placeholder="Select LEA Member"
                              filterOption={customFilter}
                              value={leaList?.filter((obj) =>
                                selectLea?.includes(obj.value)
                              )}
                              onChange={handleChangeLea}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">State Agencies</label>
                            <Select
                              isMulti
                              name="colors"
                              options={agencyList}
                              className="company-multi-select"
                              classNamePrefix="select"
                              placeholder="Select State Agencies"
                              filterOption={customFilter}
                              value={agencyList?.filter((obj) =>
                                selectAgency?.includes(obj.value)
                              )}
                              onChange={handleChangeAgency}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              Industry Member
                            </label>
                            <Select
                              isMulti
                              name="colors"
                              options={industryList}
                              className="company-multi-select"
                              classNamePrefix="select"
                              placeholder="Select Industry Member"
                              filterOption={customFilter}
                              value={industryList?.filter((obj) =>
                                selectIndustry?.includes(obj.value)
                              )}
                              onChange={handleChangeIndustry}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              onClick={() =>
                                GetRegistrationList(userType, confId)
                              }
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    headCells={headCellsCompanyReg}
                    title="Registered User List"
                    action={false}
                    ListData={dataOfTable1}
                    dropdown={false}
                    addBtn={false}
                    fileName={"Register User List"}
                    exportDataList={true}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceRegisterUser;
