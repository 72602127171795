import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { Link, useLocation, useParams } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ProductCard from "./Card";
import { Config } from "../../../Utils/Config";
import { Backdrop, Box, CircularProgress, Modal, Tooltip } from "@mui/material";
import ImgPopup from "../../Components/ImgPopUp";

import NewLIstTable from "../../Components/NewLIstTable";
// import AOS from "aos";
// import "aos/dist/aos.css";
import Select from "react-select";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import QuestionsforNonFigUsers from "./QuestionsforNonFigUsers";
import { MdVerified } from "react-icons/md";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import TableHeader from "../../../TableHeader.json";
import { ToastError, ToastSuccess } from "../../Components/Toast";
const NotFound = "/assets/images/Image_not_available.jpg";
const speckerProfileBanner = "/assets/images/speckerProfileBanner.png";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "auto",
  maxWidth: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function ExhibitorProfilePublic() {
  const { id, conf, name } = useParams();
  const [exDeatils, setExDetails] = useState([]);
  const [venderList, setVenderList] = useState([]);
  // const [usDetails, setUsDetails] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [openCall, setOpenCall] = React.useState(false);
  const [prdSelect, setPrdSelect] = useState([]);
  const [derror, setDerror] = useState();
  const [productList, setProductList] = useState([]);
  const [asList, setAsList] = useState([]);
  const [ascId, setAscId] = useState();
  const [reqCall, setReqCall] = useState(false);
  const [chatEx, setChatEx] = useState(false);
  // const cookies = new Cookies();
  const { state } = useLocation();
  const exhiId = id || state?.exhibitorId;
  const confId = conf || state?.conferenceId;
  const req = name || state?.req;
  const [conferenceId, setConferenceId] = useState();
  const [exhibitorId, setExhibitorId] = useState();
  useEffect(() => {
    if (confId) {
      setConferenceId(confId);
    }
    // eslint-disable-next-line
  }, [confId]);
  useEffect(() => {
    if (exhiId) {
      setExhibitorId(exhiId);
    }
    // eslint-disable-next-line
  }, [exhiId]);
  const [companyId, setCompanyId] = useState();
  const [companyExhibitorList, setCompanyExhibitorList] = useState();

  const qrRef = useRef();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (req) {
      if (req === "message") {
        handleReqCall(true);
      } else {
        handleReqCall(false);
      }
    }
  }, [req]);

  const handleReqCall = (e) => {
    setReqCall(true);
    setChatEx(e);
  };

  useEffect(() => {
    document.title = "FIG Events | Company Store";
    if (exhibitorId) {
      getCompanyDetail(exhibitorId);
    }
    // eslint-disable-next-line
  }, [exhibitorId]);

  const GetAssociationMemberList = async () => {
    if (LogDetail?.userId) {
      let seData = {
        url: `/api/AssociationMembers/GetAllAssociationMembersList?userId=${LogDetail?.userId}`,
        headers: headers,
      };

      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res.data.success) {
          let asm = res.data.payload?.map((x) => {
            return {
              ...x,
              value: x?.associationMembersId,
              label: x?.name,
            };
          });
          // asm = [asm[0]];
          setAsList(asm);
          if (asm?.length === 1) {
            setAscId(asm[0]?.associationMembersId);
          }
        }
      }
    }
  };

  const getCompanyDetail = async (id) => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: LogDetail?.userId
        ? `/api/CompanyRegistration/GetCompanyDetailsWithoutToken?companyId=${id}
      &userId=${LogDetail?.userId ? LogDetail?.userId : ""}`
        : `/api/CompanyRegistration/GetCompanyDetailsWithoutToken?companyId=${id}`,
    });
    if (res?.status === 200 && res?.data.success) {
      let com = res?.data?.payload;
      let comExhibitor = await GetCallApi({
        url: `/api/CompanyRegistration/GetCompanyUserAllocationListByCompanyIdWithoutToken?companyId=${exhibitorId}`,
      });
      setCompanyExhibitorList(comExhibitor?.data?.payload);
      setCompanyId({ ...com, value: id, label: com?.companyName });
      let selectprd = res.data.payload?.productList?.map((x) => {
        return {
          ...x,
          label: x.productName,
          value: x?.exhibitorProductId,
        };
      });
      setProductList(selectprd);
      let sv = res.data.payload?.productList?.map((x) => {
        return {
          ...x,
          cardImg: x.productImage
            ? x?.productImage
            : x?.multipleImages?.[0]?.imagePath
            ? x?.multipleImages?.[0]?.imagePath
            : NotFound,
          cardTitle: x.productName,
          des: x?.shortDescription,
        };
      });
      setExDetails(sv);
      let vn = res.data.payload?.vendorList?.map((x, i) => {
        return {
          id: i + 1,
          ...x,
          companyName: com?.companyName,
          image: x?.image ? (
            <ImgPopup
              img={`${Config.API_HOST_URL_live}${x.image}`}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : (
            ""
          ),
        };
      });
      setVenderList(vn);
      setTimeout(() => {
        setOpenCircle(false);
      }, 500);
    } else {
      ToastError(res.data.message);
      setOpenCircle(false);
    }
  };

  // const GetUsDetails = async (id) => {
  //   setOpenCircle(true);
  //   let seData = {
  //     url: LogDetail?.userId
  //       ? `/api/UserMst/GetExhibitorsAllInformation?userId=${id}&loginUserId=${LogDetail?.userId}`
  //       : `/api/UserMst/GetExhibitorsAllInformation?userId=${id}`,
  //     headers: headers,
  //   };
  //   let res = await GetCallApi(seData);
  //   setOpenCircle(false);
  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       setUsDetails(res.data.payload);
  //       let sv1 = res.data.payload?.productList?.map((x) => {
  //         return {
  //           ...x,
  //           cardImg: x.productImage
  //             ? x?.productImage
  //             : x?.multipleImages?.[0]?.imagePath
  //             ? x?.multipleImages?.[0]?.imagePath
  //             : NotFound,
  //           cardTitle: x.productName,
  //           des: x?.shortDescription,
  //         };
  //       });
  //       setExDetails(sv1);
  //       let sv = res.data.payload?.vendorList?.map((x, i) => {
  //         return {
  //           ...x,
  //           id: i + 1,
  //           image: x?.image ? (
  //             <ImgPopup
  //               img={`${Config.API_HOST_URL_live}${x.image}`}
  //               addClass={"h_fix"}
  //               hide_icon={true}
  //             />
  //           ) : (
  //             ""
  //           ),
  //         };
  //       });
  //       setVenderList(sv);
  //       let selectprd = res.data.payload?.productList?.map((x) => {
  //         return {
  //           ...x,
  //           label: x.productName,
  //           value: x?.exhibitorProductId,
  //         };
  //       });
  //       setProductList(selectprd);
  //     }
  //   }
  // };

  const [prdSearchField, setPrdSearchField] = useState("");
  const formattedSearchResults = exDeatils?.filter((user) => {
    if (prdSearchField) {
      return user.cardTitle
        ?.toLowerCase()
        .includes(prdSearchField?.toLowerCase());
    } else return user;
  });
  useEffect(() => {
    // AOS.init({
    //   duration: 700,
    //   easing: "ease-out-cubic",
    //   once: true,
    // });
    // window.addEventListener("load", function () {
    //   AOS.init();

    //   // Remove AOS attributes after animations are done
    //   setTimeout(function () {
    //     document.querySelectorAll("[data-aos]").forEach(function (el) {
    //       el.removeAttribute("data-aos");
    //     });
    //   }, 1000); // Adjust the timeout according to your animation duration
    // });
    ExView();
    GetAssociationMemberList();
    // eslint-disable-next-line
  }, []);

  const handleOpenCall = () => {
    setOpenCall(true);
  };
  const handleCloseCall = () => {
    setOpenCall(false);
    setDerror();
    setPrdSelect([]);
  };
  const handleChangePrdMulti = (e) => {
    setPrdSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const HandleSubmitProduct = async () => {
    if (ascId?.length === 0) {
      setDerror({ org: "This field is required" });
    } else {
      let is_success = true;
      setDerror();
      if (prdSelect?.length === 0) {
        is_success = false;
        setDerror({ err: "Please select one product." });
      }
      if (is_success) {
        setOpenCircle(true);
        let seData = {
          url: "/api/ExhibitorHall/AddUserSelectedProduct",
          body: {
            AttendeeId: LogDetail?.userId,
            ProductIds: prdSelect,
            ExhibitorsId: exhibitorId,
            associationMembersId: ascId,
            conferenceId: conferenceId,
          },
          headers: headers,
        };
        let res = await PostCallApi(seData);
        setOpenCircle(false);
        if (res?.status === 200) {
          if (res.data.success) {
            ToastSuccess(res.data.message);
            handleCloseCall();
          }
        }
      }
    }
  };
  const userInLike = async (p) => {
    if (LogDetail?.userId) {
      let seData = {
        url: "/api/ExhibitorProduct/CreateProductUserInteraction",
        body: {
          ExhibitorProductId: p,
          userId: LogDetail?.userId,
          conferenceId: conferenceId ? conferenceId : null,
          status: "Like",
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          ToastSuccess(res.data.message);
          getCompanyDetail(exhibitorId);
        }
      }
    } else {
      swalWithBootstrapButtons.fire({
        position: "center",
        // icon: "success",
        text: `Please Login after you can like product`,
        // text: des,
        confirmButtonText: "OK",
        confirmButtonColor: "#E64942",
      });
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn_success",
      cancelButton: "btn btn_success",
    },
    buttonsStyling: true,
  });

  const ExView = async () => {
    let UID = "";

    let unk = LogDetail?.userId
      ? LogDetail?.userId
      : localStorage.getItem("uId");
    if (unk) {
      UID = unk;
    } else {
      UID = uuid();
      localStorage.setItem("uId", UID);
    }
    let seData = {
      url: `/api/UserMst/CreateExhibitorProfileView`,
      body: {
        exhibitorId: exhibitorId,
        userId: UID,
        userType: LogDetail?.userId ? "User" : "Unknown",
      },
    };
    await PostCallApi(seData);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {companyId ? (
        <>
          {/* // usDetails?.isCompleteProfile ? ( */}
          {
            !reqCall ? (
              <>
                <section className="exhibitor_pro_public">
                  <img
                    src={
                      companyId?.coverImage
                        ? Config.API_HOST_URL_live + companyId?.coverImage
                        : speckerProfileBanner
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = speckerProfileBanner;
                    }}
                    className="profile_images_pic img-fluid w-100"
                    alt="Speaker Profile"
                  />

                  <div className="container-fluid">
                    <div className="main_wrapper">
                      <div className="row">
                        <div
                          className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12 index-9 aos-init aos-animate"
                          //
                        >
                          <div className="profile_images">
                            <img
                              src={
                                companyId?.profilePhoto
                                  ? Config.API_HOST_URL_live +
                                    companyId?.profilePhoto
                                  : NotFound
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = NotFound;
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12 col-12 aos-init aos-animate">
                          <div className="right-image">
                            <div className="content">
                              <h1>
                                {companyId?.companyName}
                                {companyId?.isSubscription ? (
                                  <>
                                    (Pro)
                                    <Tooltip
                                      title={"Subcription Purchased"}
                                      placement="bottom"
                                      arrow
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            backgroundColor: "#80c242",
                                            "& .MuiTooltip-arrow": {
                                              color: "#80c242",
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      <span>
                                        <MdVerified
                                          color="#80c242"
                                          fontSize={30}
                                        />
                                      </span>
                                    </Tooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                              </h1>{" "}
                              <h2>
                                {companyId?.contactName
                                  ? companyId?.contactName
                                  : ""}{" "}
                              </h2>{" "}
                              <h6>{companyId?.title}</h6>
                              <p>
                                {companyId?.address1 ? "Address: " : ""}
                                {companyId?.address1}{" "}
                                {companyId?.address1 ? ", " : ""}
                                {companyId?.address2 ? companyId?.address2 : ""}
                                {companyId?.address12 ? ", " : ""}
                                {companyId?.city}
                                {companyId?.city ? ", " : ""}
                                {companyId?.state}
                                {companyId?.zip ? " - " : ""}
                                {companyId?.zip ? companyId?.zip : ""}
                              </p>
                              {/* <h3>{usDetails?.userTitle}</h3>
                              <p style={{ whiteSpace: "pre-line" }}>
                                {usDetails?.userDescription
                                  ? usDetails?.userDescription?.slice(0, 500)
                                  : ""}
                                {usDetails?.userDescription
                                  ? usDetails?.userDescription?.length >
                                      500 && (
                                      <span
                                        className="description cr"
                                        style={{ cursor: "pointer" }}
                                      >
                                        ...
                                      </span>
                                    )
                                  : ""}
                              </p> */}
                              {/* {usDetails?.userBioList?.descriptio ? ( //description
                              ) : (
                                ""
                              )} */}
                              {/* {LogDetail?.authenticate &&
                              LogDetail?.userId !== exhibitorId ? (
                                <div className="mt-3">
                                  <Link
                                    className="dashboard_btn background "
                                    onClick={() => handleReqCall(false)}
                                  >
                                    Request A Call
                                  </Link>
                                  <Link
                                    className="dashboard_btn background ms-3"
                                    onClick={() => handleReqCall(true)}
                                  >
                                    Chat With Exhibitor
                                  </Link>
                                  
                                </div>
                              ) : LogDetail?.authenticate &&
                                LogDetail?.userId === exhibitorId ? (
                                ""
                              ) : ( */}
                              <div className="mt-3">
                                {/* {companyId?.isSubscription ? ( */}
                                <>
                                  <Link
                                    className="dashboard_btn background "
                                    onClick={() => handleReqCall(false)}
                                  >
                                    Request A Call
                                  </Link>
                                  {companyExhibitorList?.length > 0 ? (
                                    <Link
                                      className="dashboard_btn background ms-3"
                                      onClick={() => handleReqCall(true)}
                                    >
                                      Chat With Exhibitor
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </>
                                {/* ) : (
                                  <>
                                    <Button className="common_btn " disabled>
                                      Request A Call
                                    </Button>
                                    {companyExhibitorList?.length > 0 ? (
                                      <Button
                                        className="common_btn ms-3"
                                        disabled
                                      >
                                        Chat With Exhibitor
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )} */}
                                {/* created for hide warning */}
                                <Link
                                  className="dashboard_btn background d-none"
                                  onClick={handleOpenCall}
                                >
                                  Request A Call
                                </Link>
                                {/* created for hide warning */}
                              </div>
                              {/* )} */}
                            </div>
                            <div className="rightInfo">
                              <div className="user_detail_QR">
                                {/* {LogDetail?.isCompleteProfile ? ( */}
                                <div className="qr_box" ref={qrRef}>
                                  <QRCode
                                    size={100}
                                    value={
                                      Config.API_HOST_URL +
                                      "/exhibitor-profile/" +
                                      exhibitorId
                                    }
                                  />
                                </div>
                                {/* ) : (
                                  <div className="qr_box">
                                    <img
                                      src={"/assets/icons/qrcode-img.svg"}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="exhibitor_profile_view">
                  <div className="main_wrapper">
                    <div className="exhibitor_main_box mb-4">
                      <div className="hed mb-4">
                        <h4 className="title m-0">Product List</h4>
                        <div className="d-flex align-items-center">
                          <div className=" search_sec ms-auto">
                            <div className="search_input w-100">
                              <img
                                src={"/assets/icons/search_icon.svg"}
                                alt=""
                              />
                              <input
                                onChange={(e) => {
                                  setPrdSearchField(e.target.value);
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Type your search here"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <ProductCard
                          data={formattedSearchResults}
                          userInLike={userInLike}
                        />
                      </div>
                    </div>
                    <div className="new_card p-0 mb-4">
                      <NewLIstTable
                        title="Team Members List"
                        headCells={TableHeader?.ExhibitorProfilePublicHeader}
                        action={false}
                        ListData={venderList}
                      />
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <QuestionsforNonFigUsers
                GetUsDetails={() => getCompanyDetail(exhibitorId)}
                setReqCall={setReqCall}
                productList={productList}
                asList={asList}
                chatEx={chatEx}
                venderList={venderList}
                exhiId={exhibitorId}
                conferenceId={conferenceId}
              />
            )
            // ) : (
            //   <div className="f-20-600 text-center mt-3">
            //     Exhibitor profile is not completed.
            //   </div>
            // )
          }
        </>
      ) : openCircle ? (
        //  usDetails?.roleName !== undefined ? (
        //   <div className="f-20-600 text-center mt-3">
        //     {/* You are trying to reach is not an Exhibitor.
        //     <br />
        //     OR
        //     <br /> */}
        //     This Exhibitor has not completed his profile or has made his profile
        //     disable for public views.
        //     {/* You are trying to reach is not an Exhibitor. */}
        //   </div>
        // ) :
        <div className="f-20-600 text-center mt-3">
          We are fetching details...
        </div>
      ) : (
        <div className="f-20-600 text-center mt-3">
          {" "}
          This Company has not completed his profile or has made his profile
          disable for public views.
        </div>
      )}
      <Modal
        open={openCall}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_delete_data"
      >
        <Box sx={style1} className="delete_data_box">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openCircle}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="popup_delete_detail_box">
            <h6>Request A Call</h6>
            <div className="popup_delete_detail">
              <div className="form-group">
                <label className="form-label">
                  District or Organization Name
                  <span className="red-color"> *</span>
                </label>
                <select
                  className="form-select"
                  value={ascId}
                  onChange={(e) => setAscId(e.target.value)}
                  disabled={asList?.length === 1 ? true : false}
                >
                  <option value="">Select District or Organization Name</option>
                  {asList &&
                    asList?.map((con) => (
                      <option
                        value={con.associationMembersId}
                        key={con.associationMembersId}
                      >
                        {con.name} - {con?.type}
                      </option>
                    ))}
                </select>
                {derror?.org && (
                  <span role="alert" className="error_text">
                    {derror?.org}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Select Product</label>
                <Select
                  isMulti
                  name="colors"
                  options={productList}
                  className="company-multi-select"
                  classNamePrefix="select"
                  placeholder="Product"
                  filterOption={customFilter}
                  value={productList?.filter((obj) =>
                    prdSelect?.includes(obj.value)
                  )}
                  onChange={handleChangePrdMulti}
                />
                {derror?.err && (
                  <span role="alert" className="error_text">
                    {derror?.err}
                  </span>
                )}
              </div>
              <button
                type="button"
                className="common_btn me-3"
                onClick={HandleSubmitProduct}
                disabled={openCircle}
              >
                Submit
              </button>
              <button
                type="button"
                className="back_button "
                onClick={handleCloseCall}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ExhibitorProfilePublic;
