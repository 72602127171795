import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { GetCallApi } from "../../../Action/Action.jsx";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import TableHeader from "../../../TableHeader.json";
import { MdCloudUpload } from "react-icons/md";

const DeletePopup = lazy(() => import("../../Components/DeletePopup"));
const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function SpeakerList() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Speaker";
    // eslint-disable-next-line
  }, []);

  const GetSpeakerList = async (data) => {
    setDataOfTable1([]);
    setOpenCircle(true);
    let seData = {
      url: `/api/Speaker/GetAllSpeakerList?speakerName=${data?.speakerName}&speakerEmail=${data?.speakerEmail}`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    setFilterPage(!filterPage);

    if (res.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,

            email: c?.speakerEmail,
            phone: c?.speakerPhone,
            topics: c?.speechesAndTopics,
            speechesAndTopics: c?.speechesAndTopics
              ?.map((x) => x?.title)
              ?.join("; "),
            Action: (
              <>
                <Tooltip title="Classwork Document Upload">
                  <Link
                    type="button"
                    className="btn_edit me-3"
                    to={`/classwork-document/${c?.speakerId}`}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdCloudUpload fontSize={14} />
                  </Link>
                </Tooltip>
                <Link
                  type="button"
                  className="btn_edit me-3"
                  to={`/speaker-edit/${c?.speakerId}`}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Speaker"}
                  text={c?.speakerName}
                  url={`/api/Speaker/DeleteSpeaker?speakerId=${c?.speakerId}`}
                  callBack={true}
                  callBackFunction={() =>
                    GetSpeakerList({
                      speakerName: getValues("speakerName"),
                      speakerEmail: getValues("speakerEmail"),
                    })
                  }
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const handleClearFilter = () => {
    setDataOfTable1([]);
    reset();
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetSpeakerList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              {...register("speakerName", {
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.speakerName && (
                              <span role="alert" className="error_text">
                                {errors.speakerName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Email"
                              {...register("speakerEmail", {
                                // pattern: {
                                //   value: /\S+@\S+\.\S+/,
                                //   message: "Please Enter Valid Email",
                                // },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.speakerEmail && (
                              <span role="alert" className="error_text">
                                {errors.speakerEmail.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`/speaker-create`}
                    headCells={TableHeader?.SpeakersListHeader}
                    title="Speakers List"
                    action={true}
                    ListData={dataOfTable1}
                    dropdown={false}
                    addBtn={true}
                    gridView={true}
                    listView={true}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SpeakerList;
