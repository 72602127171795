import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import { Box, Button, Modal } from "@mui/material";
import { GoEye } from "react-icons/go";
import { MdFilterAlt } from "react-icons/md";
import { sortArray } from "../../../Components/SortArray";

const defaultStyles = {
  transition: "all 0.5s ease-in-out",
  // height: "100%",
  width: 0,
  position: "absolute",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
  overflowX: "hidden",
};

const expectedBehaviours = ["overlay", "hidden"];
const expectedPositions = ["left", "right"];

const invalidBehaviourProp = new Error(
  `Sidebar component : behaviour prop must be one of ${expectedBehaviours.toString()}`
);
const invalidPositionProp = new Error(
  `Sidebar component : position prop must be one of ${expectedPositions.toString()}`
);

const computeStylesFromState = (
  isSidebarOpen,
  content,
  behaviour,
  position
) => {
  const additionalStyles = {};
  additionalStyles[position] = "0px";
  additionalStyles.width = !isSidebarOpen ? "0px" : "350px";
  const contentNode = document.querySelector(content);
  if (contentNode && behaviour === "hidden") {
    // contentNode.style.marginLeft = !isSidebarOpen
    //   ? "0px"
    //   : `${position === "right" ? "-" : ""}15%`;
  }
  return { ...defaultStyles, ...additionalStyles };
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const Sidebar = ({ behaviour, content, position, setSboothId }) => {
  const [isOpen, setOpenState] = React.useState(false);

  const openSidebar = () => {
    setOpenState(true);
  };

  const toggleSidebar = () => {
    setOpenState(!isOpen);
  };

  const closeSidebar = () => {
    setOpenState(false);
  };

  Sidebar.open = openSidebar;
  Sidebar.close = closeSidebar;
  Sidebar.toggle = toggleSidebar;

  const computedStylesForState = React.useMemo(
    () => computeStylesFromState(isOpen, content, behaviour, position),
    [isOpen, content, behaviour, position]
  );

  React.useEffect(() => {
    if (!expectedBehaviours.includes(behaviour)) {
      throw new Error(invalidBehaviourProp);
    }
    // eslint-disable-next-line
  }, [behaviour]);

  React.useEffect(() => {
    if (!expectedPositions.includes(position)) {
      throw new Error(invalidPositionProp);
    }
    // eslint-disable-next-line
  }, [position]);
  const { id } = useParams();
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const [deltaPositionSave, setDeltaPositionSave] = useState([]);
  const [openPop, setOpenPop] = React.useState(false);
  const [filterVal, setFilterVal] = React.useState("BoothASC");
  const [filterValChange, setFilterValChange] = React.useState();
  const handleOpenPop = () => setOpenPop(true);
  const handleClosePop = () => {
    setOpenPop(false);
  };
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  React.useEffect(() => {
    getBoothList();
    // eslint-disable-next-line
  }, [deleteInfo]);
  const getBoothList = async () => {
    let seData = {
      url: `/api/ConferenceBooths/GetConferenceBoothsStatusByConferenceIdWithoutTokenNew?conferenceId=${id}`,

      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let pn = res.data.payload
          ?.filter((x) => x?.status === "Approve")
          ?.map((p) => {
            let company = p?.exhibitor?.exhibitorCompanyDetailsNew?.find(
              (x) => x.isFloorPlanDisplayCompany === true
            );
            let compAr = [
              company,
              ...(p?.exhibitor?.exhibitorCompanyDetailsNew?.filter(
                (x) => x.isFloorPlanDisplayCompany === false
              ) ?? []),
            ]?.map((x) => {
              return { ...x, value: x?.companyId, label: x?.companyName };
            });
            return {
              ...p,
              ...p.exhibitor,
              ...p.exhibitor?.exhibitorCompanyDetails,
              companyD: compAr,
              companyName: company?.companyName,
              sortKey: parseInt(p?.seatEventNumber),
            };
          });
        let newArry = sortArray(pn, "asc");
        setFilterValChange();
        setFilterVal("BoothASC");
        setDeltaPositionSave(newArry);
      }
    }
  };

  const [searchField, setSearchField] = useState();
  const searchParam = ["companyName", "exhibitorName", "seatEventNumber"];
  const filterUser = deltaPositionSave?.filter((user) => {
    if (searchField) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });

  const handleSave = async () => {
    setFilterVal(filterValChange);
    setFilterValChange();
    handleClosePop();
    let arSend =
      filterValChange === "ATOZ" || filterValChange === "ZTOA"
        ? deltaPositionSave.map((x) => {
            return { ...x, sortKey: x?.companyName.trim()?.toLowerCase() };
          })
        : deltaPositionSave.map((x) => {
            return { ...x, sortKey: parseInt(x?.seatEventNumber) };
          });
    let sortName =
      filterValChange === "ATOZ" || filterValChange === "BoothASC"
        ? "asc"
        : "desc";
    let newArry = await sortArray(arSend, sortName);
    setDeltaPositionSave(newArry);
  };

  return (
    <>
      <div
        className={`${isOpen ? "sidebar_open" : "sidebar_close"}`}
        style={computedStylesForState}
      >
        {" "}
        <div className="searc_box d-flex gap-2">
          <input
            type="search"
            className="form-control"
            placeholder="Type your search here"
            onChange={(e) => setSearchField(e.target.value)}
          />
          <Button
            className="back_button"
            sx={{ padding: "0.4rem 1rem !important" }}
            onClick={() => handleOpenPop()}
          >
            <MdFilterAlt fontSize={28} />{" "}
          </Button>
        </div>
        {/* <div className="exibitor">Exhibitors</div> */}
        <div className=" map_legend gap-3 p-3 d-flex">
          <div className="f-15-500">Booth No.</div>
          <div className="f-15-500">Name</div>
          <div className="ms-auto ">
            <div className="f-15-500">View</div>
          </div>
        </div>
        <div className="vender_list_box ">
          {filterUser?.map((ex, i) => (
            <div key={i} className="vender_detail_Box">
              <div className="booth_no">{ex?.seatEventNumber}</div>
              <div className="name f-15-500">
                {/* {ex?.exhibitorName ? ex?.exhibitorName : ""} */}
                {ex?.companyD?.length > 0
                  ? ex?.companyD.map((item, index) => (
                      <div
                        className="d-flex align-items-center text_grey"
                        key={index + 1}
                      >
                        <img
                          src="/assets/icons/company_logo.svg"
                          style={{ width: "16px" }}
                          alt=""
                        />
                        <div className=" ms-1">
                          {item?.companyName}{" "}
                          {item?.isFloorPlanDisplayCompany === true
                            ? " - Main"
                            : ""}
                        </div>
                        {/* <div className="f-14-400 ms-1">{ex?.companyName}</div> */}
                      </div>
                    ))
                  : ex?.exhibitorName}
              </div>
              <div className="ms-auto">
                <Button
                  className="gren_eye_btn"
                  onClick={() => setSboothId(ex?.eventBoothsId)}
                >
                  <GoEye />
                </Button>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="map_legend">Map Legend</div> */}
      </div>
      {openPop && (
        <>
          <Modal
            open={openPop}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                {/* <h6>Filter</h6> */}
                <div className="popup_delete_detail">
                  <div className="form-group">
                    <label className="form-label">Filter By</label>

                    <select
                      className="form-select"
                      value={filterValChange ? filterValChange : filterVal}
                      onChange={(e) => setFilterValChange(e.target.value)}
                    >
                      <option value="ATOZ">A To Z</option>
                      <option value="BoothASC">Booth Wise Ascending </option>
                      <option value="BoothDSC">Booth Wise Descending</option>
                      <option value="ZTOA">Z To A</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handleSave}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClosePop}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Sidebar;
