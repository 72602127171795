import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import "./index.scss";
import { CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

function SubscriptionPriceCreate() {
  const { id } = useParams();
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [durationValue, setDurationValue] = useState();
  const [conferenceList, setConferenceList] = useState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (id) {
      getPriceData(id);
    }
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    GetConferenceList();
    // eslint-disable-next-line
  }, []);

  const GetConferenceList = async () => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${logDetail?.userId}`,
    });

    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((x) => {
        return {
          ...x,
          value: x?.conferenceId,
          label: x?.conferenceName,
        };
      });
      setConferenceList(comList);
    }
  };

  const getPriceData = async () => {
    let seData = {
      url: `/api/Subscription/GetSubscriptionBySubscriptionId?subscriptionId=${id}`,
      headers: headers,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        setValue("subscriptionId", parseInt(id));
        setValue("subscriptionTitle", useDe?.subscriptionTitle);
        setValue("benefits", useDe?.benefits);

        setValue("duration", useDe?.duration);
        setDurationValue(useDe?.duration);
        setValue("totalSubscriptionPrice", useDe?.totalSubscriptionPrice);
        setValue("isDiscountedSubscription", useDe?.isDiscountedSubscription);
        setValue("discountedType", useDe?.discountedType);
        setValue("discountedValue", useDe?.discountedValue);
        setValue("price", useDe?.price);
        setValue("value", useDe?.value);
        setValue("priceType", useDe?.priceType);
        setValue("ConferenceId", useDe?.conferenceList);
        setValue(
          "DisplayAsProductInConference",
          useDe?.displayAsProductInConference
        );
      }
    }
  };
  const onSubmit = async (data) => {
    let Menu = {
      ...data,
      CreatedBy: logDetail?.userId,
      totalSubscriptionPrice: parseFloat(data?.totalSubscriptionPrice),
      discountedValue: data?.isDiscountedSubscription
        ? parseFloat(data?.discountedValue) ?? 0
        : 0,
      price: data?.isDiscountedSubscription ? parseFloat(data?.price) ?? 0 : 0,
      // priceId: parseInt(data?.priceId),
      value: parseInt(data?.value),
      ConferenceId: data?.ConferenceId ? data?.ConferenceId : null,
    };
    let seData = {
      url: "/api/Subscription/CreateSubscription",
      body: Menu,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200 && res.data.success) {
      ToastSuccess(res.data.message);
      reset();
      nav(-1);
      setDerror({ err: "" });
    } else {
      setDerror({ err: res.data?.message });
      ToastError(res.data.message);
    }
  };

  const DuratrionOption = [
    { label: "Conference", value: "Conference" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Weekly", value: "Weekly" },
    { label: "Yearly", value: "Yearly" },
  ];
  const handleDuration = (e) => {
    setDurationValue(e);
    setValue("duration", e);
  };

  const handleConference = (e) => {
    setValue("ConferenceId", Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handlePriceManage = () => {
    let getTPr = getValues("totalSubscriptionPrice");
    let getDV = getValues("discountedValue");
    let getDT = getValues("discountedType");

    let pr =
      getDT === "Percentage"
        ? parseFloat(getTPr) - parseFloat(getTPr * (getDV / 100))
        : parseFloat(getTPr) < parseFloat(getDV)
        ? 0
        : parseFloat(getTPr) - parseFloat(getDV);
    if (watch("isDiscountedSubscription") === true) {
      setValue("price", pr);
      if (getDT === "Amount") {
        if (parseFloat(getTPr) < parseFloat(getDV)) {
          ToastError(
            "You can not add more discount of total subscription price "
          );
        }
      }
    } else {
      setValue("price", 0);
    }
  };

  return (
    <section className="create_price">
      <div className="main_wrapper">
        <div className="title mb-3">
          {id ? "Edit" : "Create"} Subscription Price{" "}
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="title mb-3">
                    {id ? "Edit" : "Create"} Menu{" "}
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Title
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Subscription Title"
                      {...register("subscriptionTitle", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 250,
                          message: "Max character 250 allowed",
                        },
                      })}
                    />
                    {errors.subscriptionTitle && (
                      <span role="alert" className="error_text">
                        {errors.subscriptionTitle.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Duration
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={DuratrionOption}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Duration"
                      filterOption={customFilter}
                      value={DuratrionOption?.filter(
                        (obj) => obj.value === durationValue
                      )}
                      onChange={(e) => handleDuration(e?.value)}
                    />
                    <select
                      type="text"
                      className="form-select d-none"
                      {...register("duration", {
                        required: "This field is required",
                      })}
                    >
                      <option value="Conference">Conference</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                    {errors.duration && (
                      <span role="alert" className="error_text">
                        {errors.duration.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      $ Total Price
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter total Price"
                      {...register("totalSubscriptionPrice", {
                        required: "This field is required",
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message:
                            "Enter valid value the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                        },
                      })}
                    />
                    {errors.totalSubscriptionPrice && (
                      <span role="alert" className="error_text">
                        {errors.totalSubscriptionPrice.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Price Type
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Price Type"
                      {...register(`priceType`, {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        maxLength: {
                          value: 20,
                          message: "Max character 20 allowed",
                        },
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                      })}
                    ></input>
                    {errors.priceType && (
                      <span role="alert" className="error_text">
                        {errors.priceType.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Exhibitor Can See Number Of Attendee In Dashboard
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      {...register(`value`, {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid value",
                        },
                      })}
                    ></input>
                    {errors.value && (
                      <span role="alert" className="error_text">
                        {errors.value.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                {watch("duration") === "Conference" ? (
                  <>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Conference
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter((obj) =>
                            watch("ConferenceId")
                              ? watch("ConferenceId")?.includes(obj.value)
                              : ""
                          )}
                          onChange={handleConference}
                        />
                        <input
                          type="text"
                          className="form-control d-none"
                          placeholder="Enter ConferenceId"
                          {...register(`ConferenceId`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        ></input>
                        {errors.ConferenceId && (
                          <span role="alert" className="error_text">
                            {errors.ConferenceId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="DisplayAsProductInConference"
                            {...register(`DisplayAsProductInConference`)}
                          />
                          <span className="checkmark"></span>
                          <span className="ms-4">
                            Display As Product In Conference
                          </span>
                        </label>
                        {errors.DisplayAsProductInConference && (
                          <span role="alert" className="error_text">
                            {errors.DisplayAsProductInConference.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-md-6 my-auto">
                  <div className="form-group">
                    <label className=" personal_detail_label">
                      <input
                        type="checkbox"
                        className="login_check"
                        name="Remember Me"
                        disabled={
                          parseFloat(watch("totalSubscriptionPrice")) < 1.01
                        }
                        {...register("isDiscountedSubscription")}
                      />
                      <span className="checkmark"></span>
                      <span className="ms-4">Is Discounted Subscription</span>
                    </label>
                    {errors.isDiscountedSubscription && (
                      <span role="alert" className="error_text">
                        {errors.isDiscountedSubscription.message}
                      </span>
                    )}
                  </div>
                </div>
                {watch("isDiscountedSubscription") === true ? (
                  <>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Discount Type
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          type="text"
                          className="form-select"
                          {...register(`discountedType`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            onChange: () => handlePriceManage(),
                          })}
                        >
                          <option value="Percentage">Percentage</option>
                          <option value="Amount">Amount</option>
                        </select>
                        {errors.discountedType && (
                          <span role="alert" className="error_text">
                            {errors.discountedType.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Discount Value
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Discount Value"
                          {...register(`discountedValue`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            onChange: () => handlePriceManage(),
                            validate: (value) => {
                              if (watch("discountedType") === "Percentage") {
                                if (value > 100) {
                                  return "Discount Percentage cannot greater than 100";
                                }
                              } else {
                                if (
                                  parseFloat(value) >
                                  parseFloat(watch("totalSubscriptionPrice"))
                                ) {
                                  return "You can not add more discount of total product price";
                                }
                              }
                            },
                          })}
                        ></input>
                        {errors.discountedValue && (
                          <span role="alert" className="error_text">
                            {errors.discountedValue.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Price
                          {/* <span className="red-color"> *</span> */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Price"
                          disabled
                          {...register("price", {
                            // required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                          })}
                        />
                        {errors.price && (
                          <span role="alert" className="error_text">
                            {errors.price.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Benefits</label>
                    <textarea
                      rows="5"
                      type="text"
                      className="form-control"
                      placeholder="Enter Benefits"
                      {...register("benefits", {
                        maxLength: {
                          value: 500,
                          message: "Max 500 characters",
                        },
                      })}
                    />
                    {errors.benefits && (
                      <span role="alert" className="error_text">
                        {errors.benefits.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                  <div className="form-group">
                    <Button className="back_button" onClick={() => nav(-1)}>
                      Back
                    </Button>
                    <Button className="common_btn ms-3" type="submit">
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : id ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </Button>

                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                  </div>
                </div>
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionPriceCreate;
