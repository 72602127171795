import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/fig_events.png";
import "./index.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import ReactQuill from "react-quill";
import { Link, useLocation, useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
  borderColor: "#ccc",
}));

const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: "#f6f6f6",
  // },
  // "&:nth-of-type(even)": {
  //   backgroundColor: "#F1F6FC",
  // },
  borderColor: "#ccc",
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
function InvoiceView() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [viewData, setViewData] = useState([]);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const { state } = useLocation();

  let nav = useNavigate();
  const tableRef = useRef(null);

  let Time = new Date();

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  useEffect(() => {
    if (id) {
      getInvoiceView(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const handleDownloadPdf = async () => {
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);
        nav(-1, { replace: true });
        // handleReset();
      });
  };

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };

  const getInvoiceView = async (id) => {
    if (id) {
      setOpen(true);
      let sData = {
        url: LogDetails?.userId
          ? `/api/ExhibitorAttendeeInvoice/ViewExhibitorAttendeeInvoiceDetails?exhibitorAttendeeInvoiceId=${id}&isResend=false`
          : `/api/ExhibitorAttendeeInvoice/ViewExhibitorAttendeeInvoiceDetailsWithoutToken?exhibitorAttendeeInvoiceId=${id}`,
      };
      const res = await GetCallApi(sData);
      setOpen(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const comList = res.data?.payload;
          let inDate = comList?.invoiceDate
            ? new Date(comList?.invoiceDate)
            : "";
          if (inDate) {
            inDate = inDate.toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          }
          let newtime = comList?.invoiceDate
            ? new Date(comList?.invoiceDate)
            : "";
          if (newtime) {
            newtime?.setDate(newtime?.getDate() + 30);
            newtime = newtime.toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          }
          setViewData({ ...comList, dueDate: newtime, invoiceDate: inDate });
        }
      }
    }
  };

  return (
    <>
      <div className="invoice_view_page_individual">
        <div className="card">
          <div className="card-body">
            {LogDetails?.userId ? (
              <>
                {/* <Button
                  className="back_button mb-3 me-3"
                  onClick={() => nav(-1, { replace: true })}
                >
                  Back
                </Button> */}
                <Button
                  className="common_btn mb-3"
                  onClick={() => setDownloadpdf(true)}
                >
                  Download
                </Button>
                {state?.showBtn && viewData ? (
                  <Button className="common_btn mb-3 ms-3">
                    <Link
                      to={`/my-qr-codes`}
                      style={{ textDecoration: "none", color: "white" }}
                      // className="common_btn ms-3"
                      state={{
                        userName: state?.user ? state?.user : null,
                        groupUsers:
                          state?.groupUsers?.length > 0
                            ? state?.groupUsers
                            : null,
                        conferenceId: viewData?.conferenceId,
                      }}
                    >
                      Print FIG ID Badge
                    </Link>
                  </Button>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <section className="preview-invoice" ref={tableRef} style={back}>
              <div className="invoice-section">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="logo">
                      <img
                        src={logo}
                        style={{
                          height: "60px",
                        }}
                        alt="Confrence Planner"
                        title="Confrence Planner"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <h2>Invoice</h2>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-6  mb-4">
                    <h5 className="text-uppercase">
                      {viewData?.conferenceName}
                    </h5>
                    <div className="Billto-wrap">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {viewData?.paymentAddress}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 text-end">
                    <div className="invoice-details">
                      <ul
                        className={`${viewData?.isPayByOnline ? "online" : ""}`}
                      >
                        <li>
                          <span className="primarylight-color">
                            Invoice Number
                          </span>
                          <span className="invoice-text">
                            {viewData?.invoiceNumber}
                          </span>
                        </li>
                        {viewData?.isPayByOnline ? (
                          <li>
                            <span className="primarylight-color">
                              Payment Reference
                            </span>
                            <span className="invoice-text">
                              {viewData?.paymentReference}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        <li>
                          <span className="primarylight-color">PO Number</span>
                          <span className="invoice-text">
                            {viewData?.purchaseOrderNumber}
                          </span>
                        </li>

                        <li>
                          <span className="primarylight-color">
                            Invoice Date
                          </span>
                          <span className="invoice-text">
                            {viewData?.invoiceDate}
                          </span>
                        </li>
                        {viewData?.isPayByOnline ? (
                          <>
                            <li>
                              <span className="primarylight-color">
                                Payment Date
                              </span>
                              <span className="invoice-text">
                                {new Date(
                                  viewData?.payOnlineDate
                                )?.toLocaleString("en-us", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  minute: "2-digit",
                                  hour: "2-digit",
                                  hour12: true,
                                })}
                              </span>
                            </li>
                            <li>
                              <span className="primarylight-color">Status</span>
                              <span className="invoice-text">Paid</span>
                            </li>
                          </>
                        ) : (
                          <li>
                            <span className="primarylight-color">Due Date</span>
                            <span className="invoice-text">
                              {viewData?.dueDate}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6  mb-4">
                    <h5 className="text-uppercase">Billed To</h5>
                    <div className="Billto-wrap">
                      {viewData?.userType !== "User" ? (
                        <>
                          <h5 className="text-uppercase">
                            {viewData?.companyName}
                          </h5>
                          <p>{viewData?.companyPhone}</p>
                        </>
                      ) : (
                        ""
                      )}
                      <p>{viewData?.name}</p>
                      <p>{viewData?.addressLine1}</p>
                      <p>
                        {viewData?.city +
                          " " +
                          viewData?.state +
                          " " +
                          viewData?.zipCode}
                      </p>
                      <p>{viewData?.email}</p>
                    </div>
                  </div>
                  {!viewData?.isPayByOnline &&
                  viewData?.invoiceStatus !== "Complete" &&
                  viewData ? (
                    <div className="col-md-6 text-end ">
                      <Link
                        className="common_btn"
                        to={`/stripe/conference-individual/${id}`}
                      >
                        Pay Now
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="table-wrap mt-5">
                  <Table className="table-bordered Conference-table">
                    <TableHead className="thead-primary">
                      <StyledTableRow>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          {viewData?.userType === "User"
                            ? "Item"
                            : "Booth Number/Item"}
                        </StyledTableCell>
                        {viewData?.userType === "Exhibitor" ? (
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #ccc",
                            }}
                          >
                            Company
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          Line Total
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {viewData?.userType === "User" ? (
                        <>
                          <StyledTableRow>
                            <StyledTableCell>
                              {viewData?.conferenceName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(viewData?.confees)?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                          {viewData?.evtList?.length > 0
                            ? viewData?.evtList?.map((ev, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell>
                                    {ev?.eventName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {parseFloat(
                                      ev?.attendeeFees
                                    )?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            : ""}
                        </>
                      ) : (
                        <>
                          {viewData?.boothList?.length > 0
                            ? viewData?.boothList?.map((ev, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell>
                                    {ev?.seatEventNumber}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {ev?.companyList
                                      ?.split(";")
                                      ?.map((x, iv) => {
                                        return (
                                          <span key={iv}>
                                            {i + 1 + ".  " + x}
                                            <br />
                                          </span>
                                        );
                                      })}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {parseFloat(ev?.price)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            : ""}
                          {viewData?.teamMembersEvtList?.map((x, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell colSpan={2}>
                                {x?.eventQuntitiy} x {x?.eventName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {parseFloat(x?.totalPrice)?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}{" "}
                          {viewData?.exhibitorsProductList?.length > 0 ? (
                            <StyledTableRow>
                              <StyledTableCell colSpan={3}>
                                Association Product Details(Booth & Company
                                Wise){" "}
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                          {viewData?.exhibitorsProductList?.map((x, i) => (
                            <React.Fragment key={i}>
                              <StyledTableRow
                                key={i}
                                style={{ backgroundColor: "#f6f6f6" }}
                              >
                                <StyledTableCell colSpan={2}>
                                  {x?.productQuntitiy} x {x?.productName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {parseFloat(
                                    x?.productTotalPrice
                                  )?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </StyledTableCell>
                              </StyledTableRow>
                              {x?.productBoothCompanyDetails?.map((y, val) => (
                                <React.Fragment key={val}>
                                  <StyledTableRow key={val}>
                                    <StyledTableCell
                                      style={{ verticalAlign: "top" }}
                                      rowSpan={2}
                                    >
                                      {y?.boothNumber}
                                    </StyledTableCell>{" "}
                                    <StyledTableCell>
                                      {y?.quntity} x {y?.companyName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {y?.price?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })}
                                    </StyledTableCell>
                                  </StyledTableRow>{" "}
                                  <StyledTableRow key={val}>
                                    <StyledTableCell>Discount</StyledTableCell>
                                    <StyledTableCell>
                                      -
                                      {y?.discount?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell
                                      style={{
                                        backgroundColor: "white",
                                        textAlign: "right",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      colSpan={2}
                                    >
                                      Sub Total
                                    </StyledTableCell>
                                    <StyledTableCell
                                      style={{
                                        backgroundColor: "white",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                    >
                                      {y?.totalPrice?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </React.Fragment>
                              ))}
                              {x?.productPromoCodeData?.map((dis, val) => (
                                <StyledTableRow key={val}>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "right",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                    colSpan={2}
                                  >
                                    Discount Code [{dis?.productPromoCode}]
                                  </StyledTableCell>

                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                  >
                                    -
                                    {dis?.discountAmount?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                              {x?.totalDiscount ? (
                                <StyledTableRow>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "right",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                    colSpan={2}
                                  >
                                    Product Total Discount
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                  >
                                    -
                                    {x?.totalDiscount?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                ""
                              )}
                              {x?.productTotalPrice ? (
                                <StyledTableRow>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "right",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                    colSpan={2}
                                  >
                                    Product Total Paid Amount
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                  >
                                    {(
                                      parseFloat(x?.productTotalPrice) -
                                      parseFloat(
                                        x?.totalDiscount ? x?.totalDiscount : 0
                                      )
                                    )?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ))}{" "}
                          {viewData?.exhiProductSubscriptionLists?.map(
                            (x, i) => (
                              <StyledTableRow key={i}>
                                <StyledTableCell>
                                  {x?.subscriptionName}
                                </StyledTableCell>{" "}
                                <StyledTableCell>
                                  {x?.companyName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {parseFloat(
                                    x?.subscriptionPrice
                                      ? x?.subscriptionPrice
                                      : 0
                                  )?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          )}
                        </>
                      )}
                      {viewData?.applyPromoCodeDetails?.length > 0
                        ? viewData?.applyPromoCodeDetails?.map((x, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  textAlign: "right",
                                  borderBottom: "1px solid #ccc",
                                }}
                                colSpan={
                                  viewData?.userType === "Exhibitor" ? 2 : 1
                                }
                              >
                                Discount Code [{x?.promoCode}]
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "1px solid #ccc",
                                }}
                              >
                                -
                                {x?.discountAmount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : ""}
                      {viewData?.discountValue ? (
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              textAlign: "right",
                              borderBottom: "1px solid #ccc",
                            }}
                            colSpan={viewData?.userType === "Exhibitor" ? 2 : 1}
                          >
                            Total Discount
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            -
                            {viewData?.discountValue?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        ""
                      )}
                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            textAlign: "right",
                            borderBottom: "1px solid #ccc",
                          }}
                          colSpan={viewData?.userType === "Exhibitor" ? 2 : 1}
                        >
                          <b>Grand Total</b>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <b>
                            {viewData?.totalAmount?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </b>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="table-wrap mt-3">
                  <h5>User Details</h5>
                  <Table className="table-bordered Conference-table">
                    <TableHead className="thead-primary">
                      <StyledTableRow>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          User Name
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          Email
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          Phone
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          Price
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>{viewData?.userName}</StyledTableCell>
                        <StyledTableCell>{viewData?.userEmail}</StyledTableCell>
                        <StyledTableCell>{viewData?.phone}</StyledTableCell>
                        <StyledTableCell>
                          {viewData?.totalAmount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </div>

                {/* <div className="row mt-5">
                  <div className="col-lg-12 text-lg-end mt-3">
                    <h5 className="mt-lg-7">Becki Swan</h5>
                    <p>Co-founder, Fig Events</p>
                  </div>
                </div> */}
                <div className="row term-text mt-3">
                  <div className="col-md-12">
                    <h5>Invoice Instructions</h5>
                    <ReactQuill
                      value={viewData?.invoiceInstructions}
                      readOnly={true}
                      theme={"bubble"}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceView;
