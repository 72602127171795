import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.scss";
import axios from "axios";
import { Config } from "../../Utils/Config";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

const userimg12 = "/assets/images/user.png";
const vecteezy = "/assets/images/vecteezy.png";
const UsersList = lazy(() => import("./UsersList"));
const UserMessages = lazy(() => import("./UserMessages"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function Messages() {
  const { id } = useParams();
  const [searchField, setSearchField] = useState("");
  const [usList, setUsList] = useState([]);
  const [userChatDeatil, setUserChatDeatil] = useState();
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [seaUsList, setSeaUsList] = useState([]);
  const [startNewchat, setStartNewChat] = useState(false);
  useEffect(() => {
    document.title = "FIG Events | Message";
    getSearchList(searchField);
    if (searchField === "") {
      getChatUsList();
    }
    // eslint-disable-next-line
  }, [searchField]);
  useEffect(() => {
    const interval = setInterval(() => getChatUsList(), 30000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
  const getSearchList = (name) => {
    if (name !== "") {
      if (name.length > 2) {
        axios
          .get(
            `${Config.API_HOST_URL_live}/api/Chat/SearchUserByName?userId=${LogUserId}&searchByName=${name}`,
            { headers }
          )
          .then((response) => {
            if (response.data.success) {
              let srlist = response.data.payload?.map((item) => {
                return {
                  userId: item?.userId,
                  name: item?.userName,
                  chatId:
                    item?.activeChatInfo === null ||
                    item?.activeChatInfo?.chatId === "" ||
                    item?.activeChatInfo?.chatId === null
                      ? ""
                      : item?.activeChatInfo?.chatId,
                  img:
                    item?.activeChatInfo === null ||
                    item?.activeChatInfo?.profileUrl === "" ||
                    item?.activeChatInfo?.profileUrl === null
                      ? userimg12
                      : `${Config.API_HOST_URL_live}${item?.activeChatInfo?.profileUrl}`,
                  userProfile:
                    item?.userProfile === undefined ||
                    item?.userProfile === null ||
                    item?.userProfile === ""
                      ? userimg12
                      : `${Config.API_HOST_URL_live}${item?.userProfile}`,
                };
              });

              setSeaUsList(srlist);
            }
          });
      }
    } else {
      setSeaUsList([]);
    }
  };
  const getChatUsList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Chat/GetChatListforUser?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.success) {
          let listUs = response.data.payload?.map((item, i) => {
            return {
              id: i + 1,
              img:
                item?.userInfo?.profileUrl === "" ||
                item?.userInfo?.profileUrl === null
                  ? userimg12
                  : `${Config.API_HOST_URL_live}${item?.userInfo?.profileUrl}`,
              userId:
                item?.userInfo?.userId === "" || item?.userInfo?.userId === null
                  ? ""
                  : item?.userInfo?.userId,
              chatId:
                item?.chatId === "" || item?.chatId === null
                  ? ""
                  : item?.chatId,
              name:
                item?.userInfo?.fistName === "" ||
                item?.userInfo?.fistName === null
                  ? ""
                  : item?.userInfo?.firstName + " " + item?.userInfo?.lastName,
              time:
                item?.startedOnDate === "" || item?.startedOnDate === null
                  ? "--"
                  : new Date(item?.startedOnDate)?.toLocaleDateString("en-us", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "2-digit",
                    }),
              mestxt:
                item?.lastMessage === "" || item?.lastMessage === null
                  ? ""
                  : item?.lastMessage,
              siteLocation:
                item?.userInfo?.siteLocation === null ||
                item?.userInfo?.siteLocation === ""
                  ? "-"
                  : item?.userInfo?.siteLocation,
              jobTitle:
                item?.userInfo?.jobTitle === null ||
                item?.userInfo?.jobTitle === ""
                  ? "-"
                  : item?.userInfo?.jobTitle,
              schoolDistrictName:
                item?.userInfo?.schoolDistrictName === null ||
                item?.userInfo?.schoolDistrictName === ""
                  ? "-"
                  : item?.userInfo?.schoolDistrictName,
              phone:
                item?.userInfo?.phone === null || item?.userInfo?.phone === ""
                  ? "-"
                  : item?.userInfo?.phone,
              coverPhoto:
                item?.userInfo?.coverPhoto === null ||
                item?.userInfo?.coverPhoto === ""
                  ? vecteezy
                  : `${Config.API_HOST_URL_live}${item?.userInfo?.coverPhoto}`,
              // mesnum: "10",
              // active: true,
            };
          });
          if (startNewchat) {
            setUserChatDeatil(listUs[0]);
          } else {
            if (userChatDeatil?.chatId) {
              let useSearch = listUs?.filter(
                (x) => x.chatId === userChatDeatil?.chatId
              );
              if (useSearch[0]) {
                setUserChatDeatil(useSearch[0]);
              }
            }
          }
          setUsList(listUs);
        } else {
          setUsList([]);
          setUserChatDeatil();
        }
      });
  };
  // function convertDate(str) {
  //   var date = new Date(str?.replace(/-/g, "/")),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join("/");
  // }
  useEffect(() => {
    const interval = setInterval(() => {
      searchField === "" ? setSeaUsList([]) : <></>;
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [searchField]);

  useEffect(() => {
    if (id) {
      if (usList?.length > 0) {
        let chatD = usList?.filter((item) => item.chatId === id);
        setUserChatDeatil(chatD[0]);
      }
    }
  }, [id, usList]);
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const handleDeleteChat = (id) => {
    axios
      .delete(
        `${Config.API_HOST_URL_live}/api/Chat/DeleteChatById?chatId=${id?.chatId}&deletedBy=${LogUserId}`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            if (id?.chatId === userChatDeatil?.chatId) {
              setUserChatDeatil();
            }

            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(res.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            getChatUsList();
          }
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="ceu_cource_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="chat_page">
                <div className="row m-0 ">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-12 p-0">
                    <UsersList
                      usList={usList}
                      filteredData={seaUsList}
                      searchField={searchField}
                      setSearchField={setSearchField}
                      setUserChatDeatil={setUserChatDeatil}
                      userChatDeatil={userChatDeatil}
                      setStartNewChat={setStartNewChat}
                      handleDeleteChat={handleDeleteChat}
                    />
                  </div>
                  <div className="col-xl-9 col-lg-8 col-md-7 col-12 p-0">
                    <UserMessages userChatDeatil={userChatDeatil} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(Messages);
