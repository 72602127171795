import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VscCheck, VscChromeClose, VscEdit } from "react-icons/vsc";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import { ToastError, ToastSuccess } from "../Components/Toast";
import ImgPopUp from "../Components/ImgPopUp";
import { Config } from "../../Utils/Config";

const NotFound = "/assets/images/Image_not_available.jpg";
const NewLIstTable = lazy(() => import("../Components/NewLIstTable"));
const DeletePopup = lazy(() => import("../Components/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsCompanyReg = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
  },
  {
    id: "glnNumber",
    label: "GLN Number",
  },
  {
    id: "companyType",
    label: "Company Type",
  },
  {
    id: "isAppoved",
    label: "Status",
    color: true,
  },
];

function Company() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [comType, setComType] = useState();
  const [filterPage, setFilterPage] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Company";
    // eslint-disable-next-line
  }, []);

  const { register, handleSubmit, getValues, reset, setValue } = useForm();

  const logindetail = useSelector((state) => state.login.LoginDetails);

  const GetCompanyRegistrationList = async (data) => {
    setOpenCircle(true);
    setDataOfTable1([]);
    let res = await GetCallApi({
      url: `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${
        logindetail?.userId
      }&contactName=${data?.contactName ? data?.contactName : ""}&companyType=${
        data?.companyType ? data?.companyType : ""
      }`,
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          ...c,
          id: i + 1,
          color:
            c.isAppoved === "Approved"
              ? "approv"
              : c.isAppoved === "Rejected"
              ? "reject"
              : "created",
          groupUserList:
            c?.userList?.length > 0
              ? c?.userList?.map((x, index) => {
                  return {
                    ...x,
                    id: index + 1,
                    logo: x?.userProfile ? (
                      <ImgPopUp
                        img={Config.API_HOST_URL_live + x?.userProfile}
                        addClass={"h_fix"}
                        hide_icon={true}
                      />
                    ) : (
                      <ImgPopUp
                        img={NotFound}
                        addClass={"h_fix"}
                        hide_icon={true}
                      />
                    ),
                    Action: (
                      <div className="edit_delete justify-content-start">
                        <DeletePopup
                          title={`Delete Association Member `}
                          text={x?.userName}
                          url={`/api/CompanyRegistration/DeleteUserFromCompanyUserAllocationByUserId?userId=${x?.userId}`}
                          callBack={true}
                          callBackFunction={() =>
                            GetCompanyRegistrationList({
                              companyType: getValues("companyType"),
                              contactName: getValues("contactName"),
                            })
                          }
                        />
                      </div>
                    ),
                  };
                })
              : "",
          Action:
            c?.isCompanyEditAccess || logindetail?.roleId === 1 ? (
              <>
                <Link
                  className="btn_edit me-3 d-flex align-items-center"
                  to={`edit/${c?.companyId}`}
                >
                  <VscEdit />
                </Link>
                {logindetail?.roleId === 1 ? (
                  <>
                    {c?.isAppoved === "Approved" ||
                    c.isAppoved === "Rejected" ? (
                      ""
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn_edit me-3"
                          onClick={() => reqApprove(c?.companyId, "Approved")}
                        >
                          <VscCheck />
                        </button>
                        <button
                          type="button"
                          className="btn_dlt me-3"
                          onClick={() => reqApprove(c?.companyId, "Rejected")}
                        >
                          <VscChromeClose className="icon" />
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
                <DeletePopup
                  title={"Delete Company"}
                  text={c?.companyName}
                  url={`/api/CompanyRegistration/DeleteCompanyRegistration?companyId=${c?.companyId}`}
                  callBack={true}
                  callBackFunction={() =>
                    GetCompanyRegistrationList({
                      companyType: getValues("companyType"),
                      contactName: getValues("contactName"),
                    })
                  }
                />
              </>
            ) : (
              ""
            ),
        };
      });
      setDataOfTable1(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };
  const reqApprove = async (comId, ac) => {
    let comD = {
      companyId: comId,
      isApproved: ac,
      createdBy: logindetail?.userId,
    };
    let res = await PostCallApi({
      url: "/api/CompanyRegistration/SendCompanyApproval",
      body: comD,
    });

    if (res.status === 200 && res.data.success) {
      let msg = res.data.message;
      GetCompanyRegistrationList({
        companyType: getValues("companyType"),
        contactName: getValues("contactName"),
      });
      ToastSuccess(msg);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const CompanyTypeOption = [
    { value: "Primary", label: "Primary" },
    { value: "Secondary", label: "Secondary" },
  ];
  const handleComType = (e) => {
    setValue("companyType", e);
    setComType(e);
  };

  const handleClearFilter = () => {
    setComType();
    setDataOfTable1([]);
    reset();
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetCompanyRegistrationList)}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label"> Company Type</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={CompanyTypeOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Company Type"
                              filterOption={customFilter}
                              value={CompanyTypeOption?.filter(
                                (obj) => obj.value === comType
                              )}
                              onChange={(e) => handleComType(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Contact Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Contact Name"
                              {...register("contactName")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    headCells={headCellsCompanyReg}
                    title="Company List"
                    action={true}
                    ListData={dataOfTable1}
                    dropdown={false}
                    addBtn={true}
                    filterPage={filterPage}
                    showUserListInTable={true}
                    innerProduct={true}
                    subHeadCells={[
                      {
                        id: "id",
                        label: "id",
                      },
                      {
                        id: "logo",
                        label: "Profile",
                        exportTab: false,
                      },
                      {
                        id: "userName",
                        label: "User Name",
                      },
                      {
                        id: "userEmail",
                        label: "User Email",
                      },
                      {
                        id: "userPhone",
                        label: "User Phone",
                      },
                      {
                        id: "Action",
                        label: "Action",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Company;
