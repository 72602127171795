import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import logo from "../../assets/images/fig_events.png";
import { PostCallApi } from "../../Action/Action.jsx";
import { ToastSuccess } from "../../Modules/Components/Toast/index.jsx";

function ForgotPassword() {
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let nav = useNavigate();
  useEffect(() => {
    document.title = "FIG Events | Forgot Password";
  }, []);
  const onSubmit = async (data) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Login/ForgotPasword`,
      body: data,
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        reset();
        setOpenCircle(false);
        ToastSuccess(res.data.message);
        setTimeout(function () {
          nav("/", { replace: true });
        }, 2000);
        setDerror({ Log: "" });
      } else {
        setOpenCircle(false);

        setDerror({ Log: res.data?.message });
      }
    } else {
      setOpenCircle(false);
      console.log(res);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section className="ForgotPassword_page">
        <div className="row m-0">
          <div className="col-md-6 p-0 text-center border-right image_sec left_main">
            <div className="inner_box left">
              <div className="content">
                <h1>Forgot Password</h1>
                <p>
                  Welcome to FIG Conference Planner! We are thrilled that you
                  are joining our platform! As a member you will play a crucial
                  role in shaping the future of K12 Nutrition
                </p>
              </div>
              <img
                src={"/assets/icons/forgot-banner.svg"}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 p-0 right_main">
            <div className="inner_box right">
              <img src={logo} className="img-fluid logo" alt="logo" />
              <form
                className="d-flex flex-column"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group mb-4">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    className="w-100"
                    // placeholder="Enter your Email"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please Enter Valid Email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span role="alert" className="error_text">
                      {errors.email.message}
                    </span>
                  )}
                  {derror?.Log && (
                    <span role="alert" className="d-block error_text">
                      {derror.Log}
                    </span>
                  )}
                </div>

                <input
                  type="submit"
                  name="submit"
                  className="submit_btn"
                  value="Send Me Mail"
                />
                <h6>
                  Already have an account? <Link to="/login">LOGIN</Link>
                </h6>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
