import React, { Suspense, lazy, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import "./index.scss";
import { CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

const AssignTable = lazy(() => import("../../AssignUsers/AssignTable"));

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "questionTitle",
    label: "Question Title",
  },
  {
    id: "questionType",
    label: "Question Type",
  },
  {
    id: "isRequired",
    label: "IsRequired",
  },
];

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ConferenceFeedbackQuestionDiscount() {
  const { id } = useParams();
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [conferenceList, setConferenceList] = useState([]);
  const [confId, setConfId] = useState();
  const [sendUserType, setSendUserType] = useState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (id) {
      getConFBQuesionData(id);
    } else {
      GetAllQuestionList();
      GetAllConList();
    }
    // eslint-disable-next-line
  }, [id]);

  const GetAllConList = async (conId) => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${logDetail?.userId}`,
    });
    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((x) => {
          return {
            ...x,
            value: x?.conferenceId,
            label: x?.conferenceName,
          };
        });
        setConferenceList(comList);
        if (conId) {
          setTimeout(() => {
            setValue("conferenceId", conId);
            setConfId(conId);
          }, 250);
        }
      }
    }
  };

  const getConFBQuesionData = async () => {
    let seData = {
      url: `/api/ConferenceFeedbackQuestion/GetConferenceFeedbackQuestionBySubmittedId?submittedId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        setValue("submittedId", id);
        setValue("sendUserType", useDe[0]?.sendUserType);
        setSendUserType(useDe[0]?.sendUserType);
        GetAllQuestionList(useDe?.map((x) => x?.questionId));
        GetAllConList(useDe[0]?.conferenceId);
      }
    }
  };

  const GetAllQuestionList = async (qId) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Questions/GetAllConferenceQuestionList?userId=${logDetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload;

        let newlist = comList?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            isRequired: c?.isRequired ? "True" : "False",
            Action: (
              <span className="control_btn d-flex align-items-center">
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectUser(e?.target?.checked, c?.questionId)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            ),
          };
        });

        if (qId?.length > 0) {
          let matchedObjects = comList
            .filter(({ questionId }) => qId.includes(questionId))
            ?.map((c, i) => {
              return {
                id: i + 1,
                ...c,
                isRequired: c?.isRequired ? "True" : "False",
                Action: (
                  <span className="control_btn d-flex align-items-center">
                    <label className="personal_detail_label">
                      <input
                        type="checkbox"
                        className="login_check"
                        name="Remember Me"
                        checked={true}
                        onChange={(e) =>
                          handleSelectUser(e?.target?.checked, c?.questionId)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </span>
                ),
              };
            });
          setUserArray(matchedObjects?.map(({ questionId }) => questionId));
          let newAr = newlist.filter(
            ({ questionId }) => !qId.includes(questionId)
          );

          setDataOfTable(
            [...matchedObjects, ...newAr]?.map((c, i) => {
              return {
                ...c,
                id: i + 1,
              };
            })
          );
        } else {
          setDataOfTable(newlist);
        }
      }
    }
  };

  useEffect(() => {
    if (userArray?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [userArray]);

  const changetbData = () => {
    let newtb = dataOfTable?.map((pd) => {
      return {
        ...pd,
        add:
          userArray?.filter((x) => x === pd?.questionId)?.length > 0
            ? true
            : false,
        Action: (
          <span className="control_btn d-flex align-items-center">
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  userArray?.filter((x) => x === pd?.questionId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectUser(e?.target?.checked, pd?.questionId)
                }
              />
              <span className="checkmark"></span>
            </label>
          </span>
        ),
      };
    });
    setDataOfTable(newtb);
  };

  const handleSelectUser = (val, uId) => {
    if (val) {
      setUserArray((old) => [...old, uId]);
      setCheckValue(false);
    } else {
      setUserArray((old) => old?.filter((d) => d !== uId));
    }
  };
  const [checkedValue, setCheckValue] = useState(false);

  const handleMultiCheck = (v, i) => {
    if (v) {
      setUserArray(i?.map((id) => id?.questionId));
      setCheckValue(true);
    } else {
      setUserArray([]);
      setCheckValue(false);
    }
  };

  const onSubmit = async (data) => {
    if (userArray?.length === 0) {
      setDerror({ err: "Select minimum one question" });
    } else {
      setDerror({ err: "" });
      let conFQ = {
        ...data,
        CreatedBy: logDetail?.userId,
        questionId: userArray,
      };
      let seData = {
        url: "/api/ConferenceFeedbackQuestion/CreateConferenceFeedbackQuestionAllocation",
        body: conFQ,
        headers: headers,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);

      if (res.status === 200 && res.data.success) {
        ToastSuccess(res.data.message);
        reset();
        nav(-1);
        setDerror({ err: "" });
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
    setValue("conferenceId", e);
  };

  const handleSendUserType = (e) => {
    setSendUserType(e);
    setValue("sendUserType", e);
  };

  const sendUserTypeOption = [
    { value: "All", label: "All" },
    { value: "Event Organizer", label: "Event Organizer" },
    { value: "Exhibitor", label: "Exhibitor" },
    { value: "User", label: "User" },
  ];

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="create_Conference_feedback_question">
          <div className="main_wrapper">
            <div className="title mb-3">
              {id ? "Edit" : "Create"} Conference Feedback Question
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Conference
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter(
                            (obj) => obj.value === confId
                          )}
                          onChange={(e) => handleConfereceSelect(e?.value)}
                        />

                        <select
                          type="text"
                          className="form-select d-none"
                          {...register(`conferenceId`, {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Conference</option>
                          {conferenceList &&
                            conferenceList?.map((con, i) => (
                              <option value={con?.conferenceId} key={i}>
                                {con?.conferenceName}
                              </option>
                            ))}
                        </select>
                        {errors.conferenceId && (
                          <span role="alert" className="error_text">
                            {errors.conferenceId.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Send User Type
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={sendUserTypeOption}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Send User Type"
                          filterOption={customFilter}
                          value={sendUserTypeOption?.filter(
                            (obj) => obj.value === sendUserType
                          )}
                          onChange={(e) => handleSendUserType(e?.value)}
                        />
                        <select
                          type="text"
                          className="form-select d-none"
                          {...register(`sendUserType`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select Send User Type</option>
                          <option value="All">All</option>
                          <option value="Event Organizer">
                            Event Organizer
                          </option>
                          <option value="Exhibitor">Exhibitor</option>
                          <option value="User">User</option>
                        </select>
                        {errors.sendUserType && (
                          <span role="alert" className="error_text">
                            {errors.sendUserType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end mt-auto ">
                      <div className="form-group">
                        <Button className="back_button" onClick={() => nav(-1)}>
                          Back
                        </Button>
                        <Button className="common_btn ms-3" type="submit">
                          {openCircle ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : id ? (
                            "Update"
                          ) : (
                            "Save"
                          )}
                        </Button>

                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mb-4">
                      <AssignTable
                        headCells={headCellsEvent}
                        title="Question List"
                        action={false}
                        ListData={dataOfTable}
                        dropdown={false}
                        addBtn={false}
                        actionFirst={true}
                        userArray={userArray}
                        setUserArray={setUserArray}
                        handleMultiCheck={handleMultiCheck}
                        checkedValue={checkedValue}
                        setCheckValue={setCheckValue}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceFeedbackQuestionDiscount;
