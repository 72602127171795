import React, { useEffect, useState } from "react";
import "./index.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { Box, Modal } from "@mui/material";
import { VscTrash } from "react-icons/vsc";
import { useSelector } from "react-redux";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../../../Action/Action";
import { useParams } from "react-router";
import { HiOutlineEye } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { Config } from "../../../../../../Utils/Config";
import DeletePopup from "../../../../../Components/DeletePopup";
import { BiUpload } from "react-icons/bi";
import Select from "react-select";
import { customFilter } from "../../../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../../../Components/Toast";
import { validateImage } from "../../../../../Components/Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function AssociationProductCreate() {
  const [openCircle, setOpenCircle] = useState(false);
  // const [productId, setProductId] = useState("");
  const [category, setCategory] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    control,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "benefits",
  });
  const {
    fields: fieldArray2,
    append: AppendArray2,
    remove: RemoveArray2,
  } = useFieldArray({
    control,
    name: "ProductVideoUrl",
  });

  const [displayDoc, setDisplayDoc] = useState([]);
  const [multiImages, setMultiImages] = useState([]);
  const [docsFile, setDocsFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [matD, setMatD] = useState();
  const [derror, setDerror] = useState();
  // const [upc, setUpc] = useState();
  // const [ingred, setIngred] = useState();
  const [ecDe, setEcDe] = useState();
  // const [category, setCategory] = useState("Food Products");
  const { id, asid, stId } = useParams();
  let nav = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = async (data) => {
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
      associationStoreId: stId,
      associationId: asid,
      DiscountedValue: data?.DiscountedValue ? data?.DiscountedValue : 0,
      IsDiscountedProduct: data?.IsDiscountedProduct ? true : false,
    };
    delete sendData.SessionDoc1;
    if (!data?.CompanyId) {
      delete sendData?.CompanyId;
    }
    let Sessiondata = new FormData();

    // if (category === "Food Products") {
    //   if (upc) {
    //     Sessiondata.append("upc", upc);
    //   }
    //   if (ingred) {
    //     Sessiondata.append("ingredients", ingred);
    //   }
    // }
    if (displayDoc?.length > 0) {
      displayDoc?.map((d) => {
        return Sessiondata.append("Documents", d.file);
      });
    } else {
      Sessiondata.append("Documents", []);
    }

    if (multiImages?.length > 0) {
      multiImages
        ?.filter((x) => x?.val === "new")
        ?.map((d) => {
          return Sessiondata.append("MultipleImage", d.file);
        });
    } else {
      Sessiondata.append("MultipleImage", []);
    }
    for (var key in sendData) {
      Sessiondata.append(key, sendData[key]);
    }
    let seData = {
      url: `/api/AssociationProduct/CreateAssociationProduct`,
      body: Sessiondata,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    setOpenCircle(true);
    setDerror({ err: "" });

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let sData = {
          url: `/api/AssociationProduct/CreateAssociationProductBenefitsAndVideoLink`,
          body: {
            ...data,
            createdBy: LogDetail?.userId,
            associationProductId: res?.data?.payload?.associationProductId,
          },
          headers: headers,
        };
        await PostCallApi(sData);
        nav(-1);
        ToastSuccess(res.data.message);
      } else {
        setDerror({ err: res.data.message });
        ToastError(res.data.message);
      }
    } else {
      ToastError(res.data.message);
    }
  };

  useEffect(() => {
    document.title = "FIG Events | Association Product";
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      GetPrDdetails(id);
    } else {
      let bn = [
        {
          benefitTitle: "",
          benefitDescription: "",
        },
      ];
      setValue("benefits", bn);
    }
    // eslint-disable-next-line
  }, [id, deleteInfo]);
  useEffect(() => {
    if (ecDe) {
      setEcDe();
    }
    // eslint-disable-next-line
  }, [ecDe]);
  const GetPrDdetails = async (id) => {
    let seData = {
      url: `/api/AssociationProduct/GetAssociationProductByAssociationProductId?associationProductId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        let sendData = res?.data?.payload;
        setEcDe(sendData);
        setValue("associationProductId", id);
        setValue("ProductName", sendData?.productName);
        setValue("Price", sendData?.price);
        setValue("ShortDescription", sendData?.shortDescription);
        setValue("CategoryName", sendData?.categoryName);
        setCategory(sendData?.categoryName);
        setValue("SubCategoryName", sendData?.subCategoryName);
        setValue("IsExhibitorProduct", sendData?.isExhibitorProduct);
        setValue("IsDiscountedProduct", sendData?.isDiscountedProduct);
        setValue("TotalProductPrice", sendData?.totalProductPrice);
        setValue("DiscountedType", sendData?.discountedType);
        setValue("DiscountedValue", sendData?.discountedValue);
        // handleChangeCate(sendData?.categoryName);

        setValue("Manufacturer", sendData?.manufacturer);
        setValue("BrandName", sendData?.brandName);
        setValue("GTIN", sendData?.gtinNo);
        // setUpc(sendData?.upcNo);
        // setIngred(sendData?.ingredients);
        // setValue("UPC", sendData?.upcNo);
        // setValue("Ingredients", sendData?.ingredients);

        if (sendData?.productVideoLink?.length > 0) {
          setValue(
            "ProductVideoUrl",
            sendData?.productVideoLink?.map((x) => {
              return {
                fileName: x?.fileName,
                videoLink: x?.videoUrl,
              };
            })
          );
        } else {
          AppendArray2({
            fileName: "",
            videoLink: "",
          });
        }

        if (sendData?.benefits?.length > 0) {
          setValue("benefits", sendData?.benefits);
        } else {
          let bn = [
            {
              benefitTitle: "",
              benefitDescription: "",
            },
          ];
          setValue("benefits", bn);
        }
        setDocsFile(
          sendData?.documents
            ? sendData?.documents
                ?.filter((x) => x !== null)
                ?.map((d) => {
                  return {
                    ...d,
                    documentPath: d?.documentPath,
                  };
                })
            : []
        );
        setMultiImages(
          sendData?.multipleImages
            ? sendData?.multipleImages
                ?.filter((x) => x !== null)
                ?.map((x) => {
                  return {
                    ...x,
                    imagePath: x?.imagePath,
                  };
                })
            : []
        );
      }
    }
  };
  const handleOpen = (d) => {
    setMatD(d);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMatD();
  };

  const deleteDocument = async () => {
    if (matD?.documentId) {
      let seData = {
        url: `/api/AssociationProduct/DeleteAssociationProductDocumentsById?associatioProductDocumentsId=${matD?.documentId}`,
        headers: headers,
      };
      let res = await DeleteCallApi(seData);
      handleClose();
      if (res?.status === 200) {
        if (res.data.success) {
          let narOld = docsFile?.filter(
            (x) => x?.documentId !== matD?.documentId
          );
          setDocsFile(narOld);
        }
      }
    }
  };

  const handleMultiImg = (e) => {
    if (e.target.files?.length > 0) {
      if (e.target.files?.length > 5) {
        setValue(`MultipleImage`, "");
        setValue(`multiImg`, "");
      } else if (e.target.files?.length > 0) {
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];

          if (validateImage(multi.name)) {
            // let fileS = file.size;
            // if (fileS < 25 * 1000 * 1024) {
            //   validFiles.push(file);
            // } else {
            //   ToastError(`Invalid file Size: ${file.name} `);
            // }
            multifile.push(URL.createObjectURL(multi));
            base64_namemulti.push(multi);
          } else {
            ToastError(`Invalid file type: ${multi.name}`);
          }
        }
        setValue(`MultipleImage`, base64_namemulti);
        // setValue(`multiImg`, multifile);
        multifile = multifile?.map((x, i) => {
          return {
            id: i + 1,
            val: "new",
            file: base64_namemulti[i],
            imagePath: x,
          };
        });
        setMultiImages((old) => [...old, ...multifile]);
        e.target.files = null;
      }
    }
  };

  const handleDoCMultiFile = async (e) => {
    if (e.target.files.length) {
      //   setImgName(e.target.files[0]);
      if (e.target.files.length > 5) {
        setValue(`Documents`, "");
        setValue(`SessionDoc1`, "");
      } else if (e.target.files?.length > 0) {
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          multifile.push(multi?.name);
          base64_namemulti.push(multi);
        }
        multifile = multifile?.map((x, i) => {
          return {
            id: i + 1,
            val: "new",
            file: base64_namemulti[i],
            fileName: x,
          };
        });
        setValue(`Documents`, base64_namemulti);
        // setValue(`SessionDoc1`, multifile);
        setDisplayDoc((old) => [...old, ...multifile]);
      }
    }
  };
  const handleDocsDelete = (mat, no) => {
    setDisplayDoc(displayDoc?.filter((x) => x?.id !== no));
  };

  const handleMultiDelete = (value, id) => {
    setMultiImages(multiImages?.filter((d) => d?.id !== id));
  };

  const handleDelete = async (data, index) => {
    // if (data?.benefitId) {
    //   let seData = {
    //     url: `/api/ExhibitorProduct/DeleteProductBenefitsById?benefitId=${data?.benefitId}`,
    //     headers: headers,
    //   };
    //   let res = await DeleteCallApi(seData);
    //   if (res?.status === 200) {
    //     if (res?.data.success) {
    //       getBnData(id);
    //     }
    //   }
    // } else {
    remove(index);
    // }
  };

  const handleChangeCate = (val) => {
    setValue("CategoryName", val);
    setCategory(val);
  };

  const ProductCategoryOption = [
    { value: "Consulting", label: "Consulting" },
    { value: "Equipment", label: "Equipment" },
    { value: "Other", label: "Other" },
    { value: "Packaging", label: "Packaging" },
    { value: "Small Wares", label: "Small Wares" },
    { value: "Software/Technology", label: "Software/Technology" },
    { value: "Uniforms", label: "Uniforms" },
  ];

  const handlePriceManage = () => {
    let getTPr = getValues("TotalProductPrice");
    let getDV = getValues("DiscountedValue");
    let getDT = getValues("DiscountedType");

    let pr =
      getDT === "Percentage"
        ? parseFloat(getTPr) - parseFloat(getTPr * (getDV / 100))
        : parseFloat(getTPr) < parseFloat(getDV)
        ? 0
        : parseFloat(getTPr) - parseFloat(getDV);
    if (watch("IsDiscountedProduct") === true) {
      setValue("Price", pr);
      if (parseFloat(getTPr) < parseFloat(getDV)) {
        ToastError("You can not add more discount of total product price ");
      }
    } else {
      setValue("Price", 0);
    }
  };
  return (
    <>
      <section className="as_prouct_Create">
        <div className="main_wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="form_box">
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title mb-3">
                          {id ? "Edit" : "Create"} Association Product
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Product Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Product Name"
                            {...register("ProductName", {
                              required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.ProductName && (
                            <span role="alert" className="error_text">
                              {errors.ProductName.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Total Product Price
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Total Product Price"
                            {...register("TotalProductPrice", {
                              required: "This field is required",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message:
                                  "Enter valid value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                              },
                              onChange: () => handlePriceManage(),
                            })}
                          />
                          {errors.TotalProductPrice && (
                            <span role="alert" className="error_text">
                              {errors.TotalProductPrice.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Select Product Category
                          </label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={ProductCategoryOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Category"
                            filterOption={customFilter}
                            value={ProductCategoryOption?.filter(
                              (obj) => obj.value === category
                            )}
                            onChange={(e) => handleChangeCate(e?.value)}
                          />
                          <select
                            type="text"
                            className="form-select d-none"
                            {...register("CategoryName", {
                              // onChange: (e) =>
                              //   handleChangeCate(e?.target?.value),
                            })}
                          >
                            {/* <option value="Food Products">Food Products</option> */}
                            <option value="Consulting">Consulting</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Other">Other</option>
                            <option value="Packaging">Packaging</option>
                            <option value="Small Wares">Small Wares</option>
                            <option value="Software/Technology">
                              {" "}
                              Software/Technology{" "}
                            </option>
                            <option value="Uniforms">Uniforms</option>
                          </select>
                          {errors.CategoryName && (
                            <span role="alert" className="error_text">
                              {errors.CategoryName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Sub Category</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Sub Category"
                            {...register("SubCategoryName", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.SubCategoryName && (
                            <span role="alert" className="error_text">
                              {errors.SubCategoryName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Manufacturer</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Manufacturer"
                            {...register("Manufacturer", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.Manufacturer && (
                            <span role="alert" className="error_text">
                              {errors.Manufacturer.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">BrandName</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter BrandName"
                            {...register("BrandName", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.BrandName && (
                            <span role="alert" className="error_text">
                              {errors.BrandName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">GTIN</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter GTIN"
                            {...register("GTIN", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.GTIN && (
                            <span role="alert" className="error_text">
                              {errors.GTIN.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-6 my-auto">
                        <div className="form-group">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              className="login_check"
                              name="Remember Me"
                              {...register("IsExhibitorProduct")}
                            />
                            <span className="checkmark"></span>
                            <span className="ms-4">Exhibitor Add On</span>
                          </label>
                          {errors.IsExhibitorProduct && (
                            <span role="alert" className="error_text">
                              {errors.IsExhibitorProduct.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 my-auto">
                        <div className="form-group">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              className="login_check"
                              name="Remember Me"
                              {...register("IsDiscountedProduct")}
                            />
                            <span className="checkmark"></span>
                            <span className="ms-4">Is Discounted Product</span>
                          </label>
                          {errors.IsDiscountedProduct && (
                            <span role="alert" className="error_text">
                              {errors.IsDiscountedProduct.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {watch("IsDiscountedProduct") === true ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Discount Type
                                <span className="red-color"> *</span>
                              </label>
                              <select
                                type="text"
                                className="form-select"
                                {...register(`DiscountedType`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                  onChange: () => handlePriceManage(),
                                })}
                              >
                                <option value="Percentage">Percentage</option>
                                <option value="Amount">Amount</option>
                              </select>
                              {errors.DiscountedType && (
                                <span role="alert" className="error_text">
                                  {errors.DiscountedType.message}
                                </span>
                              )}
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Discount Value
                                <span className="red-color"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Discount Value"
                                {...register(`DiscountedValue`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                  onChange: () => handlePriceManage(),
                                  validate: (value) => {
                                    if (
                                      watch("DiscountedType") === "Percentage"
                                    ) {
                                      if (value > 100) {
                                        return "Discount Percentage cannot greater than 100";
                                      }
                                    } else {
                                      if (
                                        parseFloat(value) >
                                        parseFloat(watch("TotalProductPrice"))
                                      ) {
                                        return "You can not add more discount of total product price";
                                      }
                                    }
                                  },
                                })}
                              ></input>
                              {errors.DiscountedValue && (
                                <span role="alert" className="error_text">
                                  {errors.DiscountedValue.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Price
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Price"
                                disabled
                                {...register("Price", {
                                  // required: "This field is required",
                                  pattern: {
                                    value: /^\d+(\.\d{1,2})?$/,
                                    message:
                                      "Enter valid value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                                  },
                                })}
                              />
                              {errors.Price && (
                                <span role="alert" className="error_text">
                                  {errors.Price.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* {category === "Food Products" ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">UPC</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter UPC"
                                value={upc}
                                onChange={(e) => setUpc(e?.target?.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">Ingredients</label>
                              <textarea
                                rows="3"
                                type="text"
                                className="form-control"
                                placeholder="Enter Ingredients"
                                value={ingred}
                                onChange={(e) => setIngred(e?.target?.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> Description</label>
                          <textarea
                            rows="5"
                            type="text"
                            className="form-control"
                            placeholder="Enter  Description"
                            {...register("ShortDescription", {
                              // required: "This field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 500,
                                message: "You can entr max 500 characters",
                              },
                            })}
                          />
                          {errors.ShortDescription && (
                            <span role="alert" className="error_text">
                              {errors.ShortDescription.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <h5>Benefit</h5>
                      </div>
                      {fields?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="col-md-9 col-xl-8">
                              <div className="form-group">
                                <label className="form-label">
                                  Title
                                  <span className="red-color"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Title"
                                  {...register(
                                    `benefits.${index}.benefitTitle`,
                                    {
                                      required: "This field is required",
                                    }
                                  )}
                                />
                                {errors.benefits?.[index]?.benefitTitle && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.benefits?.[index]?.benefitTitle
                                        .message
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 col-xl-4 my-auto">
                              {index === 0 ? (
                                <Button
                                  className="common_btn"
                                  onClick={() =>
                                    append({
                                      benefitTitle: "",
                                      benefitDescription: "",
                                    })
                                  }
                                >
                                  Add
                                </Button>
                              ) : (
                                <Button
                                  className="delet_button"
                                  onClick={() => handleDelete(item, index)}
                                >
                                  Delete
                                </Button>
                              )}
                            </div>
                            <div className="col-md-12 ">
                              <div className="form-group">
                                <label className="form-label">
                                  {" "}
                                  Description
                                  <span className="red-color"> *</span>
                                </label>
                                <textarea
                                  rows="3"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter  Description"
                                  {...register(
                                    `benefits.${index}.benefitDescription`,
                                    {
                                      required: "This field is required",
                                      maxLength: {
                                        value: 500,
                                        message:
                                          "You can entr max 500 characters",
                                      },
                                    }
                                  )}
                                />
                                {errors.benefits?.[index]
                                  ?.benefitDescription && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.benefits?.[index]
                                        ?.benefitDescription.message
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                      <div className="col-md-12 text-end">
                        <div className="form-group">
                          <Button
                            className="common_btn"
                            type="submit"
                            disabled={openCircle}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : id ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                          <Button
                            onClick={() => nav(-1)}
                            className="back_button ms-2"
                          >
                            {" "}
                            Back
                          </Button>
                          {derror?.err && (
                            <span role="alert" className="d-block error_text">
                              {derror.err}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Product Multiple Image
                        </label>
                        <IconButton
                          component="label"
                          className="d-flex gap-3 upload btn_sec m-0"
                        >
                          <div className="upload_icon">
                            <BiUpload />
                          </div>
                          <span style={{ fontSize: "20px" }}>
                            Upload Images
                          </span>
                          <input
                            id="upload_img"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            multiple
                            max={5}
                            onChange={(e) => handleMultiImg(e)}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div className="mat_box col-md-12">
                      {multiImages?.length > 0 ? (
                        <label className="form-label">Image : </label>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {multiImages
                          ?.filter((m) => m.val !== "new")
                          ?.map((im, no) => (
                            <div className="col-md-3" key={no}>
                              <div className="mat_box_main mb-3">
                                <div className="img_box">
                                  <img
                                    src={im?.imagePath ? im?.imagePath : ""}
                                    alt=""
                                    className="img-fluid"
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                </div>
                                <DeletePopup
                                  title={"Delete Image"}
                                  text={im?.fileName}
                                  url={`/api/AssociationProduct/DeleteAssociationProductImagesById?associationProductImagesId=${im?.imagesId}`}
                                ></DeletePopup>
                              </div>
                            </div>
                          ))}
                        {multiImages
                          ?.filter((m) => m.val === "new")
                          ?.map((im, no) => (
                            <div className="col-md-3" key={no}>
                              <div className="mat_box_main mb-3">
                                <div className="img_box">
                                  <img
                                    src={im?.imagePath ? im?.imagePath : ""}
                                    alt=""
                                    className="img-fluid"
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                </div>
                                <button
                                  className="btn_dlt"
                                  type="button"
                                  onClick={() => handleMultiDelete(im, im.id)}
                                >
                                  <VscTrash className="icon" />
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Documents (Add Multiple Documents)
                        </label>
                        <IconButton
                          component="label"
                          className="d-flex gap-3 upload btn_sec m-0"
                        >
                          <div className="upload_icon">
                            <BiUpload />
                          </div>
                          <span style={{ fontSize: "20px" }}>
                            Upload Documents
                          </span>
                          <input
                            id="upload"
                            type="file"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            multiple
                            max={5}
                            className="form-control"
                            hidden
                            onChange={(e) => handleDoCMultiFile(e)}
                          />
                        </IconButton>
                        {errors.SessionDoc1 && (
                          <span role="alert" className="error_text">
                            {errors.SessionDoc1.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mat_box col-md-12">
                      {docsFile?.length > 0 ? "Document :" : ""}
                      <div className="row">
                        {docsFile?.map((mat, no) => (
                          <div className="col-md-6" key={no}>
                            <div className="mat_box_main mb-3">
                              {/* <div className="num me-3">{no + 1}</div> */}
                              <div className="text_data">
                                {mat?.fileName ? mat?.fileName : "Material"}
                              </div>
                              <Link
                                to={`${Config.API_HOST_URL_live}/${mat?.documentPath}`}
                                target="_blank"
                                className="btn_dlt1 me-2"
                              >
                                <HiOutlineEye className="edit" />
                              </Link>
                              <button
                                className="btn_dlt1"
                                type="button"
                                onClick={() => handleOpen(mat)}
                              >
                                <VscTrash className="icon" />
                              </button>
                            </div>
                          </div>
                        ))}
                        {displayDoc?.map((mat, no) => (
                          <div className="col-md-6" key={no}>
                            <div className="mat_box_main mb-3">
                              <div className="num me-3">{no + 1}</div>
                              <div className="text_data">
                                {mat?.fileName ? mat?.fileName : "Material"}
                              </div>

                              <button
                                className="btn_dlt1"
                                type="button"
                                onClick={() => handleDocsDelete(mat, mat.id)}
                              >
                                <VscTrash className="icon" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row">
                      {fieldArray2?.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-8 mb-3">
                            <div className="form-group">
                              <label className="form-label">Video Title</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                {...register(
                                  `ProductVideoUrl.${index}.fileName`,
                                  {
                                    // required: "this field is required",
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message:
                                        "Starting and Ending Space not allowed",
                                    },
                                    maxLength: {
                                      value: 250,
                                      message: "Max 250 characters",
                                    },
                                  }
                                )}
                              />
                              {errors.ProductVideoUrl?.[index]?.fileName && (
                                <span role="alert" className="error_text">
                                  {
                                    errors.ProductVideoUrl?.[index]?.fileName
                                      .message
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 my-auto">
                            <div className="form-group">
                              {index === 0 ? (
                                <Button
                                  className="common_btn"
                                  onClick={() =>
                                    AppendArray2({
                                      fileName: "",
                                      videoLink: "",
                                    })
                                  }
                                >
                                  {/* <VscAdd className="me-2" /> */}
                                  Add
                                </Button>
                              ) : (
                                <Button
                                  className="delet_Icon_button"
                                  onClick={() => RemoveArray2(index)}
                                >
                                  {" "}
                                  Delete
                                  {/* <MdDelete fontSize={24} /> */}
                                </Button>
                              )}
                            </div>
                          </div>
                          <div className={` mb-3 col-md-12`}>
                            <div className="form-group">
                              <label className="form-label">
                                Video Url
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter URL"
                                {...register(
                                  `ProductVideoUrl.${index}.videoLink`,
                                  {
                                    // required: "this field is required",
                                    pattern: {
                                      value:
                                        // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                      message: "Plese Enter Valid Url",
                                    },
                                  }
                                )}
                              />
                              {errors.ProductVideoUrl?.[index]?.videoLink && (
                                <span role="alert" className="error_text">
                                  {
                                    errors.ProductVideoUrl?.[index]?.videoLink
                                      .message
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Delete Class Document</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {matD?.fileName}?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={deleteDocument}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default AssociationProductCreate;
