import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action.jsx";
import NewLIstTable from "../../../Components/NewLIstTable/index.jsx";
import "./index.scss";
import ImgPopUp from "../../../Components/ImgPopUp/index.jsx";
import { Config } from "../../../../Utils/Config.jsx";
import { ToastError, ToastSuccess } from "../../../Components/Toast/index.jsx";
import Axios from "../../../../Utils/AxiosConfig.jsx";
const NotFound = "/assets/images/Image_not_available.jpg";

const DeletePopup = lazy(() => import("../../../Components/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsMember = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "logo",
    label: "Profile Picture",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "association",
    label: "District/Organization",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },

  {
    id: "memberNumber",
    label: "Member Number",
  },
  {
    id: "type",
    label: "Type",
  },
  // {
  //   id: "tshirtSize",
  //   label: "T-Shirt Size",
  // },
];

function AssociationMemberList() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const { pathname } = useLocation();
  const [filterPage, setFilterPage] = useState(false);
  const [syncCheck, setSyncCheck] = useState([]);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const Logdetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Association Member";
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (syncCheck?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [syncCheck]);

  useEffect(() => {
    if (pathname) {
      getMemberList(
        pathname === "/state-agencies"
          ? "State Agency"
          : pathname === "/lea-members"
          ? "LEA"
          : pathname === "/industry-members"
          ? "Industry"
          : ""
      );
    }
    // eslint-disable-next-line
  }, [pathname, deleteInfo]);

  const handleSelectSync = (val, userId) => {
    if (val) {
      setSyncCheck((old) => [...old, userId]);
    } else {
      setSyncCheck((old) => old?.filter((data) => data !== userId));
    }
  };

  const handleSelectAll = (val, list) => {
    if (val) {
      setSyncCheck(list?.map((x) => x?.associationMembersId));
    } else {
      setSyncCheck([]);
    }
  };

  const changetbData = () => {
    let newtb = dataOfTable1?.map((pd) => {
      let asId = pd?.associationMemberAdminList?.filter(
        (xy) => xy === Logdetails?.userId
      );
      return {
        ...pd,
        Action: (
          <>
            {Logdetails?.roleId === 1 ? (
              <>
                <label className=" personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={
                      syncCheck?.filter((x) => x === pd?.associationMembersId)
                        ?.length > 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleSelectSync(
                        e?.target?.checked,
                        pd?.associationMembersId
                      )
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <Link
                  type="button"
                  className="btn_edit me-3"
                  to={`edit/${pd?.associationMembersId}`}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VscEdit />
                </Link>
              </>
            ) : asId?.length > 0 ? (
              <Link
                type="button"
                className="btn_edit me-3"
                to={`edit/${pd?.associationMembersId}`}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VscEdit />
              </Link>
            ) : (
              ""
            )}
          </>
        ),
      };
    });
    setDataOfTable1(newtb);
  };

  const getMemberList = async (type) => {
    setDataOfTable1([]);
    if (type) {
      setOpenCircle(true);
      let seData = {
        url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${type}&userId=${Logdetails?.userId}`,
      };
      let res = await GetCallApi(seData);
      setFilterPage(!filterPage);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          let comList = res?.data?.payload?.map((c, i) => {
            let asId = c?.associationMemberAdminList?.filter(
              (xy) => xy === Logdetails?.userId
            );
            return {
              ...c,
              id: i + 1,

              logo: c?.profilePicture ? (
                <ImgPopUp
                  img={`${Config.API_HOST_URL_live}${c?.profilePicture}`}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                <ImgPopUp img={NotFound} addClass={"h_fix"} hide_icon={true} />
              ),
              Action: (
                <>
                  {Logdetails?.roleId === 1 ? (
                    <>
                      <label className="personal_detail_label">
                        <input
                          type="checkbox"
                          className="login_check"
                          name="Remember Me"
                          checked={false}
                          onChange={(e) =>
                            handleSelectSync(
                              e?.target?.checked,
                              c?.associationMembersId
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <Link
                        type="button"
                        className="btn_edit me-3"
                        to={`edit/${c?.associationMembersId}`}
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <VscEdit />
                      </Link>
                    </>
                  ) : asId?.length > 0 ? (
                    <Link
                      type="button"
                      className="btn_edit me-3"
                      to={`edit/${c?.associationMembersId}`}
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <VscEdit />
                    </Link>
                  ) : (
                    ""
                  )}
                  {/* {Logdetails?.roleId === 1 ? (
                    <DeletePopup
                      title={`Delete ${type} `}
                      text={c?.name}
                      url={`/api/AssociationMembers/DeleteAssociationMembers?associationMembersId=${c?.associationMembersId}`}
                    />
                  ) : (
                    ""
                  )} */}
                </>
              ),
              groupUserList:
                c?.userList?.length > 0
                  ? c?.userList?.map((y, index) => {
                      return {
                        ...y,
                        id: index + 1,
                        profile: y?.userProfileImage ? (
                          <ImgPopUp
                            img={`${Config.API_HOST_URL_live}${y?.userProfileImage}`}
                            addClass={"h_fix"}
                            hide_icon={true}
                          />
                        ) : (
                          <ImgPopUp
                            img={NotFound}
                            addClass={"h_fix"}
                            hide_icon={true}
                          />
                        ),
                        type:
                          c?.associationMemberAdminList?.filter(
                            (z) => z === y?.userId
                          )?.length > 0
                            ? "Admin"
                            : "User",
                        Action: (
                          <div className="edit_delete justify-content-start">
                            <DeletePopup
                              title={`Delete ${c?.type} Member `}
                              text={y?.userName}
                              url={`/api/AssociationMembers/DeleteAssociationMembersAllocationUser?associationMembersTypeAllocationId=${y?.associationMembersTypeAllocationId}`}
                            />
                          </div>
                        ),
                      };
                    })
                  : [],
            };
          });
          setDataOfTable1(comList);
        } else {
          setOpenCircle(false);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const handleDelete = async () => {
    let associData = syncCheck?.map((x) => x);

    let seAsso = {
      url: `/api/AssociationMembers/DeleteAssociationMembers`,
      body: associData,
      headers: headers,
    };

    let res = await Axios.delete(seAsso.url, {
      headers: seAsso.headers,
      data: seAsso.body,
    });
    if (res?.status === 200 && res?.data.success) {
      ToastSuccess(res.data.message);
      getMemberList(
        pathname === "/state-agencies"
          ? "State Agency"
          : pathname === "/lea-members"
          ? "LEA"
          : pathname === "/industry-members"
          ? "Industry"
          : ""
      );
    } else {
      ToastError(res.data.message);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  actionFirst={true}
                  selectAllBtn={Logdetails?.roleId === 1 ? true : false}
                  className={"delet_button"}
                  selectingAll={handleSelectAll}
                  AddStudents={handleDelete}
                  prdAddAr={syncCheck}
                  checkBtnName={"Delete"}
                  headCells={headCellsMember}
                  title={
                    pathname === "/state-agencies"
                      ? "State Agencies List"
                      : pathname === "/lea-members"
                      ? "LEA Member List"
                      : "Industry Member List"
                  }
                  // action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                  // addBtn={Logdetails?.roleId === 1 ? true : ""}
                  showUserListInTable={true}
                  fileName={
                    pathname === "/state-agencies"
                      ? "State Agencies List"
                      : pathname === "/lea-members"
                      ? "LEA Member List"
                      : "Industry Member List"
                  }
                  exportDataList={true}
                  innerProduct={true}
                  filterPage={filterPage}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },
                    {
                      id: "profile",
                      label: "Profile",
                      exportTab: false,
                    },
                    {
                      id: "userName",
                      label: "User Name",
                    },
                    {
                      id: "userRole",
                      label: "Role",
                    },
                    {
                      id: "userEmail",
                      label: "Email",
                    },
                    {
                      id: "userPhone",
                      label: "Phone",
                    },
                    {
                      id: "type",
                      label: "Type",
                    },
                    {
                      id: "Action",
                      label: "Action",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationMemberList;
