import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditAssociationUserPayment from "./EditPayment";
import { AttendeeRegistrationConference } from "../../../../../../reducers/Common";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { customFilter } from "../../../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../../../Components/Toast";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
const headCells = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "memberShipType",
    label: "Membership Type",
  },
  {
    id: "fees",
    label: "Fees",
  },
];
function EditAssociationCompanyRegisterPay() {
  const { id, invoiceId } = useParams();
  const [dataOfAssociation, setDataOfAssociation] = useState();

  const [subtotal, setSubTotal] = useState("");

  const [invoiceData, setInvoiceData] = useState();
  const [paymentD, setPaymentD] = useState();
  const [payDetail, setPayDetail] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [userList, setUserList] = useState([]);
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();
  let nav = useNavigate();

  useEffect(() => {
    if (LogDetail) {
      setValue("FirstName", LogDetail?.firstName);
      setValue("LastName", LogDetail?.lastName);
      setValue("email", LogDetail?.email);
      setValue("phone", LogDetail?.phone);
    }
    getAssoData();
    // eslint-disable-next-line
  }, []);

  const getAssoData = async () => {
    let seData = {
      url: `/api/Association/GetAssociationByAssociationId?associationId=${id}`,
      headers: headers,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        setDataOfAssociation(useDe);
        setValue("associationId", id);
        setValue("name", useDe?.name);
        setValue("PaymentRemittanceAddress", useDe?.paymentRemittanceAddress);
        setValue("PaymentCity", useDe?.paymentCity);
        setValue("PaymentState", useDe?.paymentState);
        setValue("PaymentZip", useDe?.paymentZip);
      }
    }
  };

  useEffect(() => {
    if (dataOfAssociation) {
      if (invoiceId) {
        getInvoiceData(invoiceId);
      }
    }
    // eslint-disable-next-line
  }, [invoiceId, dataOfAssociation]);
  const getInvoiceData = async (ind) => {
    let res = await GetCallApi({
      url: `/api/AssociationPayment/GetAssociationMembershipDataByAssociationPaymentId?associationPaymentId=${ind}`,
    });
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload?.ai;
        let asData = res.data.payload;
        setInvoiceData({
          ...useDe,
          associationCompanyDetails: asData?.associationCompanyDetails,

          associationMemberId: asData?.associationMemberId,
          associationMemberName: asData?.associationMemberName,
          associationUserDetails: asData?.associationUserDetails,
          companyName: asData?.companyName,
          paymentMode: asData?.paymentMode,
        });

        setValue("associationInvoiceId", useDe?.associationInvoiceId);
        GetAssociationMemberList(asData?.associationCompanyDetails);
      }
    }
  };

  const GetAssociationMemberList = async (users) => {
    let seData = {
      url: `/api/AssociationMembers/GetAllAssociationMembersListForCompanyPayment?userId=${LogDetail?.userId}&associationId=${id}`,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let asm = res.data.payload
          ?.filter((y) => y?.associationId === id)
          ?.map((x, i) => {
            let ty = x?.type ? " - " + x?.type : "";
            let paid = x?.isUserPayForCompany ? " (Already Paid)" : "";
            let us = users?.find(
              (y) => y?.associationMemberId === x?.associationMembersId
            );
            if (us) {
              return {
                ...x,
                id: i + 1,
                add: true,
                isUserPayForCompany: false,
                fees: us?.price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
                memberShipType: us?.associationMembershipsTypesId,
                cost: us?.price,
                discount: us?.discount,
                price: us?.price,
              };
            }
            return {
              ...x,
              value: x?.associationMembersId,
              label: x?.name + ty + paid,
              id: i + 1,
            };
          });
        // asm = [asm[0]];
        setUserList(asm);
      }
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;

    // if (!data?.orType) {
    //   is_success = false;
    //   Swal.fire({
    //     position: "center",
    //     width: "50%",
    //     title: "Member type is required",
    //     confirmButtonText: "Close",
    //     confirmButtonColor: "#E64942",
    //   });
    // }
    if (is_success) {
      let sed = {
        ...dataOfAssociation,
        ...data,
        subtotal: subtotal,
        organizationOrAttendeeType:
          dataOfAssociation?.associationMembershipsTypes?.filter(
            (x, i) => i + 1 === parseInt(data?.orType)
          )[0]?.name,
        associationMembershipsTypesId:
          dataOfAssociation?.associationMembershipsTypes?.filter(
            (x, i) => i + 1 === parseInt(data?.orType)
          )[0]?.associationMembershipsTypesId,
        amount: subtotal,
        totalPrice: subtotal,
        createdBy: LogDetail?.userId,
        associationCompanyList: userList
          ?.filter((y) => y?.add === true && y?.memberShipType)
          ?.map((x) => {
            return {
              associationMemberId: x?.associationMembersId,
              associationMembershipsTypeId: x?.memberShipType,
              discount: x?.discount,
              price: x?.cost,
              totalAmount: x?.cost,
            };
          }),
      };
      if (parseInt(subtotal) === 0) {
        let seData = {
          url:
            invoiceData?.paymentMode === "Invoice"
              ? `/api/AssociationPayment/AddAssociationInvoice`
              : `/api/AssociationPayment/AssociationPaymentProcess`,
          body: {
            ...invoiceData,
            associationInvoiceId: invoiceId,
            createdBy: LogDetail?.userId,
            totalAmount: subtotal,
            addressLine1: invoiceData?.address1,
            zipCode: invoiceData?.zip,
            invoiceInstructions: invoiceData?.editorValue,
            paymentMode: invoiceData?.paymentMode,
            paymentStatus: "Success",
            invoiceStatus: "Approve",
            // discountValue: discountValue?.discountValue,
            discountValue: sed?.associationUserList
              ?.filter((curr) => curr?.discount > 0)
              ?.reduce((acc, curr) => acc + curr?.discount, 0),
            associationName: sed?.name,
            associationPrice: subtotal.toString(),
            associationMembershipsTypesId: sed?.associationMembershipsTypesId,
            associationId: sed?.associationId,
            associationMemberId: sed?.associationMemberId,
            companyName: sed?.companyName,
            paymentAddress:
              dataOfAssociation?.paymentRemittanceAddress +
              " \r\n" +
              dataOfAssociation?.paymentCity +
              " " +
              dataOfAssociation?.paymentState +
              " " +
              dataOfAssociation?.paymentZip,
            registerType: "AssociationMember",
          },
        };
        let res = await PostCallApi(seData);
        if (res?.status === 200 && (res?.data?.success || res?.data.isPaid)) {
          await SendUser({
            ...sed,
            paymentMode: invoiceData?.paymentMode,
            associationInvoiceId:
              res?.data?.payload?.associationInvoiceId ?? null,
            associationPaymentId:
              invoiceData?.paymentMode === "Online"
                ? res?.data?.paymentId
                : res?.data?.payload?.associationPaymentId,
          });
          dispatch(AttendeeRegistrationConference());
          nav(-1);
          ToastSuccess(res?.data?.message);
        } else {
          ToastError(res?.data?.message);
        }
      } else {
        setPayDetail({
          ...sed,
          paymentMode: invoiceData?.paymentMode,
        });
        setPaymentD(true);
        dispatch(
          AttendeeRegistrationConference({
            ...sed,
            ...invoiceData,
          })
        );
      }
    }
  };
  const SendUser = async (data) => {
    let send = { ...data };
    await PostCallApi({
      url: `/api/AssociationPayment/GroupAssociationCompanyAllocation`,
      body: send,
    });
  };

  useEffect(() => {
    handleTotal();
    // eslint-disable-next-line
  }, [userList]);

  const handleTotal = () => {
    let cp = userList?.filter((x) => x?.add === true)?.map((x) => x?.cost);

    const sum =
      cp?.length > 0
        ? cp?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;
    setSubTotal(sum);
  };

  const handleReset = () => {
    reset();
    setDataOfAssociation();
    setPaymentD();
  };

  const handleAll = (e) => {
    const newArr = userList.map((obj) => {
      if (obj.isUserPayForCompany) {
        return obj;
      }
      return { ...obj, add: e, memberShipType: "", fees: null, cost: null };
    });
    setUserList(newArr);
    setCheckBoxVal(e);
  };

  const handleMainCheck = (e, index) => {
    let allMainCh = true;
    const newArr = userList.map((obj) => {
      if (obj.associationMembersId === index.associationMembersId) {
        return { ...obj, add: e, memberShipType: "", fees: null, cost: null };
      }
      return obj;
    });

    setUserList(newArr);

    if (e) {
      newArr
        ?.filter((y) => y?.isUserPayForCompany === false)
        ?.map((x) => {
          if (x.add === false || x.add === undefined) {
            return (allMainCh = false);
          }

          return 0;
        });
      // setCourseSchedule()
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };

  const handleAddTypeToUser = (e, us) => {
    let newArr = userList.map((obj) => {
      if (obj.associationMembersId === us.associationMembersId) {
        let st = dataOfAssociation?.associationMembershipsTypes?.find(
          (x) => x?.associationMembershipsTypesId === e
        )?.cost;
        return {
          ...obj,
          memberShipType: e,
          fees: st?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          cost: st,
        };
      }
      return obj;
    });
    setUserList(newArr);
  };
  return (
    <section className="Register_Conference_Form ">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="main_wrapper">
        <div className="card">
          <div className="card-body">
            {paymentD ? (
              <EditAssociationUserPayment
                companyData={payDetail}
                handleReset={handleReset}
              />
            ) : (
              <>
                <div className="title">Pay Fee&apos;s of Association</div>
                <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Association Name</label>
                        <input
                          className="form-control"
                          {...register("name")}
                          disabled
                        ></input>
                        {errors.associationMemberId && (
                          <span role="alert" className="error_text">
                            {errors.associationMemberId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          {...register("FirstName", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                          disabled
                        />
                        {errors.FirstName && (
                          <span role="alert" className="error_text">
                            {errors.FirstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...register("LastName", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                          disabled
                        />
                        {errors.LastName && (
                          <span role="alert" className="error_text">
                            {errors.LastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Phone/Mobile
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone/Mobile"
                          {...register("phone", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                          disabled
                        />
                        {errors.phone && (
                          <span role="alert" className="error_text">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                          disabled
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {userList?.length > 0 ? (
                      <div className="table-wrap mt-5">
                        <Table className="table-bordered Conference-table">
                          <TableHead className="thead-primary">
                            <StyledTableRow>
                              <StyledTableCell>
                                <label className="">
                                  <input
                                    type="checkbox"
                                    checked={checkBoxVal}
                                    onChange={(e) =>
                                      handleAll(e.target.checked)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </StyledTableCell>
                              {headCells?.map((headCell) => (
                                <StyledTableCell key={headCell?.id}>
                                  {headCell.label}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {userList?.map((us, id) => (
                              <StyledTableRow className="table_body" key={id}>
                                <StyledTableCell>
                                  {us?.isUserPayForCompany ? (
                                    "Paid"
                                  ) : (
                                    <label className=" ">
                                      <input
                                        type="checkbox"
                                        checked={us?.add}
                                        onChange={(e) =>
                                          handleMainCheck(
                                            e?.target?.checked,
                                            us
                                          )
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  )}
                                </StyledTableCell>
                                {headCells?.map((column) => {
                                  const value = us[column?.id];
                                  if (column?.id === "memberShipType") {
                                    return (
                                      <StyledTableCell key={column?.id}>
                                        {us?.add ? (
                                          <Select
                                            isMulti={false}
                                            name="colors"
                                            options={dataOfAssociation?.associationMembershipsTypes
                                              ?.filter(
                                                (x) =>
                                                  x?.membershipType === us?.type
                                              )
                                              ?.map((x) => {
                                                return {
                                                  ...x,
                                                  label: x?.title,
                                                  value:
                                                    x?.associationMembershipsTypesId,
                                                };
                                              })}
                                            className="company-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Membership"
                                            filterOption={customFilter}
                                            value={dataOfAssociation?.associationMembershipsTypes
                                              ?.filter(
                                                (x) =>
                                                  x?.membershipType === us?.type
                                              )
                                              ?.map((x) => {
                                                return {
                                                  ...x,
                                                  label: x?.title,
                                                  value:
                                                    x?.associationMembershipsTypesId,
                                                };
                                              })
                                              ?.filter(
                                                (obj) => value === obj.value
                                              )}
                                            onChange={(e) =>
                                              handleAddTypeToUser(e?.value, us)
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </StyledTableCell>
                                    );
                                  }
                                  return (
                                    <StyledTableCell
                                      className={`column_data`}
                                      key={column?.id}
                                    >
                                      {value ? value : ""}
                                    </StyledTableCell>
                                  );
                                })}
                              </StyledTableRow>
                            ))}
                            {userList?.filter(
                              (us) => us?.add === true && us?.memberShipType
                            )?.length > 0 ? (
                              <StyledTableRow className="table_body">
                                <StyledTableCell
                                  colSpan={headCells?.length}
                                  sx={{ textAlign: "end" }}
                                >
                                  Total Amount
                                </StyledTableCell>
                                <StyledTableCell colSpan={7}>
                                  {subtotal?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              ""
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-12 mt-3">
                      {userList?.filter(
                        (us) => us?.add === true && us?.memberShipType
                      )?.length > 0 ? (
                        userList?.filter((us) => us?.add === true)?.length ===
                        userList?.filter((us) => us?.memberShipType)?.length ? (
                          <Button type="submit" className="common_btn">
                            Submit
                          </Button>
                        ) : (
                          <Button disabled className="common_btn">
                            Submit
                          </Button>
                        )
                      ) : (
                        <Button disabled className="common_btn">
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditAssociationCompanyRegisterPay;
