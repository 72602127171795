import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { Link } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { ToastError } from "../../Components/Toast";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import TableHeader from "../../../TableHeader.json";
import Select from "react-select";
// import { VscEdit } from "react-icons/vsc";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const PermenentDeletePopup = lazy(() =>
  import("../../Components/PermenentDeletePopup")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function AssociationMembershipPaymentHistory() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);
  const [asList, setASList] = useState([]);
  const [asId, setASId] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentType, setPaymentType] = useState();
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | User Association Payment History";
    getAssoList();
    getLEAList();
    getAgencyist();
    getIndustryist();
    // eslint-disable-next-line
  }, []);

  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAssociationListForFliter?userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationId,
            label: x?.name,
          };
        });
        setASList(data);
      }
    }
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        LogDetails?.userId
      }`,
      // url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"LEA"}&userId=${
      //   LogDetails?.userId
      // }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setLeaList(data);
      }
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        LogDetails?.userId
      }`,
      // url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"State Agency"}&userId=${
      //   LogDetails?.userId
      // }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setAgencyList(data);
      }
    }
  };

  const getIndustryist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
        LogDetails?.userId
      }`,
      //    url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"Industry"}&userId=${
      //   LogDetails?.userId
      // }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setIndustryList(data);
      }
    }
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const GetPaymentList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let memberList = [];
    if (selectLea?.length > 0) {
      memberList = selectLea;
    }
    if (selectAgency?.length > 0) {
      memberList = [...memberList, ...selectAgency];
    }
    if (selectIndustry?.length > 0) {
      memberList = [...memberList, ...selectIndustry];
    }
    let res = await PostCallApi({
      url: `/api/AssociationPayment/GetAssociationPaymentHistory`,
      body: {
        ...data,
        loginUserId: LogDetails?.userId,
        associationMemberId: memberList?.length > 0 ? memberList : [],
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        let astype = c?.associationType ? " - " + c?.associationType : "";

        return {
          ...c,
          id: i + 1,
          phoneNumber: c?.phoneNumber,
          phone: c?.phoneNumber,
          date: c?.date
            ? new Date(c?.date).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          dueDate: c?.dueDate
            ? new Date(c?.dueDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          associationMemberName: c?.associationMemberName
            ? c?.associationMemberName + astype
            : "",
          groupUserList:
            c?.userType === "User"
              ? c?.groupAssociationUserList?.length > 0
                ? c?.groupAssociationUserList?.map((x, index) => {
                    return {
                      ...x,
                      registerType: "User",
                      id: index + 1,
                    };
                  })
                : []
              : c?.groupAssociationCompanyList?.length > 0
              ? c?.groupAssociationCompanyList?.map((x, index) => {
                  return {
                    ...x,
                    userName: x?.companyName,
                    userEmail: x?.companyEmail,
                    userPhone: x?.companyPhone,
                    registerType: "Company",
                    id: index + 1,
                  };
                })
              : [],
          Action: (
            <>
              {c?.registerType === "Stripe" ? (
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit me-3"
                    to={`/association-stripe-recipe/${c?.paymentId}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit me-3"
                    to={`/association-invoice-list/view/${c?.invoiceId}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
              )}
              {LogDetails?.roleId === 1 ? (
                <>
                  {/* <Link
                    to={
                      c?.registerType === "User"
                        ? `/association-invoice-list/edit/${c?.associationId}/${c?.paymentId}`
                        : `/association-invoice-list/company/edit/${c?.associationId}/${c?.paymentId}`
                    }
                    className="btn_edit me-3 d-flex align-items-center"
                  >
                    <VscEdit />
                  </Link> */}
                  <PermenentDeletePopup
                    title={"Delete User Association Invoice"}
                    text={c?.userName + "'s invoice"}
                    url={`/api/AssociationPayment/DeleteAssociationMembershipByPaymentId?associationPaymentId=${c?.paymentId}&createdBy=${LogDetails?.userId}&deletePermanently=`}
                    callBack={true}
                    callBackFunction={CallBackApi}
                  />
                </>
              ) : (
                ""
              )}
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const CallBackApi = () => {
    let send = {
      associationId: getValues("associationId"),
      paymentStatus: getValues("paymentStatus"),
      poNumber: getValues("poNumber") ? getValues("poNumber") : "",
      paymentReference: getValues("paymentReference")
        ? getValues("paymentReference")
        : "",
      paymentDate: getValues("paymentDate") ? getValues("paymentDate") : null,
      paymentType: getValues("paymentType") ? getValues("paymentType") : null,
    };
    GetPaymentList(send);
  };

  const handleAS = (e) => {
    setASId(e);
    setValue("associationId", e);
  };

  const PaymentStatusOption = [
    { value: "Pending", label: "Pending" },
    { value: "Success", label: "Success" },
  ];

  const handlePaymentStatus = (e) => {
    setPaymentStatus(e);
    setValue("paymentStatus", e);
  };

  const PaymentTypeOption = [
    { value: "Individual Invoice", label: "Individual Invoice" },
    { value: "Stripe", label: "Stripe" },
  ];

  const handlePaymentType = (e) => {
    setPaymentType(e);
    setValue("paymentType", e);
  };

  const handleClearFilter = () => {
    setASId();
    setPaymentStatus();
    setPaymentType();
    setSelectLea([]);
    setSelectIndustry([]);
    setSelectAgency([]);
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetPaymentList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Association</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={asList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Association"
                              filterOption={customFilter}
                              value={asList?.filter(
                                (obj) => obj.value === asId
                              )}
                              onChange={(e) => handleAS(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">PO Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter PO Number"
                              {...register("poNumber", {
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.poNumber && (
                              <span role="alert" className="error_text">
                                {errors.poNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              Payment Reference
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Payment Reference"
                              {...register("paymentReference", {
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.paymentReference && (
                              <span role="alert" className="error_text">
                                {errors.paymentReference.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Status</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={PaymentStatusOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Payment Status"
                              filterOption={customFilter}
                              value={PaymentStatusOption?.filter(
                                (obj) => obj.value === paymentStatus
                              )}
                              onChange={(e) => handlePaymentStatus(e?.value)}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("paymentDate", {
                                valueAsDate: true,
                              })}
                            />
                            {errors.paymentDate && (
                              <span role="alert" className="error_text">
                                {errors.paymentDate.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Type</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={PaymentTypeOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Payment Type"
                              filterOption={customFilter}
                              value={PaymentTypeOption?.filter(
                                (obj) => obj.value === paymentType
                              )}
                              onChange={(e) => handlePaymentType(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">LEA Member</label>
                            <Select
                              isMulti
                              name="colors"
                              options={leaList?.filter(
                                (x) => x?.associationId === asId
                              )}
                              className="company-multi-select"
                              classNamePrefix="select"
                              placeholder="Select LEA Member"
                              filterOption={customFilter}
                              value={leaList?.filter((obj) =>
                                selectLea?.includes(obj.value)
                              )}
                              onChange={handleChangeLea}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">State Agencies</label>
                            <Select
                              isMulti
                              name="colors"
                              options={agencyList?.filter(
                                (x) => x?.associationId === asId
                              )}
                              className="company-multi-select"
                              classNamePrefix="select"
                              placeholder="Select State Agencies"
                              filterOption={customFilter}
                              value={agencyList?.filter((obj) =>
                                selectAgency?.includes(obj.value)
                              )}
                              onChange={handleChangeAgency}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              Industry Member
                            </label>
                            <Select
                              isMulti
                              name="colors"
                              options={industryList?.filter(
                                (x) => x?.associationId === asId
                              )}
                              className="company-multi-select"
                              classNamePrefix="select"
                              placeholder="Select Industry Member"
                              filterOption={customFilter}
                              value={industryList?.filter((obj) =>
                                selectIndustry?.includes(obj.value)
                              )}
                              onChange={handleChangeIndustry}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    title="Association Membership Payment History"
                    action={true}
                    headCells={TableHeader?.UserAssociationPaymentHistory}
                    ListData={dataOfTable}
                    dropdown={false}
                    addBtn={false}
                    showUserListInTable={true}
                    fileName={"Association Membership Payment History"}
                    filterPage={filterPage}
                    exportDataList={true}
                    innerProduct={true}
                    subHeadCells={
                      TableHeader?.AssociationMembershipPaymentHistorySubHeader
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationMembershipPaymentHistory;
