import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { HiPlus, HiMinus } from "react-icons/hi";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import AssociationProductPayment from "./Payment";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../../../Action/Action";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { customFilter } from "../../../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../../../Components/Toast";
// import { addToDiscountCart } from "../../../../../../reducers/CartProductDiscount";
const deleteIcon = "/assets/images/deleteIcon.png";
const NotFound = "/assets/images/Image_not_available.jpg";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CartItem = ({
  imgSrc,
  name,
  // price,
  qty,
  decrement,
  increment,
  deleteHandler,
  id,
  descrption,
  applyPromo,
  deletePromo,
  btnLoad,
  discountValue,
}) => {
  // const [btnLoad, setBtnLoad] = useState(false);
  // const LogDetails = useSelector((state) => state.login.LoginDetails);
  // const dispatch = useDispatch();

  const DiscountApply = ({ applyPromo, btnLoad, subtotal }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    return (
      <form onSubmit={handleSubmit(applyPromo)} className="">
        <div className="discountCodeInputBox">
          <div className="overlap">
            <input
              type="text"
              placeholder="Enter Discount Code"
              className="form-control"
              disabled={subtotal === 0 ? true : false}
              // disabled={discountValue ? true : false}
              {...register("promoCode", {
                pattern: {
                  value: /^[^\s]+(\s+[^\s]+)*$/,
                  message: "Starting and Ending Space not allowed",
                },
              })}
            />
            <Button
              className="common_btn"
              type="submit"
              disabled={subtotal === 0 ? true : btnLoad ? true : false}
              // disabled={discountValue ? true : false}
            >
              {btnLoad ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    color: "#fff",
                    marginLeft: "0.5rem",
                    height: "23px !important",
                    width: "23px !important",
                  }}
                />
              ) : (
                "Apply"
              )}
            </Button>
            {/* )} */}
          </div>
          {errors.promoCode && (
            <span role="alert" className="error_text">
              {errors.promoCode.message}
            </span>
          )}
        </div>
      </form>
    );
  };
  const handlePromo = (data) => {
    if (data?.promoCode) {
      let send = {
        ...data,
        associationProductId: id?.associationProductId,
        finalTotal: id?.finalTotal,
      };
      applyPromo(send);
    } else {
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Please enter discount code",
        confirmButtonText: "Close",
        confirmButtonColor: "#E64942",
      });
      // setSubTotal(companyData?.createMember?.mainTotal);
    }
  };
  return (
    <div className="cart_discount">
      <div className="cartItem">
        <div className="d-flex gap-3 align-items-center">
          <img src={imgSrc} alt="Item" className="prd_img" />
          <div>
            <h4>{name}</h4>
            <div className="price">
              {id?.isDiscountedProduct ? "Discounted Price" : "Price"}:{" "}
              {id?.isDiscountedProduct ? (
                <span className="me-2">
                  -
                  {id?.discountedType === "Percentage"
                    ? id?.discountedValue + "%"
                    : " " + id?.discountedValue}{" "}
                </span>
              ) : (
                ""
              )}
              <b>
                {id?.isDiscountedProduct
                  ? id?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : id?.totalProductPrice?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </b>
              {id?.isDiscountedProduct ? (
                <div
                  className="description f-18-400 "
                  // style={{
                  //   textDecoration: "line-through",
                  // }}
                >
                  Price:{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                    }}
                  >
                    {id?.totalProductPrice?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </div>
              ) : (
                ""
              )}
              {/* {price?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })} */}
            </div>
            <p>{descrption}</p>
          </div>
        </div>
        <div className="btn_box">
          {discountValue?.length === 0 ? (
            <>
              {qty === 1 ? (
                <button className={"disable"} disabled>
                  <HiMinus />
                </button>
              ) : (
                <button onClick={() => decrement(id?.associationProductId)}>
                  <HiMinus />
                </button>
              )}
              <p>{qty}</p>
              <button onClick={() => increment(id)}>
                <HiPlus />
              </button>
            </>
          ) : (
            ""
          )}
          <AiFillDelete
            className="delete_icon"
            onClick={() => deleteHandler(id?.associationProductId)}
          />
        </div>
      </div>
      <div className="discount_details border-bottom-0">
        <ul>
          {discountValue?.length > 0 ? (
            <>
              <li>
                <p>
                  {/* Discount */}
                  {id?.cardTitle + " (" + id?.quantity + ")"}
                  <span className="ms-auto">
                    {" "}
                    {id
                      ? id.netAmount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : ""}
                  </span>
                </p>
              </li>
              <h6 className="mb-0">Discount Code</h6>
              {discountValue?.map((x, i) => (
                <li key={i}>
                  <p>
                    {/* Discount */}
                    {x?.promoCode}
                    <span className="ms-auto">
                      {" "}
                      -
                      {x
                        ? x.discountValue?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : ""}
                    </span>

                    <button
                      type="button"
                      className="deleteBtn"
                      onClick={() =>
                        deletePromo({
                          associationProductId: id?.associationProductId,
                          codeId: x?.codeId,
                        })
                      }
                    >
                      <img src={deleteIcon} className="img-fluid" alt="" />
                    </button>
                  </p>
                </li>
              ))}
              <li>
                <p>
                  Total Discount
                  <span className="ms-auto">
                    {" "}
                    -
                    {discountValue
                      ?.reduce((acc, curr) => acc + curr?.discountValue, 0)
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Final Total
                  <span className="ms-auto">
                    <b>
                      {id?.finalTotal?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </b>
                  </span>
                </p>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>

        <DiscountApply
          applyPromo={handlePromo}
          btnLoad={btnLoad}
          subtotal={id?.finalTotal}
        />
        <p className="discountNote">
          Note: After applying a discount code, the quantity of any product
          cannot be updated, and if any product is removed, all discount codes
          will be automatically removed.
        </p>
      </div>
    </div>
  );
};

function ProductCart() {
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const nav = useNavigate();
  const { id } = useParams();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [openCircle, setOpenCircle] = useState(false);

  const [asList, setAsList] = useState([]);
  const [addressList, setAddressList] = useState();
  const [selectAddress, setSelectAddress] = useState();
  const [payDetail, setPayDetail] = useState();
  const [paymentShow, setPaymentShow] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [associationMembersId, setAssociationMembersId] = useState();
  const [asMAddress, setAsMAddress] = useState();
  const [asMAdminAddress, setAsMAdminAddress] = useState([]);
  const [asMAdmin, setAsMAdmin] = useState([]);
  const [asMAdminId, setAsMAdminId] = useState([]);
  // const [discountValue, setDiscountValue] = useState([]);
  const [btnLoad, setBtnLoad] = useState();

  const increment = async (data) => {
    setOpenCircle(true);
    productQuatityManage({
      associationProductId: data?.associationProductId,
      isAdded: true,
      userId: LogDetails?.userId,
      createdBy: LogDetails?.userId,
    });
  };

  const productQuatityManage = async (body) => {
    let res = await PostCallApi({
      url: `/api/AssociationProductAddToCart/UpdateProductQuantityByUserId`,
      body: body,
    });
    setOpenCircle(false);

    if (res?.status === 200 && res?.data?.success) {
      GetCartData();
      ToastSuccess(res?.data?.message);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const decrement = async (id) => {
    setOpenCircle(true);
    productQuatityManage({
      associationProductId: id,
      isAdded: false,
      userId: LogDetails?.userId,
      createdBy: LogDetails?.userId,
    });
  };

  const deleteHandler = async (id) => {
    setOpenCircle(true);
    let res = await DeleteCallApi({
      url: `/api/AssociationProductAddToCart/DeleteAddToCartProductByUserId?associationProductId=${id}&userId=${LogDetails?.userId}`,
    });
    setOpenCircle(false);

    if (res?.status === 200 && res?.data?.success) {
      GetCartData();
      ToastSuccess(res?.data?.message);
    } else {
      ToastError(res?.data?.message);
    }
  };
  useEffect(() => {
    GetUsDetails();
    GetCartData();
    GetAssociationMemberList();
  }, []);
  const GetUsDetails = async () => {
    let seData = {
      url: `/api/UserMst/GetAttendeeRoleData?userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload[0];
        if (ud?.address?.length > 0) {
          let AllAddress = [];
          let home = ud.address?.find((x) => x?.addressType === "Home");
          if (home) {
            AllAddress.push(home);
          }
          let office = ud.address?.find((x) => x?.addressType === "Office");
          if (office) {
            AllAddress.push(office);
          }
          let other = ud.address?.find((x) => x?.addressType === "Other");
          if (other) {
            AllAddress.push(other);
          }
          setAddressList(
            AllAddress?.map((x) => {
              return { ...x, id: generateId(4), adType: "User" };
            })
          );
        }
      }
    }
  };
  useEffect(() => {
    let cartTotal = cartItems?.reduce((acc, cuu) => acc + cuu?.finalTotal, 0);
    setTotal(cartTotal);
  }, [cartItems]);
  const GetCartData = async () => {
    setCartItems([]);
    setTotal(0);
    let seData = {
      url: `/api/AssociationProductAddToCart/GetAllAddToCartProductByUserId?userId=${LogDetails?.userId}&associationId=${id}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload?.map((x) => {
          return {
            ...x,
            cardImg: x?.productImages?.[0] ? x?.productImages?.[0] : NotFound,
            cardTitle: x.productName,
            des: x?.productDescription,
            price: x?.productPrice,
            finalTotal: x?.netAmount,
            discountValue: [],
          };
        });
        setCartItems(ud);
      }
    }
  };

  const GetAssociationMemberList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAllAssociationMembersList?userId=${LogDetails?.userId}`,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let asm = res.data.payload?.map((x) => {
          let ty = x?.type ? " - " + x?.type : "";
          return {
            ...x,
            value: x?.associationMembersId,
            label: x?.name + ty,
          };
        });

        setAsList(asm);
      }
    }
  };

  const HandlePayment = (data) => {
    if (selectAddress) {
      let cart = cartItems?.map((x) => {
        return {
          ...x,
          price: x?.netAmount,
          mainPrice: x?.price,
          totalAmount: x?.finalTotal,
          totalDiscount:
            x?.discountValue?.reduce(
              (acc, curr) => acc + curr?.discountValue,
              0
            ) ?? 0,
          productPromoCodeList: x?.discountValue?.map((x) => {
            return {
              // ...x,
              productPromoCodeId: x?.productPromoCodeId,
              discountAmount: x?.discountValue,
            };
          }),
        };
      });
      let ad =
        asMAdminAddress?.length > 0
          ? [asMAddress, ...asMAdminAddress, ...addressList]?.find(
              (x) => x?.id === selectAddress
            )
          : asMAddress
          ? [asMAddress, ...addressList]?.find((x) => x?.id === selectAddress)
          : addressList?.find((x) => x?.id === selectAddress);
      if (total === 0) {
        SaveOrder({ ...data, ad: ad });
      } else {
        let td = cart?.reduce((acc, curr) => acc + curr?.totalDiscount, 0);

        setPayDetail({
          ...data,
          discountAmount: td,
          associationProductList: cart,
          subtotal: total,
          orderNumber: generateId(10),
          shippingAddress: ad?.adType === "User" ? ad?.addressType : null,
          addressDeatils: ad,
          address: ad?.adType === "User" ? "" : ad?.address ? ad?.address : "",
          shippingAddressType:
            ad?.adType === "User" ? "" : ad?.addressType ? ad?.addressType : "",
          shippingCity: ad?.adType === "User" ? "" : ad?.city ? ad?.city : "",
          shippingState:
            ad?.adType === "User" ? "" : ad?.state ? ad?.state : "",
          shippingZip: ad?.adType === "User" ? "" : ad?.zip ? ad?.zip : "",
        });
        setPaymentShow(true);
      }
    } else {
      if (addressList?.length > 0) {
        Swal.fire({
          position: "center",
          width: "50%",
          title: "Please select address",
          confirmButtonText: "Close",
          confirmButtonColor: "#E64942",
        });
      } else {
        Swal.fire({
          position: "center",
          width: "50%",
          title:
            "Please update address from profile after you can proceed to buy product",
          confirmButtonText: "Go to Profile",
          confirmButtonColor: "#80c242",
        }).then((value) => {
          if (value?.isConfirmed) {
            if (LogDetails?.roleName === "Speaker") {
              nav("/speaker-profile", { replace: true });
            } else {
              nav("/attendee-profile", { replace: true });
            }
          }
        });
      }
    }
  };

  const SaveOrder = async (data) => {
    let cart = cartItems?.map((x) => {
      return {
        ...x,
        price: x?.netAmount,
        mainPrice: x?.price,
        totalAmount: x?.finalTotal,
        totalDiscount:
          x?.discountValue?.reduce(
            (acc, curr) => acc + curr?.discountValue,
            0
          ) ?? 0,
        productPromoCodeList: x?.discountValue?.map((x) => {
          return {
            // ...x,
            productPromoCodeId: x?.productPromoCodeId,
            discountAmount: x?.discountValue,
          };
        }),
      };
    });
    let td = cart?.reduce((acc, curr) => acc + curr?.totalDiscount, 0);

    let paymentData = {
      ...data,
      associationProductList: cart,
      subtotal: total,
      orderNumber: generateId(10),
      shippingAddress:
        data?.ad?.adType === "User" ? data?.ad?.addressType : null,
      addressDeatils: data?.ad,
      paymentStatus: "success",
      totalAmount: total,
      createdBy: LogDetails?.userId,
      userId: LogDetails?.userId,
      email: LogDetails?.email,
      amount: total,
      associationId: id,
      paymentMode: "Online",
      discountAmount: td,
      address:
        data?.ad?.adType === "User"
          ? ""
          : data?.ad?.address
          ? data?.ad?.address
          : data?.ad?.address1,
      shippingAddressType:
        data?.ad?.adType === "User"
          ? ""
          : data?.ad?.addressType
          ? data?.ad?.addressType
          : "",
      shippingCity:
        data?.ad?.adType === "User" ? "" : data?.ad?.city ? data?.ad?.city : "",
      shippingState:
        data?.ad?.adType === "User"
          ? ""
          : data?.ad?.state
          ? data?.ad?.state
          : "",
      shippingZip:
        data?.ad?.adType === "User" ? "" : data?.ad?.zip ? data?.ad?.zip : "",
    };
    let sveCC = {
      url: `/api/AssociationProductPayment/AssociationProductPaymentProcess`,
      body: paymentData,
    };
    let res1 = await PostCallApi(sveCC);
    if (res1?.status === 200) {
      if (res1.data.isPaid) {
        let svpId = res1?.data?.paymentId;
        let newApi = {
          url: `/api/AssociationProductPayment/CreateAssociationProductUserAllocation`,
          body: {
            associationProductList: cart,
            subtotal: total,
            orderNumber: generateId(10),
            shippingAddress: selectAddress,
            addressDeatils: data?.ad,
            createdBy: LogDetails?.userId,
            userId: LogDetails?.userId,
            paymentStatus: "success",
            totalAmount: total,
            associationProductPaymentId: svpId,
            paymentMode: "Online",
          },
        };
        let res = await PostCallApi(newApi);
        if (res?.status === 200 && res?.data?.success) {
          nav(-1);
          ToastSuccess(res.data.message);
        } else {
          ToastError(res.data.message);
        }
      }
    }
  };
  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  const handleCompany = (e) => {
    setAssociationMembersId(e);
    setValue("associationMemberId", e);
    AssociationAdminWithAddress(e);
  };
  const handleAsMAdmin = (e) => {
    setAsMAdminId(e);
    setValue("associationMemberAdminId", e);
    let ad = asMAdmin?.find((x) => x?.value === e);
    let AllAddress = [];
    let home = ad?.adminAddress?.find((x) => x?.addressType === "Home");
    if (home) {
      AllAddress.push(home);
    }
    let office = ad?.adminAddress?.find((x) => x?.addressType === "Office");
    if (office) {
      AllAddress.push(office);
    }
    let other = ad?.adminAddress?.find((x) => x?.addressType === "Other");
    if (other) {
      AllAddress.push(other);
    }
    setAsMAdminAddress(
      ad
        ? AllAddress?.map((x) => {
            return { ...x, id: generateId(4) };
          })
        : []
    );
  };

  const AssociationAdminWithAddress = async (id) => {
    setAsMAdmin([]);
    if (id) {
      let res = await GetCallApi({
        url: `/api/AssociationMembers/GetAssociationMembersAdminWithAddressListByAssociationMemberId?associationMemberId=${id}`,
      });
      if (res.status === 200 && res?.data.success) {
        let asmd = res.data?.payload;
        setAsMAddress({ ...asmd, addressType: "Office", id: generateId(4) });
        setAsMAdmin(
          asmd?.associtionAdminWithAddres?.map((x) => {
            return {
              ...x,
              value: x?.associationAdminId,
              label: x?.associationAdminName,
            };
          })
        );
      }
    }
  };

  const applyPromo = async (data) => {
    // setDiscountValue();

    let send = {
      amount: data?.finalTotal,
      productId: data?.associationProductId,
      // promoCodeId: d?.promoCodeId,
      userId: LogDetails?.userId,
      productPromoCode: data?.promoCode,
    };

    setBtnLoad({
      associationProductId: data?.associationProductId,
      load: true,
    });
    let seData = {
      url: `/api/AssociationProductPayment/GetAmountAfterApplyProductPromoCodeOnProduct`,
      body: send,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res.data.success) {
      let am = res.data.payload;
      let cart = cartItems?.map((x) => {
        if (data?.associationProductId === x?.associationProductId) {
          return {
            ...x,
            finalTotal: am?.finalAmount,
            discountValue: [
              ...(x?.discountValue ?? []),
              { ...am, promoCode: data?.promoCode, codeId: generateId(10) },
            ],
          };
        }
        return x;
      });
      setCartItems(cart);
      // if (discountValue?.length > 0) {
      //   if (
      //     discountValue?.filter((x) => x?.promoCodeId === am?.promoCodeId)
      //       ?.length > 0
      //   ) {
      //     SwalMessage(
      //       `Same Discount Code Not Applicable. You have already used ${data?.promoCode}. `
      //     );
      //   } else {
      //     setSubTotal(am?.finalAmount);
      //     let disc =
      //       discountValue?.length > 0
      //         ? [...discountValue, { ...am, promoCode: data?.promoCode }]
      //         : [{ ...am, promoCode: data?.promoCode }];
      //     setDiscountValue(disc);
      //   }
      // } else {

      //  setSubTotal(am?.finalAmount);
      // let disc =
      //   discountValue?.length > 0
      //     ? [...discountValue, { ...am, promoCode: data?.promoCode }]
      //     : [{ ...am, promoCode: data?.promoCode }];
      // setDiscountValue(disc);
      // dispatch(
      //   addToDiscountCart({
      //     associationProductId: id?.associationProductId,
      //     discountValue: [{ ...am, promoCode: data?.promoCode }],
      //   })
      // );
      // }
      setBtnLoad();
    } else {
      setBtnLoad();

      ToastError(res.data.message);
    }
  };
  const deletePromo = (id) => {
    let dv = cartItems?.map((x) => {
      if (x?.associationProductId === id?.associationProductId) {
        return {
          ...x,
          discountValue: x?.discountValue?.filter(
            (y) => y?.codeId !== id?.codeId
          ),
          finalTotal:
            x?.finalTotal +
            x?.discountValue?.find((y) => y?.codeId === id?.codeId)
              ?.discountValue,
        };
      }
      return x;
    });
    setCartItems(dv);
    // setDiscountValue(dv);
    // let vl = discountValue?.find(
    //   (x) => x?.productPromoCodeId === id
    // ).discountValue;
    // setSubTotal(subtotal + vl);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="product_cart">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              {paymentShow ? (
                <AssociationProductPayment companyData={payDetail} />
              ) : (
                <div className="row">
                  <div className="col-xl-9 col-md-12">
                    <div className="title">Cart</div>
                    {cartItems?.length > 0 ? (
                      cartItems?.map((i) => (
                        <CartItem
                          imgSrc={i.cardImg}
                          name={i.productName}
                          price={i.price}
                          descrption={i.des}
                          qty={i.quantity}
                          id={i}
                          key={i.associationProductId}
                          decrement={decrement}
                          increment={increment}
                          deleteHandler={deleteHandler}
                          applyPromo={applyPromo}
                          deletePromo={deletePromo}
                          btnLoad={
                            btnLoad?.associationProductId ===
                              i.associationProductId && btnLoad?.load === true
                          }
                          discountValue={i?.discountValue}
                        />
                      ))
                    ) : (
                      <div className="">No items Yet</div>
                    )}
                  </div>
                  <div className="col-xl-3 col-md-12">
                    <div className="title">Payment Details</div>
                    <div className="payment_detail">
                      <Table>
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Item</StyledTableCell>
                            <StyledTableCell>Quantity</StyledTableCell>
                            <StyledTableCell>Line Total</StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {cartItems?.length > 0
                            ? cartItems?.map((cart) => (
                                <StyledTableRow
                                  key={cart?.associationProductId}
                                >
                                  <StyledTableCell>
                                    {cart?.productName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {cart?.quantity}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {cart?.finalTotal?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            : ""}
                          {/* <StyledTableRow>
                        <StyledTableCell align="right" colSpan={2}>
                          SubTotal
                        </StyledTableCell>
                        <StyledTableCell>
                          {subTotal?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </StyledTableCell>
                      </StyledTableRow> */}
                          <StyledTableRow>
                            <StyledTableCell align="right" colSpan={2}>
                              Total
                            </StyledTableCell>
                            <StyledTableCell>
                              {total?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </div>
                    {cartItems?.length > 0 ? (
                      <form onSubmit={handleSubmit(HandlePayment)}>
                        <div className="form-group">
                          <label className="form-label">
                            Company/Industry
                            <span className="red-color"> *</span>
                          </label>
                          {asList?.length > 0 ? (
                            <>
                              <Select
                                isMulti={false}
                                name="colors"
                                options={asList}
                                isClearable={true}
                                className="dropdown-single-select"
                                classNamePrefix="select"
                                placeholder="Select Company/Industry"
                                filterOption={customFilter}
                                value={asList?.filter(
                                  (obj) => obj.value === associationMembersId
                                )}
                                onChange={(e) => handleCompany(e?.value)}
                              />
                              <select
                                className="form-select d-none"
                                {...register("associationMemberId", {
                                  required: "This field is required",
                                })}
                              >
                                <option value="">
                                  Select Company/Industry
                                </option>
                                {asList &&
                                  asList?.map((con) => (
                                    <option
                                      value={con.associationMembersId}
                                      key={con.associationMembersId}
                                    >
                                      {con.name} - {con?.type}
                                    </option>
                                  ))}
                              </select>

                              {errors.associationMemberId && (
                                <span role="alert" className="error_text">
                                  {errors.associationMemberId.message}
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <input
                                className="form-control"
                                placeholder="Enter company name"
                                {...register("companyName", {
                                  required: "This field is required",
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                  maxLength: {
                                    value: 250,
                                    message: "Max character 250 allowed",
                                  },
                                })}
                              ></input>
                              {errors.companyName && (
                                <span role="alert" className="error_text">
                                  {errors.companyName.message}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        {associationMembersId && LogDetails?.roleId == 1 ? (
                          <div className="form-group ">
                            <label className="form-label">
                              Company/Industry Admin
                            </label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={asMAdmin}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Company/Industry Admin"
                              filterOption={customFilter}
                              value={asMAdmin?.filter(
                                (obj) => obj.value === asMAdminId
                              )}
                              onChange={(e) => handleAsMAdmin(e?.value)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <h4 className="">Select Address</h4>
                        {asMAdminAddress?.length > 0
                          ? [asMAddress, ...asMAdminAddress]?.map((x, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {i === 0 ? (
                                    <h6 className="">Company Address</h6>
                                  ) : i === 1 ? (
                                    <h6 className="">Company Admin Address</h6>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className={`address_box ${
                                      selectAddress === x?.id ? "active" : ""
                                    }`}
                                    onClick={() => setSelectAddress(x?.id)}
                                  >
                                    <label className="personal_radio_label">
                                      <input
                                        type="checkbox"
                                        className="login_check"
                                        name="address"
                                        checked={selectAddress === x?.id}
                                        onChange={() => setSelectAddress(x?.id)}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                    <div className="Address">
                                      <h6>{x?.addressType}</h6>
                                      <p>
                                        {x?.address}
                                        <br />
                                        {x?.city} {x?.state} {x?.zip}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })
                          : asMAddress && LogDetails?.roleId == 1
                          ? [asMAddress]?.map((x, i) => (
                              <React.Fragment key={i}>
                                <h6 className="">Company Address</h6>

                                <div
                                  // key={i}
                                  className={`address_box ${
                                    selectAddress === x?.id ? "active" : ""
                                  }`}
                                  onClick={() => setSelectAddress(x?.id)}
                                >
                                  <label className="personal_radio_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      name="address"
                                      checked={selectAddress === x?.id}
                                      onChange={() => setSelectAddress(x?.id)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <div className="Address">
                                    <h6>{x?.addressType}</h6>
                                    <p>
                                      {x?.address}
                                      <br />
                                      {x?.city} {x?.state} {x?.zip}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))
                          : ""}{" "}
                        {addressList &&
                          addressList?.map((x, i) => (
                            <React.Fragment key={i}>
                              {i === 0 ? <h6 className="">My Address</h6> : ""}
                              <div
                                key={i}
                                className={`address_box ${
                                  selectAddress === x?.id ? "active" : ""
                                }`}
                                onClick={() => setSelectAddress(x?.id)}
                              >
                                <label className="personal_radio_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="address"
                                    checked={selectAddress === x?.id}
                                    onChange={() => setSelectAddress(x?.id)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <div className="Address">
                                  <h6>{x?.addressType}</h6>
                                  <p>
                                    {x?.address1}
                                    <br />
                                    {x?.city} {x?.state} {x?.zip}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        <div className="text-end">
                          <Button className="common_btn" type="submit">
                            Process to Pay
                          </Button>
                        </div>
                      </form>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>{" "}
      </section>
    </>
  );
}

export default ProductCart;
