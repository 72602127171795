import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import { ToastError, ToastSuccess } from "../Toast";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { useFieldArray, useForm } from "react-hook-form";
import { VscAdd, VscTrash } from "react-icons/vsc";

export default function ResendPopup(props) {
  const { body, afterAPICallAnotherURl } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const [derror, setDerror] = useState();

  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "emailList",
  });

  const handleOpen = () => {
    setOpen(true);
    reset();
    body?.email?.map((x) => {
      append({ email: x });
    });
  };
  const handleClose = () => {
    setDerror({ err: "" });
    setOpen(false);
    setOpenCircle(false);
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let responce = await PostCallApi({
      url: props.url,
      body: { ...body, emailList: data?.emailList?.map((x) => x.email) },
    });
    if (responce?.status === 200 && responce.data.success) {
      await GetCallApi({ url: afterAPICallAnotherURl });
      setOpen(false);
      setOpenCircle(false);
      reset();
      if (props?.callBack) {
        props?.callBackFunction();
      }
      handleClose();

      ToastSuccess(responce.data.message);
    } else {
      setOpen(false);
      setOpenCircle(false);
      ToastError(responce.data.message);
    }
  };

  return (
    <>
      <Button
        style={{
          background:
            "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
          padding: "0.5rem",
          borderRadius: "5px",
          color: "white",
          textTransform: "none",
          marginLeft: "1rem",
        }}
        onClick={handleOpen}
      >
        Resend
      </Button>
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {open && (
        <>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_resend_data"
          >
            <Box sx={style} className="resend_data_box">
              <div className="popup_resend_detail_box">
                <h6>{props?.title}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="popup_resend_detail">
                    <p className="resend_text">
                      Are you sure you want to Re-send email ?
                    </p>
                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                    <div className="row m-0">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label className="form-label">
                            Email
                            <span className="red-color"> *</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <Button
                          className="nd_btn add_btn ms-auto"
                          onClick={() => append({ email: "" })}
                        >
                          <VscAdd />
                        </Button>
                      </div>
                    </div>
                    <div className="email_list">
                      <div className="row m-0">
                        {fields?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="col-md-10">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter email"
                                    {...register(`emailList.${index}.email`, {
                                      required: "This field is required",
                                      pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Please Enter Valid Email",
                                      },
                                    })}
                                  />
                                  {errors.emailList?.[index]?.email && (
                                    <span role="alert" className="error_text">
                                      {errors.emailList?.[index]?.email.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-2">
                                {index === 0 ? (
                                  ""
                                ) : (
                                  <Button
                                    className="nd_btn delete_btn"
                                    onClick={() => remove(index)}
                                  >
                                    <VscTrash />
                                  </Button>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <Button type="submit" className=" common_btn">
                      Submit
                    </Button>
                    <Button
                      type="button"
                      className="back_button ms-3"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
