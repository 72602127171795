import React, { Suspense, useEffect, useState } from "react";
import Axios from "../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { Backdrop, Box, CircularProgress, Modal } from "@mui/material";
import { VscCheck, VscChromeClose } from "react-icons/vsc";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import { PostCallApi } from "../../../Action/Action.jsx";
import TableHeader from "../../../TableHeader.json";
import { ToastSuccess } from "../../Components/Toast/index.jsx";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function ConferenceApproval() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();

  useEffect(() => {
    document.title = "FIG Events | Conference Approval";
    GetAllEventsList();
    // eslint-disable-next-line
  }, []);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const handleOpenPop = (eId, st) => {
    setApData({ ...eId, st: st });
    setOpenPop(true);
  };
  const handleClose = () => {
    setApData();
    setOpenPop(false);
  };
  const GetAllEventsList = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/Conference/GetConferenceListWaitingForApproval?userId=${logindetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let comList = res?.data?.payload?.map((c, i) => {
              return {
                id: i + 1,
                ...c,
                conferenceStartdate: c?.conferenceStartdate
                  ? convert(c?.conferenceStartdate?.split("T")[0])
                  : "",
                conferenceEnddate: c?.conferenceEnddate
                  ? convert(c?.conferenceEnddate?.split("T")[0])
                  : "",
                Action: (
                  <>
                    {c?.status === "Approved" || c?.status === "Rejected" ? (
                      ""
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn_edit me-3"
                          onClick={() => handleOpenPop(c, "Approved")}
                        >
                          <VscCheck />
                        </button>
                        <button
                          type="button"
                          className="btn_dlt me-3"
                          onClick={() => handleOpenPop(c, "Rejected")}
                        >
                          <VscChromeClose className="icon" />
                        </button>
                      </>
                    )}
                  </>
                ),
              };
            });
            setDataOfTable(comList);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const HandleRequest = () => {
    setOpenCircle(true);
    let reqData = {
      conferenceId: apData?.conferenceId,
      userId: logindetail?.userId,
      status: apData?.st,
    };
    Axios.post("/api/Conference/ApproveRejectConference", reqData, { headers })
      .then(async (res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let seData = {
              url: `/api/Conference/PublishConferenceDataAsPost?conferenceId=${apData?.conferenceId}&userId=${logindetail?.userId}`,
              body: {},
              headers: headers,
            };
            await PostCallApi(seData);
            // const resp =
            // if (resp?.status === 200) {
            //   if (resp?.data.success) {
            //   }
            // }

            setOpenCircle(false);
            handleClose();
            let msg = res.data.message;
            GetAllEventsList();
            ToastSuccess(msg);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Backdrop open={openCircle} sx={{ color: "#fff", zIndex: () => 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  headCells={TableHeader?.ConferenceApprovalHeader}
                  title="Pending Approval Conference List"
                  action={true}
                  ListData={dataOfTable}
                  dropdown={false}
                />
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <div className="popup_delete_detail_box">
                  <h6>Conference {apData?.st} </h6>
                  <div className="popup_delete_detail">
                    <p className="delete_text">
                      Are you sure you want to
                      <span>
                        {" "}
                        {apData?.conferenceName} Conference {apData?.st} ?
                      </span>
                    </p>
                    <button
                      type="button"
                      className="popup_btn delete"
                      onClick={HandleRequest}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default ConferenceApproval;
