import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
// import eye from "../../assets/icons/eye.svg";
// import eyeOff from "../../assets/icons/eye-off.svg";
import { PostCallApi } from "../../Action/Action.jsx";
import {
  ToastError,
  ToastSuccess,
} from "../../Modules/Components/Toast/index.jsx";

function ResetPassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [derror, setDerror] = useState();
  const [mailOtp, setMailOtp] = useState();
  const { email } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  // let location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  let nav = useNavigate();
  useEffect(() => {
    document.title = "FIG Events | Reset Password";
    setValue("email", email?.split("email=")[1]);
    let key = email?.split("encryptedkey=")[1];
    setMailOtp(key?.split("&")[0]);
    // eslint-disable-next-line
  }, [email]);
  const onSubmit = async (data) => {
    // let lac = location.pathname?.split("/")[1];

    let rsData = {
      ...data,
      otp: parseInt(mailOtp),
      isRequestedPassword: true,
    };
    setOpenCircle(true);
    let seData = {
      url:
        // lac === "resetpassword"
        // ?
        `/api/Login/ResetPassword`,
      // : "/api/SpeakerLogin/SpeakerResetPassword",
      body: rsData,
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        reset();

        ToastSuccess(res.data.message);
        setTimeout(function () {
          nav("/", { replace: true });
        }, 2100);
        setDerror({ Log: "" });
      } else {
        setOpenCircle(false);

        setDerror({ Log: res.data?.message });
        ToastError(res.data.message);
      }
    } else {
      setOpenCircle(false);
      console.log(res);
      ToastError(res.data.message);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section className="ForgotPassword_page">
        <div className="main_box">
          <div className="row m-0">
            <div className="col-md-12 p-0">
              <div className="inner_box">
                <h1>RESET PASSWORD</h1>
                <form
                  className="d-flex flex-column"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h4>Recover your password</h4>
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordShown1 ? "text" : "password"}
                        {...register("newPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown1(!passwordShown1)}
                      >
                        <img
                          src={
                            passwordShown1 === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.newPassword && (
                      <span role="alert" className="error_text">
                        {errors.newPassword.message}
                      </span>
                    )}
                  </div>{" "}
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="Confirm Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordShown ? "text" : "password"}
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                          validate: (val) => {
                            if (watch("newPassword") !== val) {
                              return "Password and Confirm Password does not match";
                            }
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        <img
                          src={
                            passwordShown === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <span role="alert" className="error_text">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                    {derror?.Log && (
                      <span role="alert" className="d-block error_text">
                        {derror.Log}
                      </span>
                    )}
                  </div>
                  <Button type="submit" className="submit_btn w-10">
                    Submit
                  </Button>
                  {/* <input
                    type="submit"
                    name="submit"
                  /> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
