import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GetCallApi } from "../../../../Action/Action.jsx";
import Select from "react-select";
import GropCEUTable from "./GropCEUTable/index.jsx";
import { customFilter } from "../../../Components/CustomFilterForReactSelect/index.jsx";
import { ToastError } from "../../../Components/Toast/index.jsx";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../../Action/ConferenceListApi.jsx";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsCompanyReg = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "userPhone",
    label: "Phone ",
  },
  {
    id: "registerType",
    label: "Register Type",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "totalCEUEarned",
    label: "Total CEU Earned",
  },
];

function GroupCEUReport() {
  const [conferenceList, setConferenceList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [confId, setConfId] = useState("");
  //   const [leaList, setLeaList] = useState([]);
  //   const [selectLea, setSelectLea] = useState([]);
  //   const [agencyList, setAgencyList] = useState([]);
  //   const [selectAgency, setSelectAgency] = useState([]);
  //   const [industryList, setIndustryList] = useState([]);
  //   const [selectIndustry, setSelectIndustry] = useState([]);

  useEffect(() => {
    document.title = "FIG Events | Register User";
    GetAllConList();
    // getLEAList();
    // getAgencyist();
    // getIndustryist();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetRegistrationList(confId);
    //   }, [confId, selectAgency, selectIndustry, selectLea]);
    // eslint-disable-next-line
  }, [confId]);

  const logindetail = useSelector((state) => state.login.LoginDetails);

  const GetAllConList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConferenceList(res);
      setConfId(res[0]?.conferenceId);
    }
  };

  // const GetAllConList = async () => {
  //   let res = await GetCallApi({
  //     url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=true`,
  //   });

  //   if (res.status === 200) {
  //     if (res.data.success) {
  //       let comList = res?.data?.payload?.map((x) => {
  //         return {
  //           ...x,
  //           value: x?.conferenceId,
  //           label: x?.conferenceName,
  //         };
  //       });
  //       setConferenceList(comList);
  //       setConfId(comList[0]?.conferenceId);
  //     }
  //   }
  // };

  const GetRegistrationList = async (conId) => {
    setDataOfTable1([]);
    // let member = [];
    // if (selectLea?.length > 0) {
    //   member = [...selectLea];
    // }
    // if (selectAgency?.length > 0) {
    //   member = [...member, ...selectAgency];
    // }
    // if (selectIndustry?.length > 0) {
    //   member = [...member, ...selectIndustry];
    // }
    if (conId) {
      setOpenCircle(true);

      let SedData = {
        url: `/api/Reports/GroupAdminCEUearnedAndUserActivityReport?userId=${logindetail?.userId}&ConferenceId=${conId}`,
        // body: {
        //   userId: logindetail?.userId,
        //   conferenceId: conId,
        //   associationMemberId: member,
        // },
      };
      const res = await GetCallApi(SedData);
      //   const res = await PostCallApi(SedData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          let comList = res?.data?.payload?.map((c, i) => {
            return {
              ...c,
              id: i + 1,
              email: c?.emailId,
              adaDisabilities: c?.adaDisabilities
                ? c?.adaDisabilities?.split(";")?.join(", ")
                : "",
              mealRequirement: c?.mealRequirement
                ? c?.mealRequirement?.split(";")?.join(", ")
                : "",
              paymentDate: c?.paymentDate
                ? new Date(c?.paymentDate).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : null,
            };
          });
          setDataOfTable1(comList);
        } else {
          ToastError(res?.data?.message);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  const handleChangeValue = (conId) => {
    setConfId(conId);
  };

  //   const getLEAList = async () => {
  //     let seData = {
  //       url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}`,
  //       headers: headers,
  //     };
  //     setOpenCircle(true);
  //     let res = await GetCallApi(seData);
  //     setOpenCircle(false);
  //     if (res.status === 200) {
  //       if (res.data.success) {
  //         let data = res.data.payload?.map((x) => {
  //           return {
  //             ...x,
  //             value: x?.am?.associationMemberId,
  //             label: x?.am?.name,
  //           };
  //         });
  //         setLeaList(data);
  //       }
  //     }
  //   };

  //   const getAgencyist = async () => {
  //     let seData = {
  //       url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}`,
  //       headers: headers,
  //     };
  //     setOpenCircle(true);
  //     let res = await GetCallApi(seData);
  //     setOpenCircle(false);
  //     if (res.status === 200) {
  //       if (res.data.success) {
  //         let data = res.data.payload?.map((x) => {
  //           return {
  //             ...x,
  //             value: x?.am?.associationMemberId,
  //             label: x?.am?.name,
  //           };
  //         });
  //         setAgencyList(data);
  //       }
  //     }
  //   };

  //   const getIndustryist = async () => {
  //     let seData = {
  //       url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}`,
  //       headers: headers,
  //     };
  //     setOpenCircle(true);
  //     let res = await GetCallApi(seData);
  //     setOpenCircle(false);
  //     if (res.status === 200) {
  //       if (res.data.success) {
  //         let data = res.data.payload?.map((x) => {
  //           return {
  //             ...x,
  //             value: x?.am?.associationMemberId,
  //             label: x?.am?.name,
  //           };
  //         });
  //         setIndustryList(data);
  //       }
  //     }
  //   };

  //   const handleChangeLea = (e) => {
  //     setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  //   };

  //   const handleChangeAgency = (e) => {
  //     setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  //   };

  //   const handleChangeIndustry = (e) => {
  //     setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  //   };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="group_ceu_report ">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row mb-3" style={{ marginLeft: "20px" }}>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Conference</label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter(
                            (obj) => obj.value === confId
                          )}
                          onChange={(e) => handleChangeValue(e?.value)}
                        />
                        <select
                          className="form-select d-none"
                          value={confId}
                          onChange={(e) => handleChangeValue(e.target.value)}
                          style={{ minWidth: "250px" }}
                        >
                          {conferenceList &&
                            conferenceList?.map((con, i) => (
                              <option value={con?.conferenceId} key={i}>
                                {con?.conferenceName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">LEA Member</label>
                        <Select
                          isMulti
                          name="colors"
                          options={leaList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select LEA Member"
                          filterOption={customFilter}
                          value={leaList?.filter((obj) =>
                            selectLea?.includes(obj.value)
                          )}
                          onChange={handleChangeLea}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">State Agencies</label>
                        <Select
                          isMulti
                          name="colors"
                          options={agencyList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select State Agencies"
                          filterOption={customFilter}
                          value={agencyList?.filter((obj) =>
                            selectAgency?.includes(obj.value)
                          )}
                          onChange={handleChangeAgency}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Industry Member</label>
                        <Select
                          isMulti
                          name="colors"
                          options={industryList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Industry Member"
                          filterOption={customFilter}
                          value={industryList?.filter((obj) =>
                            selectIndustry?.includes(obj.value)
                          )}
                          onChange={handleChangeIndustry}
                        />
                      </div>
                    </div> */}
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card">
                <GropCEUTable
                  headCells={headCellsCompanyReg}
                  title="Group CEU Registration Report"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Group CEU Registration Report"}
                  exportDataList={false}
                  innerProduct={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default GroupCEUReport;
