import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ImgPopup from "../../Components/ImgPopUp";
import { Config } from "../../../Utils/Config";
import AOS from "aos";
import Select from "react-select";
import AssignTable from "../../SuperAdmin/AssignUsers/AssignTable";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
// import filterIcon from "../../../assets/icons/filterIcon.svg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "leadStatus",
    label: "Lead Status",
  },
  {
    id: "email",
    label: "Contact Email",
  },
  {
    id: "phone",
    label: "Contact Number",
  },
  // {
  //   id: "productName",
  //   label: "Product Name",
  // },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "associationMembersName",
    label: "Group Name",
  },

  // {
  //   id: "productImage",
  //   label: "Product Image",
  // },
];
const LeadList = [
  { label: "Cold", value: "Cold" },
  { label: "Hot", value: "Hot" },
  { label: "Warm", value: "Warm" },
];
function CompanyLead() {
  const [openCircle, setOpenCircle] = useState(false);
  const [attendeeList, setAttendeeList] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [conList, setConList] = useState([]);
  const [selectConference, setSelectConference] = useState();
  const [selectLead, setSelectLead] = useState();
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);
  // const [derror, setDerror] = useState();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.title = "FIG Events | Dashboard";
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getLEAList();
    getConferenceList();
    getAgencyist();
    getIndustryist();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    GetAttList();
    // eslint-disable-next-line
  }, [selectConference, selectAgency, selectIndustry, selectLea, selectLead]);
  const handleSelectProd = (v, id) => {
    if (v) {
      setUserArray((old) => [...old, id]);
    } else {
      setUserArray((old) => old?.filter((d) => d !== id));
    }
  };

  const GetAttList = async () => {
    setOpenCircle(true);
    setAttendeeList([]);
    setUserArray([]);
    let member = [];
    if (selectLea?.length > 0) {
      member = [...selectLea];
    }
    if (selectAgency?.length > 0) {
      member = [...member, ...selectAgency];
    }
    if (selectIndustry?.length > 0) {
      member = [...member, ...selectIndustry];
    }
    let seData = {
      url: `/api/ExhibitorHall/AddAttendeeInterestedProductListWithFilter`,
      body: {
        userId: logindetail?.userId,
        conferenceId: selectConference ? selectConference?.value : null,
        associationMemberId: member?.length > 0 ? member : null,
        LeadStatus: selectLead ? selectLead?.value : null,
      },
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.attendeeFavoriteProductList?.map(
          (c, i) => {
            return {
              id: i + 1,
              ...c,
              color:
                c?.leadStatus === "Hot"
                  ? "#f3c2c3"
                  : c?.leadStatus === "Warm"
                  ? "#fffaca"
                  : c?.leadStatus === "Cold"
                  ? "#0000ff63"
                  : "",
              requestACallDate: c?.requestACallDate
                ? new Date(c?.requestACallDate)?.toLocaleDateString("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "",
              isChatWithExhibitor: c?.isChatWithExhibitor ? "True" : "False",
              productImage: c?.productImage ? (
                <ImgPopup
                  img={`${Config.API_HOST_URL_live}${c.productImage}`}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : c?.mutipleImages?.length > 0 ? (
                <ImgPopup
                  img={`${Config.API_HOST_URL_live}${c?.mutipleImages?.[0]}`}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                ""
              ),
              Action: (
                <span className="control_btn d-flex align-items-center">
                  <label className="personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      checked={false}
                      onChange={(e) =>
                        handleSelectProd(e?.target?.checked, c?.attendeeId)
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </span>
              ),
            };
          }
        );
        setAttendeeList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  useEffect(() => {
    if (userArray?.length > 0) {
      changeTbData();
    } else {
      changeTbData();
    }
    // eslint-disable-next-line
  }, [userArray]);

  const changeTbData = () => {
    let newData = attendeeList?.map((v) => {
      return {
        ...v,
        Action: (
          <span className="control_btn d-flex align-items-center">
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  userArray?.filter((c) => c === v?.attendeeId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectProd(e?.target?.checked, v?.attendeeId)
                }
              />
              <span className="checkmark"></span>
            </label>
          </span>
        ),
      };
    });
    setAttendeeList(newData);
  };

  const [checkedValue, setCheckValue] = useState(false);

  const handleMultiCheck = (val, id) => {
    if (val) {
      setUserArray(id?.map((d) => d?.attendeeId));
      setCheckValue(true);
    } else {
      setUserArray([]);
      setCheckValue(false);
    }
  };

  const getConferenceList = async () => {
    let seData = {
      url: `/api/ExhibitorHall/GetExhibitorRegisteredConferenceList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            value: x?.conferenceId,
            label: x?.conferenceName,
          };
        });
        setConList(data);
      }
    }
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        logindetail?.userId
      }`,
      //  url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"LEA"}&userId=${
      //   logindetail?.userId
      // }`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setLeaList(data);
      }
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        logindetail?.userId
      }`,
      //  url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"State Agency"}&userId=${
      //   logindetail?.userId
      // }`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setAgencyList(data);
      }
    }
  };

  const onSubmit = async (data) => {
    let userD = {
      // createdBy: loginDetail?.userId,
      userId: userArray,
      leadStatus: data?.leadStatus,
      // association: selectAsso?.length > 0 ? selectAsso : [],
      // lea: selectLea?.length > 0 ? selectLea : [],
      // industry: selectIndustry?.length > 0 ? selectIndustry : [],
      // stateAgency: selectAgency?.length > 0 ? selectAgency : [],
    };
    let seData = {
      url: `/api/ExhibitorHall/AddAttendeeLeadsStatus`,
      body: userD,
      headers: headers,
    };

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        setValue("leadStatus", "");
        GetAttList();
      }
    }
  };

  const getIndustryist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
        logindetail?.userId
      }`,
      // url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"Industry"}&userId=${
      //   logindetail?.userId
      // }`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setIndustryList(data);
      }
    }
  };

  const handleChangeCon = (e) => {
    setSelectConference(e);
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="exhibito_status_filter py-4">
          <div className="main_wrapper">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                {/* <div className="card">
                <div className="card-body"> */}
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === selectConference?.value
                            )}
                            onChange={handleChangeCon}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">LEA Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={leaList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA Member"
                            filterOption={customFilter}
                            value={leaList?.filter((obj) =>
                              selectLea?.includes(obj.value)
                            )}
                            onChange={handleChangeLea}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">State Agencies</label>
                          <Select
                            isMulti
                            name="colors"
                            options={agencyList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select State Agencies"
                            filterOption={customFilter}
                            value={agencyList?.filter((obj) =>
                              selectAgency?.includes(obj.value)
                            )}
                            onChange={handleChangeAgency}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Industry Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={industryList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry Member"
                            filterOption={customFilter}
                            value={industryList?.filter((obj) =>
                              selectIndustry?.includes(obj.value)
                            )}
                            onChange={handleChangeIndustry}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Lead Status</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={LeadList}
                            isClearable={true}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Lead Status"
                            filterOption={customFilter}
                            value={LeadList?.filter(
                              (obj) => obj.value === selectLead?.value
                            )}
                            onChange={(e) => setSelectLead(e)}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className="accordian_card">
                  <div className=" d-flex justify-content-start my-3 ms-2">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Update Lead Status</label>
                        <select
                          className="form-select "
                          {...register("leadStatus", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Status</option>
                          <option value="Cold">Cold</option>
                          <option value="Hot">Hot</option>
                          <option value="Warm">Warm</option>
                        </select>
                        {errors.leadStatus && (
                          <span role="alert" className="error_text">
                            {errors.leadStatus.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group ms-3 mt-auto">
                      <Button
                        className="common_btn "
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className="">
                    <AssignTable
                      title="Company Lead Tracking Updates"
                      headCells={headCellsUser}
                      actionFirst={true}
                      ListData={attendeeList}
                      action={false}
                      dropdown={false}
                      addBtn={false}
                      setUserArray={setUserArray}
                      handleMultiCheck={handleMultiCheck}
                      checkedValue={checkedValue}
                      setCheckValue={setCheckValue}
                      status={true}
                      showUserListInTable={true}
                    />
                  </div>
                </div>
              </div>
            </form>
            {/* </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CompanyLead;
